

//import {env} from "../common/APi/api"

import UrlSetting from "./APi/UrlSetting";;
export var env="production";
export const PROD_GOOGLE_TRACKING_ID = "UA-96050324-1";
export const DEV_GOOGLE_TRACKING_ID = "UA-234185351-1";
export const DEV_GOOGLE_CAPTCHA_SITE_KEY = '6LfccVMgAAAAAJLczVdKyHOwbC0xrjXQx5RMEhKH';
export const PROD_GOOGLE_CAPTCHA_SITE_KEY = '6LdbC_MgAAAAAB-qciWOOokkQppNMXFt6S-3b5Cd';
export const GOOGLE_CAPTCHA_SITE_KEY = env==="development"? '6LfccVMgAAAAAJLczVdKyHOwbC0xrjXQx5RMEhKH':'6LdbC_MgAAAAAB-qciWOOokkQppNMXFt6S-3b5Cd';
export const GOOGLE_TRACKING_ID = env==="development"?"UA-234185351-1":"UA-96050324-1";
export const env_api_url = env==="development" ? 
UrlSetting.defaultSettings.DEV_REACT_APP_BASE_URL:
UrlSetting.defaultSettings.PROD_REACT_APP_BASE_URL;