import React, {FC} from 'react';
import Carousel from 'react-material-ui-carousel';
import { Container, Box, Typography, Grid, Card, CardContent, Button,List,CardMedia, 
ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import AboutUsImg from '../../../assets/images/About-us-banner.png';
const GetToKnowUs=(props: any) => {
    const { data } = props;
    //console.log(data[0].body,"data[0].body")
    //data1=data[0].body;
    return (
        <React.Fragment>
            {/* <Box className='blog' pt="77px">
             <Container maxWidth={false} sx={{ maxWidth: '1332px' }}>
            <Box textAlign='center'>
                                <Typography variant="h1" fontSize='36px' mb="20px">
                                {data[0].title}
                                </Typography>
                                <Typography component="p" fontSize='25px' mb="86px" maxWidth='951px' mx='auto'>
                                  {data[0].body!==""?<div dangerouslySetInnerHTML={{ __html: data[0].body}}/>:''} 
                                </Typography>
             </Box> 
             </Container>
             </Box> */}
             <Box textAlign='center'>
                <Typography variant="h1" fontSize='36px' mb="20px">
                    Get to know us
                </Typography>
                <Typography component="p" fontSize='25px' mb={{xs:"30px", lg:"86px"}} maxWidth='951px' mx='auto'>
                    Welcome to our resource center, where you can explore our facilities via video tour,
                    browse blog posts by our subject matter experts, learn what’s new at Biomerics, find
                    out where you can meet our team at tradeshows, and more.
                </Typography>
            </Box> 
            </React.Fragment>
    );
};


export default GetToKnowUs;
