

import React, { FC } from 'react';
//import Carousel from 'react-material-ui-carousel';
import {
    Container, Box, Typography, Grid, Card, CardContent, Button, List, CardMedia,
    ListItem, ListItemAvatar, ListItemText
} from '@mui/material';
// import AboutUsImg from '../../../assets/images/About-us-banner.png';
// import NoResultFound from '../../../components/sections/NewsAndSearch/NoResultFound';
import {Link as RouteLink} from 'react-router-dom';
import { truncateString } from '../../../common/utils/utils'; 
import useAnalyticsEventTracker from '../../useAnalyticsEventTracker';
const PinnedBlog = (props: any) => {
    const { data } = props;
  
    const gaEventTracker = useAnalyticsEventTracker(window.location.pathname);
    const blogResult = data;
    if (data !== null) {

        return (
            <React.Fragment>
               {blogResult.length > 0  && blogResult.length!=='' ?
                   <Box className='news-and-resource' display='flex' justifyContent='center' mb={{ xs: '30px', lg: '98px' }}>
                       <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center">
                           <img src={data[0].field_blog_bannerimage} alt="" />
                           <Box className='news-box' display="flex" flexDirection="column" justifyContent="space-between"
                               pl={{ xs: '20px', md: '24px' }} pr={{ xs: '20px', md: '56px' }} pt={{ xs: '20px', md: '56px' }} pb={{ xs: '20px', md: '40px' }} maxWidth='557px'>
                               <Box>
                                   <Typography variant="h1" mb="20px" fontSize="47px">
                                       {truncateString(data[0].title, 64)}
            
                                   </Typography>
                               </Box>
                               
                               <Box>
                               <RouteLink   to={`/BlogDetails${data[0].field_blog_address_url}`} state={{searchResultData:data,  currentIndex:0, isPinnedBlog:"true"}}>
                                   <Button  sx={{ padding: '0 25px', fontSize: '18px', fontWeight: '500 !important' }} style={{
                                       width: '170px',
                                       height: '46px'
                                   }} className='btn-blue'
                                   
                                   href="#"  onClick={()=>gaEventTracker('blog')}
                                      >
                                       READ MORE
                                   </Button>
                                   </RouteLink>
                               </Box>
                           </Box>
                       </Box>
                   </Box> :
                <></>
          
               }
           </React.Fragment>
       )
    }
    else {
        return (
            <React.Fragment>
                <></>
            </React.Fragment>
        )
    }

};


export default PinnedBlog;
