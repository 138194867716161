import React, { useState, useEffect, useRef } from 'react'
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import {
    Container, Box, Typography, Grid, FormHelperText
} from '@mui/material';
import contactImg from '../assets/images/contactimg.jpg';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import contactbg from '../assets/images/contact-bg.jpg';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Theme, useTheme } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import BannerSection from './sections/RFQForm/BannerSection';
import RfqQuote from './sections/RFQForm/RfqQuote';
import ReCAPTCHA from "react-google-recaptcha";
import RfqFooter from './sections/RFQForm/RfqFooter';
import Muiphone from 'material-ui-phone-number';
import { Country, State } from 'country-state-city';
//component section
//Service
import { RfPageResponse, fetchProductDropdownValue, fetchProductCurrentStage, postRfqFormData } from '../Service/Rfq';

//API 
import { RfqHeroSection, RfqQouteSection, RfqQouteFooterSection } from '../common/utils/utils';
import { RFQ } from '../common/APi/apiurl';
//import { ErrorMessage } from './sections/ContactSection/ContactInfusionSection';
import { postUserFormDataForRFQPage } from '../Service/ContactUs/index';
import { useNavigate } from 'react-router-dom';
import { GOOGLE_CAPTCHA_SITE_KEY, PROD_GOOGLE_CAPTCHA_SITE_KEY, DEV_GOOGLE_CAPTCHA_SITE_KEY } from '../common/Constants';

var qs = require('qs');
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: '30px',
    },

    '& .MuiInputBase-input': {
        width: '100%',
        borderRadius: 0,
        position: 'relative',
        backgroundColor: '#ffffff',
        fontSize: 16,
        padding: '11px 14px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1);',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
    },
}));

interface userContactInformation {
    firstName: '',
    lastName: '',
    email: string;
    phone: string;
    tellUs_field: string;
    companyName: string;
    division: string;
    websiteUrl: string;
    Address1: string;
    Address2: string;
    country: string;
    zip: string;
    state: string;
    city: string;
    application: string;
    budgetTarget: string;
    projectLaunchDate: string;
    sme: string;
    projectDetails: string;
    productCategory: string | [];
    currentProjectStage: string,
    countryName: string[],
    stateName: string[]
}

const initial_value: userContactInformation = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    tellUs_field: '',
    division: "",
    websiteUrl: "",
    Address1: "",
    Address2: "",
    country: "",
    zip: "",
    state: "",
    city: "",
    application: '',
    budgetTarget: '',
    projectLaunchDate: '',
    sme: '',
    projectDetails: '',
    productCategory: [],
    currentProjectStage: '',
    countryName: [],
    stateName: []

}
interface ProjectInfoField {
    productCategory: string[];
    currentProjectStage: string;
}

export interface ErrorMessage {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    companyName: string;
    productCategory: string;
    city: string;
    zip: string;
    country: string;
    state: string;
    countryName: string;
    stateName: string;
    application: string;
}

const initialProjectInfo: ProjectInfoField = {
    productCategory: [],
    currentProjectStage: ''
}
interface ApiResponseDropdown {
    body: string[],
    loading: boolean
}


const Contact = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [pageContent, setPageContent] = useState<null | any>(null);
    const [ProductDropdownValueApiRes, setProductDropdownValueApiRes] = useState<ApiResponseDropdown>({
        loading: true,
        body: []
    })
    const [CurrentStageApiRes, setCurrentStageApiRes] = useState<ApiResponseDropdown>({
        loading: true,
        body: []
    })


    const history = useNavigate();
    const theme = useTheme();
    const [productCategory, setproductCategory] = React.useState<string[]>([]);
    const [currentProjectStage, setcurrentProjectStage] = React.useState<string[]>([]);
    const [projectInfo, setProjectInfo] = useState(initialProjectInfo);
    const [rfqFormData, setRfqFormData] = useState<userContactInformation>(initial_value);
    const [personName, setPersonName] = useState<string>('');
    // const [personName, setPersonName] = useState<string[]>([]);
    const [currentStage, setCurrentStage] = useState<string[]>([]);
    const [selectedCountry, setSelectedCountry] = React.useState<string>('');
    const [selectedCountryId, setSelectedCountryId] = React.useState<any>('');
    const [selectedstate, setSelectedstate] = React.useState<string>('');
    const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(false)

    const [HeadingSectionData, setHeadingSection] = useState<null | any>(null);
    const [RfqQouteSectionData, setRfqQouteSectionData] = useState<null | any>(null);
    const [RfqFooterSectionData, setRfqFooterSectionData] = useState<null | any>([]);

    const [ContactInfusionDetail, setContactInfusionData] = useState<null | any>(null);
    const [ContactUsData, setContactUsData] = useState<null | any>(null);
    const [ContactAddressDetail, setContactAddressData] = useState<null | any>(null);
    const [selectedApplication, setSelectedApplication] = useState<string>('');
    const [selectedSME, setSelectedSME] = useState<string>('');

    const [error, setError] = useState<ErrorMessage>({
        firstName: '',
        lastName: '',
        phone: '',
        companyName: '',
        email: '',
        productCategory: "",
        country: "",
        state: "",
        city: "",
        zip: "",
        countryName: "",
        stateName: "",
        application: ""
    })

    const countries = Country.getAllCountries();
    console.log("countries", countries)


    const fetchResponse = async () => {
        const response = await RfPageResponse(RFQ);
        setPageContent(response)
        setLoading(false);
    };

    const formatData = () => {
        let newsData;
        let titlesection: any;
        let RfqHeadingSection;
        let RfqQouteSectionData;
        let RfqFooterSectionData;
        if (pageContent) {
            RfqHeadingSection = RfqHeroSection(pageContent);
            RfqQouteSectionData = RfqQouteSection(pageContent);
            RfqFooterSectionData = RfqQouteFooterSection(pageContent);
            setHeadingSection(RfqHeadingSection);
            setRfqQouteSectionData(RfqQouteSectionData);
            setRfqFooterSectionData(RfqFooterSectionData);
            //console.log(RfqFooterSectionData,"RfqFooterSectionData") 
        }
    }

    useEffect(() => {
        window.scroll(0, 0)
        fetchResponse();
    }, [])
    useEffect(() => {
        formatData();

    }, [pageContent])


    useEffect(() => {
        //fetch menu data from api
        const response = fetchProductDropdownValue();
        response.then((res): void => {
            setLoading(false);
            const dummyString = res.body.replace(/(^"|"$)/g, '')
            setProductDropdownValueApiRes({ loading: false, body: dummyString.split(",") })
        }).catch((err) => {

        })
    }, [])

    useEffect(() => {
        //fetch menu data from api
        const response = fetchProductCurrentStage();
        response.then((res): void => {
            setLoading(false);
            const dummyString = res.body.replace(/(^"|"$)/g, '')
            setCurrentStageApiRes({ loading: false, body: dummyString.split(",") })
        }).catch((err) => {

        })
    }, [])

    function getStyles(name: string, personName: readonly string[], theme: Theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    console.log("states...", State.getStatesOfCountry(selectedCountry))

    const handleCountry = (e: any, id: any) => {
        console.log("e and id...", e, id)
        const selectedCountry = e.target.value;
        const countryId = id.props.id;
        setSelectedCountry(selectedCountry);
        setSelectedCountryId(State.getStatesOfCountry(countryId));
        setSelectedstate('');
        setError({ ...error, country: '' });

    };

    const handlestate = (e: any, id: any) => {
        const selectedstate = e.target.value;
        console.log("selectedstate", selectedstate)
        setSelectedstate(selectedstate);
        setError({ ...error, state: '' });
    };


    const handelSuccessBanner = (flag: boolean) => {
        setShowSuccessBanner(flag)
    }
    const [verified, setVerified] = useState(false);
    function handelCaptcha(value: any) {
        setVerified(value);

    }

    const [phone, setPhone] = useState("");


    const handlePhoneChange = (e: any) => {
        const name = 'phone';
        let value = e;

        // Basic validation for phone number
        if (!value) {
            setError({ ...error, [name]: "Please enter your phone number" });
        } else {
            setError({ ...error, [name]: "" });

            // Remove non-numeric characters from the phone number
            value = value.replace(/[^\d]/g, '');

            // Validate the length of the phone number
            if (value.length < 6 || value.length > 15) {
                setError({ ...error, [name]: "Please enter a valid phone number" });
            } else {
                setError({ ...error, [name]: "" });
            }

            // Update the phone state
            setPhone(value);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setRfqFormData({ ...rfqFormData, [name]: value });

        if (name === 'firstName' && (!value || value.length < 4)) {
            setError({ ...error, [name]: "Please add a first name" });
        } else if (name === 'lastName' && (!value || value.length < 4)) {
            setError({ ...error, [name]: "Please add a last name" });
        } else if (name === 'city' && !value) {
            setError({ ...error, [name]: "Please enter a city" });
        }
        else if (name === 'zip' && !value) {
            setError({ ...error, [name]: "Please enter a zip code" });
        }

        else if (name === 'application' && !value) {
            setError({ ...error, [name]: "Please selected an Application/Product Type" });
        }

        else {
            setError({ ...error, [name]: "" });
        }
    };


    const handleProductCategory = (event: SelectChangeEvent<typeof personName | any>) => {
        const {
            target: { value, name },
        } = event;
        setPersonName(event.target.value);
        // setPersonName(
        //     typeof value === 'string' ? value.split(',') : value,
        // );
        if (name === 'productCategory') {
            if (!value) {
                setError({ ...error, [name]: "Please select single category." })
            } else {
                setError({ ...error, [name]: "" })
            }
        }
    };

    const handleCurrentChange = (event: SelectChangeEvent<typeof currentStage | any>) => {
        const {
            target: { value, name },
        } = event;
        setCurrentStage(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        if (name === 'productCategory') {
            if (!value) {
                setError({ ...error, [name]: "Please select single category." })
            } else {
                setError({ ...error, [name]: "" })
            }
        }
    };
    const myRef = useRef(null);
    const scrollToRef = (ref: any) => window.scrollTo(0, 2300)

    const ScrollDemo = () => {
        const myRef = useRef(null)
        const executeScroll = () => scrollToRef(myRef)
    }
    const validateForm = () => {
        let isValid = true;
        const newError: ErrorMessage = {
            firstName: '',
            lastName: '',
            phone: '',
            companyName: '',
            email: '',
            productCategory: "",
            city: "",
            country: "",
            state: "",
            countryName: "",
            stateName: "",
            zip: '',
            application: ''

        };

        // Validation logic for each field
        if (!rfqFormData.firstName) {
            isValid = false;
            newError.firstName = "Please add a valid first name";
        }

        if (!rfqFormData.lastName) {
            isValid = false;
            newError.lastName = "Please add a valid last name";
        }
        if (!rfqFormData.email) {
            isValid = false;
            newError.email = "Please enter a email";
        }
        if (!rfqFormData.companyName) {
            isValid = false;
            newError.companyName = "Please enter a company name";
        }
        if (!phone) {
            isValid = false;
            newError.phone = "Please enter a phone number";
        }

        if (!selectedCountry) {
            isValid = false;
            newError.country = "Please select a country";
        }

        if (!selectedstate) {
            isValid = false;
            newError.state = "Please select a state";
        }

        if (!rfqFormData.city) {
            isValid = false;
            newError.city = "Please enter a city";
        }

        if (!rfqFormData.zip) {
            isValid = false;
            newError.zip = "Please enter a zip code";
        }

        // if (!rfqFormData.application) {
        //     isValid = false;
        //     newError.application = "Please selected an Application/Product Type";
        // }
        
        // if (!rfqFormData.productCategory) {
        //     isValid = false;
        //     newError.productCategory = "Please selected an Product Category";
        // }

        setError(newError);

        return isValid;
    };

    let handleSubmit = async (e: any) => {
        console.log("handle submit data", e)
        //window.scrollTo(0,2500);
        scrollToRef(myRef);
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        const rfqContactPayload = {
            "firstname": rfqFormData.firstName,
            "lastname": rfqFormData.lastName,
            "company": rfqFormData.companyName,
            "email": rfqFormData.email,
            "phone": phone,
            "Division__c": rfqFormData.division,
            "Website": rfqFormData.websiteUrl,
            "address_1": rfqFormData.Address1,
            "address_2": rfqFormData.Address2,
            "city": rfqFormData.city,
            "state": selectedstate,
            "zip_code": rfqFormData.zip,
            "country": selectedCountry,
            "Product_Type__c": selectedApplication,
            "Product_Category__c": personName,
            "Center_of_Excellence__c": "Guidewires", // COE dependency
            "Commercial_Director__c": "Not Yet Assigned", //sending a dependent field for product category onto the salesforce
            "project_details": rfqFormData.projectDetails,
            "Customer_Project_Stage__c": currentStage.join(','),
            "Is_Lead_SME_for_this_Project__c": selectedSME,
            "project_launch_date": rfqFormData.projectLaunchDate,
            "Budgetary_Targets__c": rfqFormData.budgetTarget,
            "lead_source": "Website-RFQ",
        }

        const response = postRfqFormData(JSON.stringify(rfqContactPayload));

        response.then((res) => {

            //props.handelSuccessBannerCallback(true);
            setSelectedApplication('');
            setSelectedSME('');
            setPhone("");
            setPersonName('');
            setCurrentStage([]);
            setSelectedstate('');
            setRfqFormData(initial_value);
            setSelectedCountry('')
            setShowSuccessBanner(true)

        }).catch((err) => {
            setShowSuccessBanner(true)
        })
    };

    return (
        <>
            {loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
                <React.Fragment>
                    {/* <Header /> */}
                    <BannerSection data={HeadingSectionData} />
                    <RfqQuote data={RfqQouteSectionData ? RfqQouteSectionData : ''} />

                    <Box className='rfq-frm-box' bgcolor='#F5F5F5' py={8}>
                        <form>
                            <Container maxWidth={false} sx={{ maxWidth: '1195px' }}>
                                <Grid container>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                                <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }} required={true}>
                                                    First Name
                                                </InputLabel>
                                                <BootstrapInput placeholder='First Name' name='firstName' onChange={handleChange} value={rfqFormData.firstName} />
                                                {error.firstName.length > 0 ? <FormHelperText>{error.firstName}</FormHelperText> : ''}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                                <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                                    Last Name*
                                                </InputLabel>
                                                <BootstrapInput placeholder='Last Name' name='lastName' onChange={handleChange} value={rfqFormData.lastName} />
                                                {error.lastName.length > 0 ? <FormHelperText>{error.lastName}</FormHelperText> : ''}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                                <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                                    Company*
                                                </InputLabel>
                                                <BootstrapInput placeholder='Company' name='companyName' onChange={handleChange} value={rfqFormData.companyName} />
                                                {error.companyName.length > 0 ? <FormHelperText>{error.companyName}</FormHelperText> : ''}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                                <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                                    Email*
                                                </InputLabel>
                                                <BootstrapInput placeholder='Email' name='email' onChange={handleChange} value={rfqFormData.email} />
                                                {error.email.length > 0 ? <FormHelperText>{error.email}</FormHelperText> : ''}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="mobile-number">

                                            <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                                Phone number*
                                            </InputLabel>
                                            <Muiphone
                                                disableCountryCode={false}
                                                defaultCountry={"us"}
                                                name="phone"
                                                variant="standard" className='w-100' sx={{ mb: '6px' }}
                                                value={phone}
                                                onChange={handlePhoneChange}
                                            />
                                            {error.phone.length > 0 ? <FormHelperText>{error.phone}</FormHelperText> : ''}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                                <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                                    Division (if applicable)
                                                </InputLabel>
                                                <BootstrapInput placeholder='Division'
                                                    name='division' onChange={handleChange} value={rfqFormData.division} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                                <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                                    Website URL
                                                </InputLabel>
                                                <BootstrapInput placeholder='Website URL' name='websiteUrl' onChange={handleChange} value={rfqFormData.websiteUrl} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                                <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                                    Address 1
                                                </InputLabel>
                                                <BootstrapInput placeholder='Address 1' name='Address1' onChange={handleChange} value={rfqFormData.Address1} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                                <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                                    Address 2
                                                </InputLabel>
                                                <BootstrapInput placeholder='Address 2' name='Address2' onChange={handleChange} value={rfqFormData.Address2} />
                                            </FormControl>
                                        </Grid>


                                        <Grid item xs={12} md={6} lg={3}>
                                            <>
                                                <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none', mb: '6px' }}>
                                                    Country*
                                                </InputLabel>
                                                <Select sx={{ width: '100%', borderRadius: '0', backgroundColor: '#ffffff', fontSize: '16px', boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)', border: 'none' }}
                                                    className='w-100 frm-select'
                                                    displayEmpty
                                                    value={selectedCountry}
                                                    onChange={(value, id: any) => handleCountry(value, id)}
                                                    name='countryName'
                                                    input={<OutlinedInput />}
                                                >

                                                    <MenuItem value="" disabled>
                                                        Please select a country
                                                    </MenuItem>
                                                    {countries.map((val: any) => (
                                                        <MenuItem
                                                            id={val?.isoCode}
                                                            key={val?.name}
                                                            value={val.name}
                                                        >
                                                            {val?.name}
                                                        </MenuItem>

                                                        // <MenuItem key={country.name} value={country.name}>
                                                        //     {country.name}
                                                        // </MenuItem>
                                                    ))}
                                                </Select>
                                                {error?.country?.length > 0 ? <FormHelperText>{error.country}</FormHelperText> : ''}
                                            </>
                                        </Grid>


                                        <Grid item xs={12} md={6} lg={3}>
                                            <>
                                                <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none', mb: '6px' }}>
                                                    State/Province*
                                                </InputLabel>
                                                <Select
                                                    sx={{ width: '100%', borderRadius: '0', backgroundColor: '#ffffff', fontSize: '16px', boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)', border: 'none' }}
                                                    className='w-100 frm-select'
                                                    displayEmpty
                                                    value={selectedstate}
                                                    onChange={(value, id) => handlestate(value, id)}
                                                    name='state'
                                                    input={<OutlinedInput />}
                                                    disabled={!selectedCountry} // Disable when no country is selected
                                                >
                                                    <MenuItem value="" disabled>
                                                        Please select a state
                                                    </MenuItem>

                                                    {
                                                        selectedCountry &&
                                                        (selectedCountryId?.map(
                                                            (val: any) => (
                                                                <MenuItem
                                                                    id={val?.isoCode}
                                                                    key={val?.name}
                                                                    value={val.name}
                                                                >
                                                                    {val?.name}
                                                                </MenuItem>
                                                            ),
                                                        ))}


                                                </Select>
                                                {error?.state?.length > 0 ? <FormHelperText>{error.state}</FormHelperText> : ''}
                                            </>
                                        </Grid>


                                        <Grid item xs={12} md={6} lg={3}>
                                            <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                                <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                                    City*
                                                </InputLabel>
                                                <BootstrapInput placeholder='City' name='city' onChange={handleChange} value={rfqFormData.city} />
                                                {error.city.length > 0 ? <FormHelperText>{error.city}</FormHelperText> : ''}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={6} lg={3}>
                                            <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                                <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                                    ZIP/Postal code*
                                                </InputLabel>
                                                <BootstrapInput placeholder='ZIP' name='zip' onChange={handleChange} value={rfqFormData.zip} />
                                                {error.zip.length > 0 ? <FormHelperText>{error.zip}</FormHelperText> : ''}
                                            </FormControl>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Box mt={6}>
                                    <Typography variant='h1' fontSize='25px' color='#131313' fontWeight='500' mb={4}>Project Information</Typography>
                                    <FormControl>
                                        <Typography fontSize='18px' color='#131313' fontWeight='500' mb={1}>Application*</Typography>

                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="application"
                                            value={selectedApplication}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                setSelectedApplication(newValue);
                                                
                                                // Clear the error when a value is selected
                                                if (newValue) {
                                                    setError((prevError) => ({
                                                        ...prevError,
                                                        application: ''
                                                    }));
                                                }
                                            }}
                                            // onChange={(e) => setSelectedApplication(e.target.value)}
                                        >

                                            <FormControlLabel value="Component" control={<Radio />} label="Component" />
                                            <FormControlLabel value="Development / R&D" control={<Radio />} label="Development / R&D" />
                                            <FormControlLabel value="Final Assembly Bulk Non-Sterile" control={<Radio />} label="Final Assembly Bulk Non-Sterile" />
                                            <FormControlLabel value="Finished device" control={<Radio />} label="Finished device" />
                                            <FormControlLabel value="Material" control={<Radio />} label="Material" />
                                            <FormControlLabel value="Prototype" control={<Radio />} label="Prototype" />
                                            <FormControlLabel value="Sub-Assembly" control={<Radio />} label="Sub-Assembly" />
                                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                        </RadioGroup>
                                        {error?.application?.length > 0 ? <FormHelperText>{error.application}</FormHelperText> : ''}
                                    </FormControl>
                                </Box>
                                <Box mt={3}>
                                    <Typography fontSize='18px' color='#131313' fontWeight='500' mb={1}>Product Category*</Typography>
                                    <Select
                                        sx={{
                                            width: '100%',
                                            borderRadius: '0',
                                            backgroundColor: '#ffffff',
                                            fontSize: '16px',
                                            boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)',
                                            border: 'none'
                                        }}
                                        className='w-100 frm-select'
                                        displayEmpty
                                        value={personName} // personName is now a single string
                                        onChange={handleProductCategory} // update to handle a single value
                                        name='productCategory'
                                        input={<OutlinedInput />}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return "Please select a Product Category"
                                            }
                                            return selected;
                                        }}
                                    >
                                        <MenuItem disabled value="">
                                            <em>Select option here</em>
                                        </MenuItem>
                                        {ProductDropdownValueApiRes.body?.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                                sx={{
                                                    '&.selected': {
                                                        backgroundColor: '#e0e0e0',
                                                        fontWeight: 'bold',
                                                    },
                                                }}
                                                className={`selected-menu-option ${personName === name ? 'selected' : ''}`}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    {error.productCategory.length > 0 ? <FormHelperText>{error.productCategory}</FormHelperText> : ''}
                                </Box>

                                <Box mt={3}>
                                    <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                        <Typography fontSize='18px' color='#131313' fontWeight='500' mb={1}>
                                            What can you tell us about the project at this time ? You can share additional details after an NDA is in place.
                                        </Typography>
                                        <BootstrapInput placeholder='Type here' name='projectDetails' value={rfqFormData.projectDetails} onChange={handleChange} />
                                    </FormControl>
                                </Box>
                                <Box mt={3}>
                                    <Typography fontSize='18px' color='#131313' fontWeight='500' mb={1}>Current Project Stage</Typography>
                                    <Select sx={{ width: '100%', borderRadius: '0', backgroundColor: '#ffffff', fontSize: '16px', boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)', border: 'none' }}
                                        className='w-100 frm-select'
                                        displayEmpty
                                        value={currentStage}
                                        onChange={(e) => handleCurrentChange(e)}
                                        name='currentProjectStage'
                                        input={<OutlinedInput />}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return "Please select all that apply"
                                            }
                                            return selected;
                                        }}
                                    >
                                        <MenuItem disabled value="">
                                            <em>Select option here </em>
                                        </MenuItem>

                                        {CurrentStageApiRes.body?.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box mt={3}>
                                    <FormControl>
                                        <Typography fontSize='18px' color='#131313' fontWeight='500' mb={1}>Are you the subject matter expert (SME) for this project?</Typography>

                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="sme"
                                            value={selectedSME}
                                            onChange={(e) => setSelectedSME(e.target.value)}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="No" control={<Radio />} label="No" />
                                        </RadioGroup>

                                    </FormControl>
                                </Box>
                                <Box mt={3}>
                                    <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                        <Typography fontSize='18px' color='#131313' fontWeight='500' mb={1}>
                                            Project Launch Date
                                        </Typography>
                                        <BootstrapInput placeholder='Type here' name='projectLaunchDate' onChange={handleChange} value={rfqFormData.projectLaunchDate} />
                                    </FormControl>
                                </Box>
                                <Box my={3}>
                                    <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                        <Typography fontSize='18px' color='#131313' fontWeight='500' mb={1}>
                                            Budget Target
                                        </Typography>
                                        <BootstrapInput placeholder='Type here' name='budgetTarget' onChange={handleChange} value={rfqFormData.budgetTarget} />
                                    </FormControl>
                                </Box>
                                <ReCAPTCHA
                                    id='ReCAPTCHA'
                                    sitekey={GOOGLE_CAPTCHA_SITE_KEY}//test key
                                    //sitekey='6LdbC_MgAAAAAB-qciWOOokkQppNMXFt6S-3b5Cd' //production site kry
                                    onChange={handelCaptcha}
                                />
                                <Box mt={5}>
                                    <Button variant="contained" className='' sx={{ px: '24px' }} onClick={handleSubmit}>SUBMIT</Button></Box>
                            </Container>
                        </form>
                    </Box>

                    <RfqFooter data={RfqFooterSectionData ? RfqFooterSectionData : []} showBanner={showSuccessBanner} />
                    {/* <Footer /> */}
                </React.Fragment >
            }
        </>
    );
}
export default Contact;
