//balloon catherters
import Api from "../../common/APi/api";
import axios from "axios";
export const LeadGenPageResponse = async (url: string) => {
  const response = await Api.get(url);
  console.log("Service", response);
  return response.data;
}

//on submit
export const postUserFormDataForPerformanceRFQ = async (payloadBody: any) => {
  const res = await axios.post('https://skwsjd5px2.execute-api.us-east-1.amazonaws.com/dev/v1/bio/PerformanceMaterialsRFQ',
    payloadBody, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return res.data;
}


export const fetchMaterialsOfInterestDropdownValue = async () => {
  const response = await axios.get('https://skwsjd5px2.execute-api.us-east-1.amazonaws.com/dev/v1/bio/materialsOfInterest');

  return response.data;
}

export const fetchPolymerProcessingDropdownValue = async () => {
  const response = await axios.get('https://skwsjd5px2.execute-api.us-east-1.amazonaws.com/dev/v1/bio/polymerProcessing');

  return response.data;
}