import Api from "../../common/APi/api";
// import axios  from "axios";
import axios, { AxiosRequestConfig } from 'axios';
export const RfPageResponse = async (url: string) => {
  const response = await Api.get(url);
  //console.log("Service", response);
  return response.data;
}

export const fetchProductDropdownValue = async () => {
  const response = await axios.get('https://ifr771wo94.execute-api.us-east-1.amazonaws.com/dev/v1/bio/productCategory');
  return response.data;
}
export const fetchProductCurrentStage = async () => {
  const response = await axios.get('https://ifr771wo94.execute-api.us-east-1.amazonaws.com/dev/v1/bio/currentProjectStage');
  //console.log(response.data,"RES")
  return response.data;
}

export const postRfqFormData = (data: any) => {
  var config: AxiosRequestConfig = {
    method: 'post',
    url: ' https://ifr771wo94.execute-api.us-east-1.amazonaws.com/dev/v1/bio/biomericsRfq',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  return axios(config)
    .then(function (response) {


      return JSON.stringify(response)
    })
    .catch(function (error) {

      return error;
    });

}