import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"
import { level4CenterSection, level4HeaderDetail, level4ImgContentDetail, level4TableSection } from "../common/utils/utils";
import axios from 'axios';
import CommonCreativeSolution from "./CommonCreativeSolution";
import BannerSection from "./sections/ServiceDetailsLevel2/BannerSection";
import CommonRepetativeSections from "./sections/ServiceDetailsLevel2/CommonRepetativeSections";
import ExploreAboutBiomerics from "./sections/ServiceDetailsLevel2/ExploreAboutBiomerics";
import SectionCenterText10 from "./sections/ServiceDetailsLevel2/SectionCenterText10";
import SectionRight1 from "./sections/ServiceDetailsLevel2/SectionTextRight1";
import TopSubMenuAboutUs from "./sections/ServiceDetailsLevel2/TopSubMenuAboutUs";
import { fetchL4MetaData } from '../Service/Level4';
import { env_api_url } from '../common/Constants';
import CommonTable from "./sections/ServiceDetailsLevel2/TabelsSections/CommonTable";

const Level4 = () => {

    const pathName = useLocation();
    let currentPage = pathName.pathname;
    const location = useLocation().pathname;

    const [loading, setLoading] = useState<boolean>(true);
    const [pageContent, setContent] = useState<null | any>(null);
    const [hash, setHash] = useState<string>(pathName.hash ? pathName.hash : '');
    const [level4Header, setLevel4Header] = useState<null | any>(null);
    const [level4Overview, setLevel4Overview] = useState<null | any>(null);
    const [level4ImgContentData, setLevel4ImgContentData] = useState<null | any>(null);
    const [level4CenterData, setLevel4CenterData] = useState<null | any>(null);
    const [level4TableData, setLevel4TableData] = useState<null | any>(null);
    const [newApi, newApiData] = React.useState<any>([]);

    useEffect(() => {
        const apiResponse = fetchL4MetaData();
        apiResponse.then((res: any) => {
           
            const HTML = res[0].body.replace(/<\/?[^>]+(>|$)/g, "").replace(/\n/g, " ");

            var api_data = JSON.parse(HTML);

            const pageName = api_data[0].NewApis.map((item: any) => item.page_name);
            const pageNames = api_data[0].NewApis.map((item: any) => item.page_name.toLowerCase());


            function compareUrl(currentPage: string, pageNames: string[]): boolean[] {
                currentPage = currentPage.toLowerCase(); // Convert currentPage to lowercase
                // Use the map function to create an array of booleans
                const matchArray = pageNames.map((pageName) => currentPage === '/' + pageName);

                return matchArray; // Return the array of booleans
            }

            const matchResults = compareUrl(currentPage, pageNames);

            const isSame = matchResults.some((match: any) => match);

            if (isSame) {

                const matchingPageName = pageName.find(
                    (pageName: any, index: any) => matchResults[index]
                );
                if (matchingPageName) {
                    currentPage = "/" + matchingPageName; // Update currentPage directly
                }
                newApiData(api_data[0].NewApis)
            } else {
                console.log("no match found!")
            }

            var apiData = mappApis(currentPage, api_data[0].NewApis);
            let resApi = apiData[currentPage];

            axios.get(`${env_api_url}${resApi}`)
                .then(res => {
                    console.log("res.data in Level4", res.data)
                    setContent(res.data)
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err)
                })
        }).catch((e) => {
            console.log(e)
        })

    }, [currentPage])


    function mappApis(currentPage: any, newApi: []) {

        let pageName: any;
        let apis_links: any;
        let urlMap: any = {};
        let map1 = newApi.map(async (list: any, index: any) => {

            pageName = '/' + list.page_name;
            if (pageName.indexOf(currentPage) > -1) {
                apis_links = list.content_base_url;
                urlMap[pageName] = apis_links;
            }
        })
        return urlMap;
    }

    const formatData = () => {
        let level4HeaderData;
        let level4ImgContentData;
        let level4CenterSectionData;
        let level4TableSectionData;

        if (pageContent) {
            level4HeaderData = level4HeaderDetail(pageContent);
            level4ImgContentData = level4ImgContentDetail(pageContent);
            level4CenterSectionData = level4CenterSection(pageContent);
            level4TableSectionData = level4TableSection(pageContent)
            setLevel4Header(level4HeaderData);
            setLevel4ImgContentData(level4ImgContentData);
            setLevel4CenterData(level4CenterSectionData);
            setLevel4TableData(level4TableSectionData);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    useEffect(() => {
        formatData();
    }, [pageContent])

    useEffect(() => {
        setTimeout(() => {
            window.addEventListener('scroll', () => {
                document.querySelector('.headerPadding')?.classList.remove('headerPadding');
            })
        }, 3000)
        return () => {
            window.removeEventListener('scroll', () => { })
        }
    }, [])

    return (
        <>
            {loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
                <React.Fragment>
                    {currentPage === '/Locations' ? <TopSubMenuAboutUs currentPage={"/Locations"} />
                        : currentPage === '/WhoWeAre' ? <TopSubMenuAboutUs currentPage={"/WhoWeAre"} />
                            : currentPage === '/Quality' ? <TopSubMenuAboutUs currentPage={"/Quality"} />
                                // : currentPage === '/Careers' ? <TopSubMenuAboutUs currentPage={"/Careers"} />
                                    : ''
                    }

                    <BannerSection data={level4Header} />
                    {/* <SectionRight1 data={level4Overview} /> */}
                    <CommonRepetativeSections data={level4ImgContentData} />

                    {level4TableData !== '' ?
                        <CommonTable data={level4TableData} /> : ''
                    }
                    {level4CenterData == '' ? <ExploreAboutBiomerics /> :
                        <SectionCenterText10 data={level4CenterData} />}


                    <CommonCreativeSolution />
                </React.Fragment>
            }
        </>
    )

}
export default Level4