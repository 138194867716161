import React, { FC, useState, useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Button, Box, Typography, Container } from '@mui/material';
import { Helmet } from 'react-helmet';

interface IHeroSection {
    data: {
        field_anchorlink?: string
        field_button_text: string
        field_description: string
        field_image_url?: string
        field_reference?: string
        nid?: string
        title?: string
        field_button_url: string
        field_image_url_1: string
        field_seo_title?: string
        field_seo_description?: string
        field_seo_image?: string
        field_seo_keywords?: string
    }[];
}

const HeroSection: FC<IHeroSection> = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(prevIndex => (prevIndex + 1) % data.length);
        }, 13000); // Set the interval duration here

        return () => clearInterval(interval); 
    }, [data]); 

    return (
        <Carousel
            index={activeIndex}
            autoPlay={false} // Set autoPlay to false as we're controlling it manually
            animation={"fade"}
            indicator={false}
            stopAutoPlayOnHover={false}
            pauseOnFocus={null}
            dataPause={""}
            indicatorContainerProps={{
                className: "bullet-indicators"
            }}
            activeIndicatorIconButtonProps={{
                className: "active-indicator"
            }}
        >
            {data?.map((item: any, index: number) => {
                return <Item key={index} {...item} />
            })}
        </Carousel>
    );
}

const Item = (props: any) => {
    //console.log(props);
    const { title, field_description, field_button_text, fieldImage, nid, field_serial_no,field_button_url,field_image_url_1,field_seo_title
        ,field_seo_image, field_seo_description, field_seo_keywords } = props
    // const {name, description, buttonText}=props
   
    return (
        <React.Fragment>
            <Box className="hero-section home" display="flex" justifyContent="space-between" flexDirection="column">
                <img src={field_image_url_1} alt="" />

                {field_seo_title != undefined ?
                    <Helmet>
                            <meta charSet="utf-8" />
                            <title> {!field_seo_title[0] ? field_seo_title.split("&amp; ")[0] : field_seo_title.split("amp; ")[0]}</title>
                            <meta name="description" content={field_seo_description} />
                            <meta name="keywords" content={field_seo_keywords}></meta>
                            <meta property='keywords' content={field_seo_keywords}></meta>
                    </Helmet>
                :""}
                <Container maxWidth={false} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', maxWidth: '1736px' }}>
                  <Box position="relative"  zIndex="9">
                        <Typography variant="h1" fontSize="24px" fontWeight="600" className='slide-count'>0{field_serial_no}.</Typography> 
                            <Typography variant="h1" fontSize="88px" lineHeight={{xs:'1',md:'0.8'}}  >
                              {title.split("&amp;")[0]}
                            </Typography>
                        <Typography variant="h1" fontSize="88px" fontWeight="600" lineHeight='1'>{title.split("&amp;")[1] }</Typography>
                        <Typography component="p" fontWeight="500" fontSize="20px">
                           {field_description}
                        </Typography>
                        <Box mt={5}>
                            <Button variant="contained" className='btn-primary' sx={{ px: '24px' }}  href={field_button_url}>{field_button_text}</Button>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </React.Fragment>

    );
};


export default HeroSection;
