import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { DESGIN_SERVICES_PAGE_CONTENT_URL, MATERIAL_AND_COMPOUNDING_PAGE_CONTENT_URL, FINISHED_DEVICES_PAGE_CONTENT_URL, ENGG_COMPONENTS_PAGE_CONTENT_URL} from '../common/APi/apiurl';
import { formatHeroSection, formatRightTextSection1, formatCommonBottomSection, formatCommonRepitativeSectionTypeLevel1 } from '../common/utils/utils';
import { pageResponse } from '../Service/ServiceDetail';
import BannerSection from './sections/ServiceDetailsLevel1/BannerSection';
import SectionTextRight1 from './sections/ServiceDetailsLevel1/SectionTextRight1';
import CommonRepetativeSectionsLevel1 from './sections/ServiceDetailsLevel1/CommonRepetativeSectionsLevel1';
import CommonBottomSection from './sections/ServiceDetailsLevel1/CommonBottomSection';
import CommonCreativeSolution from '../components/CommonCreativeSolution'
import Header from '../components/HeaderSections/Header';


const Level1 = () => {
    let pageURL;
    const pathName = useLocation();
    const currentPage = pathName.pathname;
    const location = useLocation().pathname;

    const [loading, setLoading] = useState<boolean>(true)
    const [pageContent, setContent] = useState<null | any>(null);
    const [HeadingSectionData, setHeadingSection] = useState<null | any>(null);
    const [SectionRightData1, setSectionRightData1] = useState<null | any>(null);
    const [SectionLeftData2, setSectionLeftData2] = useState<null | any>(null);
    const [SectionLeftData3, setSectionLeftData3] = useState<null | any>(null);
    const [CommonBottomSectionData, setCommonBottomSection] = useState<null | any>(null);
    const [repitativeSectionTypeData, setRepitativeSectionTypeData] = useState<null | any>(null);

    const urlContentMappings = [
        { url: "/DesignServices", contentUrl: DESGIN_SERVICES_PAGE_CONTENT_URL },
        { url: "/Materials", contentUrl: MATERIAL_AND_COMPOUNDING_PAGE_CONTENT_URL },
        { url: "/FinishedDevices", contentUrl: FINISHED_DEVICES_PAGE_CONTENT_URL },
        { url: "/EngineeredComponents", contentUrl: ENGG_COMPONENTS_PAGE_CONTENT_URL },
    ];

    const mappedURLs = urlContentMappings.map(item => item.url.toLowerCase());


    function compareUrl(currentPage: string, mappedURLs: string[]): boolean[] {
      
        currentPage = currentPage.toLowerCase();

        const matchArray = mappedURLs.map((pageName) => currentPage === pageName);

        return matchArray;
    }

    const matchResults = compareUrl(currentPage, mappedURLs);

    const isSame = matchResults.some((match: any) => match);

    const fetchResponse = async () => {

        if (isSame) {
            const matchedIndex = matchResults.findIndex(match => match);
            const response = await pageResponse(urlContentMappings[matchedIndex].contentUrl);
           
            setContent(response);
            setLoading(false);
        } else {

            console.error("No matching URL found for currentPage:");
        }
    };

    const formatData = () => {
        let newsData;
        let titlesection: any;
        let HeadingSection;
        let SectionRightData1;
        let SectionLeftData2;
        let SectionLeftData3;
        let CommonBottomSection;
        let repitativeSectionTypeData;
        if (pageContent) {
        
            HeadingSection = formatHeroSection(pageContent);
            SectionRightData1 = formatRightTextSection1(pageContent);
            CommonBottomSection = formatCommonBottomSection(pageContent);
            repitativeSectionTypeData = formatCommonRepitativeSectionTypeLevel1(pageContent);
            setHeadingSection(HeadingSection);
            setSectionRightData1(SectionRightData1);
            setSectionLeftData2(SectionLeftData2);
            setSectionLeftData3(SectionLeftData3);
            setCommonBottomSection(CommonBottomSection);
            setRepitativeSectionTypeData(repitativeSectionTypeData);
        }
    }

    useEffect(() => {

        const currentHash: string = window.location.hash;
        const document: Document = window.document || {} as Document;
        if (pathName.hash) {
            if (document?.querySelector(pathName.hash)) {
                setTimeout(() => {
                    document?.querySelector(pathName.hash)?.classList.add('headerPadding')
                    document?.querySelector(pathName.hash)?.scrollIntoView({ behavior: 'smooth', block: "start" });
                }, 100)
            }
        }
    }, [pageContent, loading]);

    useEffect(() => {
        window.scroll(0, 0)
        fetchResponse();
    }, [location])

    useEffect(() => {
        formatData();

    }, [pageContent])
    return (
        <>
            {loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
                <React.Fragment>
                    <BannerSection data={HeadingSectionData} />
                    <SectionTextRight1 data={SectionRightData1} />
                    <CommonRepetativeSectionsLevel1 data={repitativeSectionTypeData} />
                    <CommonBottomSection data={CommonBottomSectionData} />
                    <CommonCreativeSolution />
                </React.Fragment>
            }
        </>

    )
}

export default Level1