import React, {FC} from 'react';
import Carousel from 'react-material-ui-carousel';
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, 
ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import AboutUsImg from '../../../assets/images/About-us-banner.png';
import {Helmet} from 'react-helmet';


interface HeroSection {
    data: {
        field_description: string,
        nid?: string
        title?: string
        field_image_url_1:string
        field_seo_title?:string 
       field_seo_description?:string 
       field_seo_image?:string
       field_seo_keywords?:string
    }[]
};
const BannerSection: FC<HeroSection> = ({ data }) => {
  
    return (
      <
        >
            {data?.map((item: any, i: number) => {
                return <Item key={i} {...item} />
            })}
        </>
    );

}

const Item = (props: any) => {
    const { title, field_description, field_button_text, field_image_url_1,field_seo_title, field_seo_description, field_seo_image, field_seo_keywords} = props
    return (
        <React.Fragment>
            <Box className="hero-section blog-banner" display="flex" justifyContent="space-between"
             flexDirection="column" py={{md:"40px",lg:"64px"}}>
                <img src={field_image_url_1} alt="" />
                {field_seo_title != undefined ?
               <Helmet>
                        <meta charSet="utf-8" />
                         <title> {!field_seo_title[0] ? field_seo_title.split("&amp;")[0] : field_seo_title.split("amp;")[0]}</title>
                         <meta name="description" content={field_seo_description}></meta>
                         <meta name="keywords" content={field_seo_keywords}></meta>
                         <meta property='keywords' content={field_seo_keywords}></meta>
                         <meta property="og:title" content={!field_seo_title[0] ? field_seo_title.split("&amp; ")[0] : field_seo_title.split("amp; ")[0]} />
                         <meta property="og:description" content={field_seo_description}/>
                        <meta property="og:image" content={field_seo_image}/> 
                 </Helmet> 
                 :""}
              <Container maxWidth={false} sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', maxWidth: '1736px'
                }}>
                    <Box position="relative" zIndex="9" mt={{ xs: '0px', lg: '136px' }}>
                        <Typography variant="h1" fontSize="88px" fontWeight="600">
                            <Typography  component="span"  fontSize='inherit' fontWeight="600">NEWS &amp;
                            </Typography>
                            <Typography  component="span" fontSize='inherit'  > RESOURCES
                            </Typography>
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </React.Fragment>
    );
};


export default BannerSection;