import React from 'react'
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, 
ListItem, ListItemAvatar, ListItemText, Link } from '@mui/material';
import aboutEnggCompany from '../../../assets/images/about-engineer-company.jpg';
const MoreAboutBiomarices = (props:any) => {
    const { data } = props;
    const submenu_data=[
        {
            "nid": "2",
            "title":"Explore Biomerics",
            "field_reference": "about_sub_menu_data",
            "about_submenu_data":
                [
                    {
                        "sid": "0",
                        "title_name": "WHO WE ARE",
                        "link":"/WhoWeAre"
                        
                    },
                    {
                        "sid": "1",
                        "title_name": "LEADERSHIP",
                         "link":"/Leadership"
                     },
                  
                    {
                       "sid": "2",
                       "title_name": "LOCATIONS",
                        "link":"/Locations"
                    },
                    
                    //  {
                    //     "sid": "3",
                    //     "title_name": "MARKETS",
                    //      "link":"/Markets"
                    //  },
                     {
                        "sid": "3",
                        "title_name": "QUALITY",
                         "link":"/Quality"
                     },
                    //  {
                    //     "sid": "4",
                    //     "title_name": "CAREERS",
                    //      "link":"/Careers"
                    //  },
                     {
                        "sid": "4",
                        "title_name": "OUR BOARD",
                         "link":"/OurBoard"
                     },
                ]
        },
    ];
    //console.log(submenu_data[0].title, "vvvvv")
    const about_data = submenu_data[0].about_submenu_data;
       const listData = () => {
            return  about_data.map((submenu_list:any)=>{ 
                return (
                <ListItem>
                    <Link href={submenu_list.link}>{submenu_list.title_name}</Link>
                </ListItem>
                )
            })
          };
          listData();      
return (
    <Box>
        <Typography variant="h1" fontWeight="500" color="#4A4948" fontSize='36px' textAlign='center' mx='auto' mb={5} >
            {submenu_data[0].title}
        </Typography>
        <Box className='sub-nav bottom'>
            <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>
                <List disablePadding className='about-menu-list'>
                    {listData()}
                </List>
            </Container>
        </Box>
    </Box>
)
}
export default MoreAboutBiomarices;