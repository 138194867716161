import React from 'react'
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, 
    ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import img1 from '../../assets/images/img1.jpg'
import arrow from '../../assets/images/arrow.svg'
import Link from '@mui/material/Link';
//import DesignImg from '../../assets/images/DesignServiceImage.jpg'
import FDImg from '../../assets/images/FDImage.jpg';
import MaterialImg from "../../assets/images/MaterialImg.jpg";
import ECImage from "../../assets/images/EcImage.png";
//Get Images by const variable
import wellVersed from '../../../assets/images/well-versed.jpg';
import ReactPlayer from 'react-player';
const CommonRepetativeSectionsLevel1=(props: any) => {
const { data } = props;
let number;
//console.log("WellVersedData::::", data[0].title);
return data.map((commonData: any, index: any) => {
    number = index;
    var list_1 = commonData.field_description2.split("\r\n");
    var list_3 = commonData.field_description4.split("\r\n");
    function decodeEntity(inputStr:any) {
      var textarea = document.createElement("textarea");
      //console.log(textarea,"textarea")
      textarea.innerHTML = inputStr;
      return textarea.value;
    }
    const field_description3 =  decodeEntity(commonData.field_description3);
    const Description3 = [
      {
        field_description3:field_description3
      }
    ];
    const field_description =  decodeEntity(commonData.field_description);
    const Description = [
      {
        field_description:field_description
      }
    ];
    var bulletsColor = { "--bullet-bg-color": commonData.field_bullets_fgcolor } as React.CSSProperties;
    const padding_top = {
      paddingTop: commonData.field_center_top_padding+'!important'
    };
    const padding_bottom = {
      paddingBottom: commonData.field_center_bottom_padding+'!important'
    };

    const padding_left = {
      paddingLeft: commonData.field_pd_image_left+'!important'
    };
    const padding_right = {
      paddingRight: commonData.field_pd_image_right+'!important'
    };
    const ShowlistData_1 = (list_1: any) => {
        return list_1.map((data: any, i: any) => {
  
          return (<>
            {data !== "" ? <ListItem disablePadding style={{ color: commonData.field_title_fgcolor }}>
              <ListItemText>{data.split("amp;")}</ListItemText>
            </ListItem> : ""}
          </>
          )
        });
      };
    
      const ShowlistData_3 = (list_3: any) => {
        return list_3.map((data: any, i: any) => {
          return (<>
            {data !== "" ?
              <ListItem disablePadding style={{ color: commonData.field_title_fgcolor }}>
                <ListItemText>{data.replace("&gt;", ">").split("amp;")}</ListItemText>
              </ListItem>
              : ""}
          </>
          )
        });
      };
      ShowlistData_1(list_1);
      ShowlistData_3(list_3);
    return (
        <Box className='section' style={{ background: commonData.field_bg_color }}  id={"section"+(commonData.nid)}>
          <Box pt={6} className='content'>
            <Grid container spacing={6} justifyContent='end' className={number % 2 !== 0 ? "revers" : ""}>
              <Grid item xs={12} lg={6}>
              <Box className="text-section" pb={{ xs: '20px', md: '64px' }} sx={{
              pl: commonData.field_pd_left !== "" ? commonData.field_pd_left : "",
              pr: commonData.field_pd_right !== "" ? commonData.field_pd_right : ""
            }} >
                <Typography variant="h1" fontWeight="500"  className='section-heading'    style={bulletsColor} color={{ color: commonData.field_title_fgcolor }} >
                {commonData.title.split("amp;")}
                </Typography>
                {/* <Typography mb="25px" fontSize='25px'  sx={{maxWidth:'635px'}} color={{ color: commonData.field_title_fgcolor }} >
                {commonData.field_description.split("amp;")}
                </Typography> */}
                {Description.map((v) => (
                    <Typography mb="25px" fontSize='25px'  sx={{maxWidth:'635px'}} color={{ color: commonData.field_title_fgcolor }} >
                        <div  dangerouslySetInnerHTML={{ __html: v.field_description }} /> 
                     </Typography>
                 ))} 
                {Description3.map((v) => (
                    <Typography mb="25px" fontSize='25px'  sx={{maxWidth:'635px'}} color={{ color: commonData.field_title_fgcolor }} >
                        <div  dangerouslySetInnerHTML={{ __html: v.field_description3 }} /> 
                     </Typography>
                 ))} 
                <Typography mb="25px" fontSize='25px'  sx={{maxWidth:'635px'}}>
                {list_1 !== "" ?
                <List disablePadding className='custom-list' style={bulletsColor}>
                  {list_1 !== "" ? ShowlistData_1(list_1) : ""}
                </List> : ""}
                {/* {list_3 !== "" ?
                  <List disablePadding className='custom-list' style={bulletsColor}>
                    {list_3 !== "" ? ShowlistData_3(list_3) : ""}
                  </List>
                  :
                  ""} */}
                </Typography>
                <Typography mb="20px" fontSize='25px'>
                    <div dangerouslySetInnerHTML={{ __html: commonData.body}}/>
                </Typography>
                {commonData.field_button_text !== ""  ?
              <Box mt={4}>
                 <Button variant="contained"
                    id={commonData.nid}
                    //onClick={onOpenModal}
                    
                    href={decodeEntity(commonData.field_button_url)}
                     className={
                     commonData.field_button_color ==="#29A7DF" ? 'btn-blue'
                     :commonData.field_button_color === "#FAFAFA" ? 'btn-white'
                    : ''
                    } sx={{ px: '24px' }}>
                     {
                      commonData.field_button_type ==='video'? commonData.field_button_text.replace("&#039;", "'"):
                      commonData.field_button_type ==='pdf'? commonData.field_button_text.replace("&#039;", "'"):
                      commonData.field_button_type ==='redirect'? commonData.field_button_text.replace("&#039;", "'"):''
                    }
                 
                  </Button>
                </Box>:""}
                </Box>
              </Grid>
             
              {/* <Grid item xs={12} lg={6} display='flex'  justifyContent='center'pt={padding_top} pb={padding_bottom} pl={padding_left} pr={padding_right} >
              <Box className='w-100 h-100' display='flex' 
                //  justifyContent='center'
                //   alignItems='center'
                justifyContent={commonData.field_justify_content}
                alignItems={commonData.field_align_items !== "" ?
               commonData.field_align_items : 'center'} >
                
                <img src={commonData.field_image_url_1} alt="" className='img-responsive' />
              </Box>
               </Grid> */}


              <Grid item xs={12} lg={6} className="img-wrapper" >
                {commonData.field_video !== "" ?
                  <Box className="w-100 h-100" display='flex' justifyContent={commonData.field_justify_content}
                    alignItems={commonData.field_align_items !== "" ? commonData.field_align_items : 'center'}>
                    <Box className='video-wrapper'>
                      <ReactPlayer url={commonData.field_video} width='100%'
                        height='100%' />
                    </Box>
                  </Box>
                  : <Box className='w-100 h-100' display='flex'  pt={padding_top} pb={padding_bottom} pl={padding_left} pr={padding_right} 
                    justifyContent={commonData.field_justify_content}
                      alignItems={commonData.field_align_items !== "" ?
                    commonData.field_align_items : 'center'} >
                    <img src={commonData.field_image_url_1} alt="" className='img-responsive' />
                  </Box>}
              </Grid>





            </Grid>
          </Box>
      </Box>
    )}
)}

export default CommonRepetativeSectionsLevel1;


// import React from 'react'
// import { Container, Box, Typography, Grid, Card, CardContent, Button,List, 
//     ListItem, ListItemAvatar, ListItemText } from '@mui/material';
// import img1 from '../../assets/images/img1.jpg'
// import arrow from '../../assets/images/arrow.svg'
// import Link from '@mui/material/Link';
// //import DesignImg from '../../assets/images/DesignServiceImage.jpg'
// import FDImg from '../../assets/images/FDImage.jpg';
// import MaterialImg from "../../assets/images/MaterialImg.jpg";
// import ECImage from "../../assets/images/EcImage.png";
// //Get Images by const variable
// import wellVersed from '../../../assets/images/well-versed.jpg';
// const CommonRepetativeSectionsLevel1=(props: any) => {
// const { data } = props;
// let number;
// //console.log("WellVersedData::::", data[0].title);
// return data.map((commonData: any, index: any) => {
//     number = index;
//     var list_1 = commonData.field_description2.split("\r\n");
//     const ShowlistData_1 = (list_1: any) => {
//         return list_1.map((data: any, i: any) => {
  
//           return (<>
//             {data !== "" ? <ListItem disablePadding style={{ color: commonData.field_title_fgcolor }}>
//               <ListItemText>{data.split("amp;")}</ListItemText>
//             </ListItem> : ""}
//           </>
//           )
//         });
//       };
//     return (
//         <Box className='section' pb={8} pt={2} style={{background: 'linear-gradient(180deg, #E7E7E7 0%, #B3B3B3 100%)'}}>
//         <Container maxWidth={false} sx={{ maxWidth: '1736px' }} >
//           <Box pt={15} className='content'>
//             <Grid container spacing={3} justifyContent='end' className={number % 2 !== 0 ? "revers" : ""}>
//               <Grid item xs={12} lg={6}>
//                 <Typography variant="h1" fontWeight="500"  className='section-heading' sx={{maxWidth:'329px'}}>
//                 {commonData.title.split("amp;")}
//                 </Typography>
//                 <Typography mb="25px" fontSize='25px'  sx={{maxWidth:'635px'}}>
//                 {commonData.field_description.split("amp;")}
//                 </Typography>
//                 <Typography mb="25px" fontSize='25px'  sx={{maxWidth:'635px'}}>
//                 {list_1 !== "" ?
//                 <List disablePadding className='custom-list' >
//                   {list_1 !== "" ? ShowlistData_1(list_1) : ""}
//                 </List> : ""}
//                 </Typography>
//               </Grid>
//               <Grid item xs={12} lg={6} display='flex'  justifyContent={commonData.field_justify_content}
//                   alignItems={commonData.field_align_items !== "" ?
//                  commonData.field_align_items : 'center'} >
//                <img src={commonData.field_image_url_1} alt="" className='img-responsive w-100'/>
//                </Grid>
//             </Grid>
//           </Box>
//         </Container>
//       </Box>
//     )}
// )}