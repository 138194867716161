import React from 'react'
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, 
ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import visionImg from '../../../assets/images/about-vision.jpg';
const FocusOurVision = (props: any) => {
    const { data } = props;
    function decodeEntity(inputStr:any) {
        var textarea = document.createElement("textarea");
        //console.log(textarea,"textarea")
        textarea.innerHTML = inputStr;
        return textarea.value;
    }
    const field_description =  decodeEntity(data[0].field_description);
    const fieldDescription = [
        {
          fieldDescription:
          field_description
        }
      ];
return (
    <Box className='e2e-capablities focus-vision' pt={{ xs: '10px', lg: '30px' }} top="0">
    <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>
        <Grid container>
            {/* <Box className='white-box'></Box> */}
            <Grid item xs={12} md={6}>
                <Box p={4} className='content' sx={{ justifyContent: 'start !important' }}>
                    <Typography variant="h1" fontSize="40px"  mb='25px' maxWidth={{ xs: '100%', lg: '480px' }} >
                         {/* <strong>VISION</strong> */}
                         <Typography component='div' fontWeight="500" fontSize="40px" lineHeight='46px' >{data[0].title}</Typography>
                         <Typography component='span' fontWeight="700" fontSize="40px"  lineHeight='46px'>{data[0].field_title1}</Typography>
                    </Typography>
                    {fieldDescription.map((v) => (
                         <Typography component="p" mb="20px" fontSize='25px' maxWidth="672px">
                            <div dangerouslySetInnerHTML={{ __html: v.fieldDescription }}/> 
                        </Typography>
                    ))} 
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <img src={data[0].field_image_url_1} alt="" width="100%" />
            </Grid>
            <Grid item xs={12}>
                <Box className='blue-bar'></Box>
            </Grid>
        </Grid>
    </Container>
</Box>
)
}
export default FocusOurVision;