import React, { useEffect } from 'react'
import { Grid, Button, List, ListItem, ListItemAvatar, ListItemText, Box, Container, Typography } from '@mui/material';
import newsImg from '../../../assets/images/newsImg.jpg'
import newsImg1 from '../../../assets/images/newsImg1.jpg'
import newsImg2 from '../../../assets/images/newsImg2.jpg'
import newsImg3 from '../../../assets/images/newsImg3.jpg'
import axios from 'axios';
import { truncateString } from '../../../common/utils/utils';
//Api url
import {env_api_url} from '../../../common/Constants';
const imageArray = [newsImg1, newsImg2, newsImg3]
const NewsSection = (props: any) => {
    const { data } = props;
    var pageData;
    const [pagesData, setpagesData] = React.useState<any>([]);
    const [pinnedData, setPinnedData] = React.useState<any>([]);
    useEffect(() => {
        axios
            .get(`${env_api_url}bio/v1/biomerics/blogs/?page=0&is_pinned=true`)
            .then(res => {
                setPinnedData(res.data)
              
            })
            .catch(err => {
                
            })
    }, []);
    useEffect(() => {
        axios
            .get(`${env_api_url}bio/v1/biomerics/blogs/?page=0&is_pinned=false`)
            .then(res => {
                setpagesData(res.data)
                
            })
            .catch(err => {
                
            })
    }, []);
    const blogData = () => {
        return pagesData.slice(1).filter((commonData: any, index: any) => index < 3).map((commonData: any, index: any) => {
            return (
                <ListItem sx={{ alignItems: 'start', px: '0' }}>
                    <ListItemAvatar sx={{ mt: '12px', mr: '34px' }}>
                        <img src={commonData.field_blog_bannerimage} alt="" />
                    </ListItemAvatar>
                    <ListItemText>
                        <Typography variant="h4" mb="18px" fontSize="28px">
                            {commonData.title}
                        </Typography>
                        <Box><Button variant="contained" className='btn-blue' sx={{ borderRadius: '0 !important' }}
                            href={'/BlogDetails' + `${commonData.field_blog_address_url}`}
                        >
                            READ MORE
                        </Button></Box>
                    </ListItemText>
                </ListItem>
            )
        })
    }

    const pinnedBlogData = () => {
        return pinnedData.filter((commonData: any, index: any) => index < 1).map((commonData: any, index: any) => {
          
            return (
                <Grid item xs={12} lg={7}>
                    <Typography variant="h1" fontWeight="500" color="#4A4948" className='section-heading'>
                        News &amp; resources
                    </Typography>
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center">
                        {<img src={commonData.field_blog_bannerimage} alt="" />}
                        <Box className='news-box' display="flex" flexDirection="column" justifyContent="space-between" pl={{xs:'20px', md:'32px'}} pr={{xs:'20px', md:'56px'}} py={{xs:'20px', md:'32px'}}>
                            <Box>
                                <Typography variant="h4" mb="20px" fontSize={{ lg: '32px', xl: '45px' }}>
                                    {truncateString(commonData.title, 50)     }
                                </Typography>
                            </Box>
                            <Box>
                                <Button variant="contained" className='btn-blue' sx={{ borderRadius: '0 !important' }}
                                    href={'/BlogDetails' + `${commonData.field_blog_address_url}`}
                                >
                                    READ MORE
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            )
        })
    }
    return (
        <Box className='news-and-resource' pt={{xs:'70px', md:'104px'}}>
            <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>
                <Grid container spacing={{ xs: 2, lg: 4, xl: 8 }}>
                    {pinnedBlogData()}
                    <Grid item xs={12} lg={5} className='news-blog'>
                        <List sx={{ width: '100%', mt: '30px' }}>
                            {blogData()}

                        </List>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )

}

export default NewsSection
