import React from "react";
import { Button, Typography, Grid, Box } from "@mui/material";
import leftBottomImg from "../assets/images/leftBottomImg.png";
import topRightImg from "../assets/images/topRightImg.png";
import {useNavigate} from "react-router-dom"
import CommonCreativeSol from "./sections/CreativeSolution";
import CommonCreativeSolution from "./CommonCreativeSolution";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <>
    
   
      <Box className="page-not-found-wrapper">
        <Grid container  sx={{ textAlign: "center" }} className="h-100">
        <Grid item xs={12} md={4}  >
          <div className="page-not-found-bg">
              <img
                src={leftBottomImg}
                alt="left_bottom"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={12} sx={{display:"flex", alignItems:"center", justifyContent:"center", padding:"0px 0 80px 0"}} className="page-content">
            <div>
              <Typography sx={{color:"#fff", fontWeight:"700", fontSize:"170px"}} variant="h1">404</Typography>
              <Typography sx={{fontWeight:"700", fontSize:"50px", color:"#fff"}} variant="h2">Page not found</Typography>
              <div style={{padding:"20px 0 35px 0"}}>
              <Typography sx={{color:"#fff"}}>
                We're sorry, the page you requested could not be found.
              </Typography>
              <Typography sx={{color:"#fff"}}>Please go back to the home page.</Typography>
              </div>

              <Button className="page-not-found-btn"
                sx={{
                  padding: "0 15px",
                  fontSize: "13px",
                  fontWeight: "700",
                  backgroundColor:"#fff"
                  
                }}
                style={{ width: "180px", height: "46px",color:"#000"}}
              
                onClick={()=>navigate("/")}
              >
                Back to homepage
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={4} >
          <div className="page-not-found-bg-right">
              <img
                src={topRightImg}
                alt="top-right"
              />
            </div>
          </Grid>
        </Grid>
      </Box>
     <div className="common-creative-space">
     <CommonCreativeSolution/>
     </div>
   
    </>
  );
};

export default PageNotFound;
