






import React, { useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Grid, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import Link from '@mui/material/Link';
import Header from '../components/HeaderSections/Header';
import Footer from './Footer';
import CommonCreativeSolution from '../components/CommonCreativeSolution'
//Abous us sections
import BannerSection from '../components/sections/AboutUsAllSections/BannerSection';
import RightTextsection1 from '../components/sections/AboutUsAllSections/RightTextsection1';
import MoreAboutUs2 from '../components/sections/AboutUsAllSections/MoreAboutUs2';
import FocusOurVision3 from './sections/AboutUsAllSections/FocusOurVision3';
import LivingOurValues4 from '../components/sections/AboutUsAllSections/LivingOurValues4';
import SingleImageSection5 from '../components/sections/AboutUsAllSections/SingleImageSection5';
import GrowthAndExpansion6 from '../components/sections/AboutUsAllSections/GrowthAndExpansion6';
import MedicalDeviceIndustry7 from '../components/sections/AboutUsAllSections/MedicalDeviceIndustry7';
import MoreAboutBiomarices from './sections/AboutUsAllSections/MoreAboutBiomarices8';
//Api url
import {ABOUT_US_PAGE_CONTENT_URL} from '../common/APi/apiurl';
import {AboutHeroSection,AboutEnggCompSection,AboutFocusedOurVisionSection,AboutLivingValues,AboutGrowthExpansion,AboutMedicalDevice,
    AboutGetToKnow } from '../common/utils/utils';
import { AboutUsPageResponse } from '../Service/AboutUs';
import TopSubMenuAboutUs from './sections/ServiceDetailsLevel2/TopSubMenuAboutUs';
import DrawerComponent from './Drawer';
    
const About = () => {
    let pageURL;
    const pathName = useLocation();
    const currentPage = pathName.pathname;
    const location=useLocation().pathname;
    const [loading, setLoading] = useState<boolean>(true)
    const [pageContent, setContent] = useState<null | any>(null);
    const[ HeadingSectionData, setHeadingSection]= useState<null | any>(null);
    const[ AboutEnggCompData, setAboutEnggCompData]= useState<null | any>(null);
    const[FocusedOurData, setFocusedOurData]= useState<null | any>(null);
    const[ LivingValuesData, setLivingValuesData]= useState<null | any>(null);
    const[ GrowthExpansionData, setGrowthExpansionData]= useState<null | any>(null);
     const[ MedicalDeviceData, setMedicalDeviceData]= useState<null | any>(null);
     const[ GetToKnowData, setGetToKnowData]= useState<null | any>(null);



     const urlContentMappings = [
        { url: "/About", contentUrl: ABOUT_US_PAGE_CONTENT_URL },
        { url: "/Values", contentUrl: ABOUT_US_PAGE_CONTENT_URL },
       
    ];

    const mappedURLs = urlContentMappings.map(item => item.url.toLowerCase());


    function compareUrl(currentPage: string, mappedURLs: string[]): boolean[] {
        currentPage = currentPage.toLowerCase();

        const matchArray = mappedURLs.map((pageName) => currentPage === pageName);

        return matchArray;
    }

    const matchResults = compareUrl(currentPage, mappedURLs);

    const isSame = matchResults.some((match: any) => match);

    

    const fetchResponse = async () => {
       
        if (isSame) {
            const matchedIndex = matchResults.findIndex(match => match);
            const response = await AboutUsPageResponse(urlContentMappings[matchedIndex].contentUrl);
            setContent(response);
            setLoading(false);
        } else {

            console.error("No matching URL found for currentPage:");
        }
    };
   
    const formatData = () => {
        let newsData;
        let titlesection: any;
        let HeadingSection;
        let AboutEnggCompData;
        let FocusedOurData;
        let LivingValuesData;
        let GrowthExpansionData;
        let MedicalDeviceData;
        let GetToKnowData;
        if (pageContent){
            HeadingSection = AboutHeroSection(pageContent);
            AboutEnggCompData=AboutEnggCompSection(pageContent);
            FocusedOurData= AboutFocusedOurVisionSection(pageContent);
            LivingValuesData= AboutLivingValues(pageContent);
            GrowthExpansionData = AboutGrowthExpansion(pageContent);
            MedicalDeviceData = AboutMedicalDevice(pageContent);
            GetToKnowData=AboutGetToKnow(pageContent);
            setHeadingSection(HeadingSection);
            setFocusedOurData(FocusedOurData);
            setAboutEnggCompData(AboutEnggCompData);
            setLivingValuesData(LivingValuesData);
            setGrowthExpansionData(GrowthExpansionData);
            setMedicalDeviceData(MedicalDeviceData);
            setGetToKnowData(GetToKnowData);
            // console.log(AboutEnggCompData[0].field_description, "checkdata")
        }
      
    }
console.log("LivingValuesData in the About page-------->", LivingValuesData)

useEffect(() => {
    // formatData();
    const lowercasePathname = pathName.pathname.toLowerCase();
    console.log("lowercasePathname in the About page......", lowercasePathname);
    let targetElementId = '';

    if (lowercasePathname === '/values') {
      targetElementId = '#section262';
    }

    if (targetElementId) {
        setTimeout(() => {
          const anchor = document.createElement('a');
          anchor.href = targetElementId;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        }, 1000);
        
      }
}, [pathName]); 


    // setTimeout(() => {
    //     window.addEventListener("scroll", () => {
    //         document.querySelector('.headerPadding')?.classList.remove('headerPadding');
    //     })
    // }, 1500)
    

    useEffect(() => {
        const currentHash: string = window.location.hash;
       // console.log("location", pathName)
        const document: Document = window.document || {} as Document;
        if (pathName.hash) {
            if (document?.querySelector(pathName.hash)) {
                setTimeout(() => {
                    document?.querySelector(pathName.hash)?.classList.add('headerPadding')
                    document?.querySelector(pathName.hash)?.scrollIntoView({ behavior: 'smooth', block: "start"});
                }, 100)
            }
        }

        setTimeout(() => {
                window.addEventListener("scroll", () => {
                    document.querySelector('.headerPadding')?.classList.remove('headerPadding');
                })
            }, 1500)
            return ()=>{
                window.removeEventListener("scroll", () => {
                    document.querySelector('.headerPadding')?.classList.remove('headerPadding');
                })
            }

    },[pathName.hash, pageContent, loading]);

    useEffect(()=>{
        window.scrollTo(0,0)
        fetchResponse();
    },[location])
    
    useEffect(() => {
        formatData();
       
    }, [pageContent])

    useEffect(()=>{
        //window.scrollTo(0,0)
        setTimeout(()=>{
            window.addEventListener("scroll", () => {
                document.querySelector('.headerPadding')?.classList.remove('headerPadding');
            
              })
        },3000)
     return()=>{
        window.removeEventListener("scroll", () => {
            //  document.querySelector('.headerPadding')?.classList.remove('headerPadding');
            
            })
     }            
    },[])
    setTimeout(() => {
        window.addEventListener("scroll", () => {
            document.querySelector('.headerPadding')?.classList.remove('headerPadding');
        })
    }, 1500)
 
   
    return ( 
    <>
  
        {loading ? <h1 style={{display:'none'}}>Loading....</h1> :

<React.Fragment>
   
  {/* <Header /> */}

        <TopSubMenuAboutUs  currentPage={currentPage}/>
<BannerSection data={HeadingSectionData}/>
<RightTextsection1  data={AboutEnggCompData}/>
<FocusOurVision3  data={FocusedOurData}/>
<LivingOurValues4  data={LivingValuesData} />
 <MoreAboutUs2 />
<SingleImageSection5 />
<GrowthAndExpansion6 data={GrowthExpansionData}/>
{/* <MedicalDeviceIndustry7 data={MedicalDeviceData} /> */}
<MoreAboutBiomarices  data={GetToKnowData} />
<CommonCreativeSolution /> 
{/* <Footer /> */}
</React.Fragment>
 } 
    </>
    );
}
export default About;





