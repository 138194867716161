//balloon catherters
import Api from "../../common/APi/api";
import axios from "axios";
export const contactUsPageResponse = async (url: string) => {
  const response = await Api.get(url);
  //console.log("Service", response);
  return response.data;
}


export const postUserFormDataForContactPage = async (payloadBody: any) => {

  var config = {
    method: 'post',
    url: 'https://ifr771wo94.execute-api.us-east-1.amazonaws.com/dev/v1/bio/ContactUs',
    headers: {
      'Content-Type': 'application/json'
    },
    data: payloadBody
  };
  const res = await axios.post('https://ifr771wo94.execute-api.us-east-1.amazonaws.com/dev/v1/bio/ContactUs',
    payloadBody, {
      headers: {
        'Content-Type': 'application/json'
      }
  })
  //const response= await axios(config)
  return res.data;
}


export const fetchAreaOfInterestDropdownValue = async () => {
  const response = await axios.get('https://ifr771wo94.execute-api.us-east-1.amazonaws.com/dev/v1/bio/areaofinterest');

  return response.data;
}

//https://auvr94d5p4.execute-api.us-east-1.amazonaws.com/dev/v1/bio/salesForce

export const postUserFormDataForRFQPage = async (payloadBody: any) => {


  const res = await axios.post('https://ifr771wo94.execute-api.us-east-1.amazonaws.com/dev/v1/bio/biomericsRfq',
    payloadBody, {
      headers: {
        'Content-Type': 'application/json'
      }
  })
  //const response= await axios(config)
  return res.data;
}
