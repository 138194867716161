import React from 'react'
import { Container, Box, Typography, Grid, Card, CardContent, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import img1 from '../../assets/images/img1.jpg'
import arrow from '../../assets/images/arrow.svg'
import Link from '@mui/material/Link';
//import DesignImg from '../../assets/images/DesignServiceImage.jpg'
import FDImg from '../../assets/images/FDImage.jpg';
import MaterialImg from "../../assets/images/MaterialImg.jpg";
import ECImage from "../../assets/images/EcImage.png";
import fastbaloonImg from '../assets/images/fast-baloon.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const CommonTable = (props: any) => {
  const { data } = props;
  
  // console.log(bulletsColor, "bulletsColor")
   return data.map((commonData: any, index: any) => {
    var bulletsColor = { "--bullet-bg-color": commonData.field_bullets_fgcolor!=="" && commonData.field_bullets_fgcolor!==undefined ? 
    commonData.field_bullets_fgcolor:'' } as React.CSSProperties;
  return (
     <Box className='balloon-capabilities ' pt={8}>
        <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>
            <Typography variant="h4" mb="50px" fontWeight="500" color="#4A4948" textAlign='center' className='section-heading' style={bulletsColor}  >
                {commonData.title!=="" ? commonData.title.split('amp;'):""
                }
            </Typography>
            <Box className='table-responsive'>
                {commonData.body!==""?
                <div  dangerouslySetInnerHTML={{ __html: commonData.body }} /> :
                '' }
            </Box>
        </Container>
    </Box>
    )

})
}

export default CommonTable;
