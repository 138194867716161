import React, { FC, useState } from 'react'
import { Button,Box, Container, Typography, Grid, Accordion, AccordionSummary, AccordionDetails,Link } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface IBioDifferenceSectionProps {
    sectionId: string
    data: {
        title: string
        field_description: string
        nid?: string
        field_button_url:string
    }[]
    sectionHead: {
        title: string
        field_description: string
        nid?: string
        field_button_url:string
    }[]
}


const BioDifferenceSection: FC<IBioDifferenceSectionProps> = ({ sectionId, data, sectionHead }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const ss = data.filter((bioDiffContent, index) => {
        return bioDiffContent.nid === "13"
    })
   // console.log("filteredData", sectionHead)
    return (
        <>
            <Box className='creating-value' pt={{xs:'50px', md:'80px'}} pb={{xs:'50px', md:'112px'}}>
                <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>
                    <Typography variant="h1" fontWeight="500" color="#ffffff" className='section-heading'>
                        {/* Creating value as a strategic partner to global OEMs */}
                        {sectionHead[0]?.title}
                    </Typography>
                    <Typography variant='h6' color="#ffffff" fontWeight="700" fontSize="26px" mb={6}>{sectionHead[0]. field_description}
                    </Typography>
                    <Grid container spacing={{ xs: 2, md: 4, lg: 8 }} className='card-grid'>
                        {isMobile ? <MobileAccordain data={data} /> : <DesktopView data={data} />}
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default BioDifferenceSection

interface IMobileScreen {
    data: {
        title: string
        field_description: string
        nid?: string
        field_button_url:string
    }[]

}
const MobileAccordain: FC<IMobileScreen> = ({ data }) => {
    const [expanded, setExpanded] = useState<string | false>('165');
    const renderContent = () => {

        //console.log("fd", fd)
        return data.map((fd, index) => {
            return <Accordion expanded={expanded === fd.nid} onChange={handleChange(fd.nid)} key={index} >
                <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                >
                <Typography variant='h1' fontWeight={500} mb={1}>{fd.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography fontSize="18px" color="#4A4948">
                        {fd.field_description}
                    </Typography>
                </AccordionDetails>
            </Accordion >
        })
    }

    const handleChange =
        (panel: string | any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };
    return <>
        {renderContent()}

    </>
}

const DesktopView: FC<IMobileScreen> = ({ data }) => {
    //const {title, field_description}=data;

    const renderContent = () => {
        return data.map((data, index) => {
            return <Grid item xs={12} md={6} display="flex">
                <Link p={4} className='creating-value-box cursor-pointer' href={data.field_button_url} underline="none">
                  {/* <Button href={data.field_button_url}> */}
                    <Typography variant='h4' fontWeight={500} mb={1} >{data.title}</Typography>
                    <Typography fontSize="18px" color="#4A4948" >
                        
                        <div dangerouslySetInnerHTML={{ __html: data.field_description}}/>
                        </Typography>
                    
                    {/* </Button> */}
                    </Link>
            </Grid>
        });
    }
    return <>
        {renderContent()}
    </>
}