import React, {FC, useState, useEffect} from 'react';
import Carousel from 'react-material-ui-carousel';
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, CardMedia, 
ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { Link as RouterLink} from 'react-router-dom';
import he from 'he'
import useAnalyticsEventTracker from '../../useAnalyticsEventTracker';

const CommonBlogs=(props: any) => {
    const { data,topicSearched,  typeSearched,textSearched, currentPage} = props;
//     let gaData:any;
//    const found = data.filter((obj:any, index: any) => {
//       gaData = obj.field_blog_address_url;
    
//   });
//     //console.log(gaData,"found")
    const gaEventTracker = useAnalyticsEventTracker("blogDetail/" + window.location.search);
    if (data !== null) {
    return data.map((commonData: any, index: any) => { 
       
    return (
        <React.Fragment>
                        <Grid item xs={12} md={6} lg={4}>
                            <Card className='blog-box' sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)' }}>
                                       <Box display='flex' alignItems='start' justifyContent='end'>
                                           <CardMedia
                                            component="img"
                                            height="288"
                                            image={commonData.field_blog_bannerimage}
                                            alt=""
                                        />
                                        </Box>
                                        <CardContent sx={{ p: '24px' }}>
                                                <Typography gutterBottom variant="h5" fontSize='28px' mb={3}>
                                                {he.decode(commonData.title)}
                                                </Typography>
                                                <RouterLink   to={`/BlogDetails${commonData.field_blog_address_url}`} state={{searchResultData:data,  currentIndex:index, textSearched:textSearched, topicSearched:topicSearched , typeSearched:typeSearched , currentPage:currentPage,
                                                isPinnedBlog:"false", showPagination:"false"}}>
                                                <Button sx={{ padding: '0 25px', fontSize: '18px', fontWeight: '500 !important' }}  style={{width: '170px',
                                                height: '46px'}} className='btn-blue' 
                                                // component={RouteLink}
                                                href='#' onClick={()=>gaEventTracker('blogDetail'+commonData.field_blog_address_url)}
                                               >
                                                    READ MORE
                                                </Button>
                                                </RouterLink>
                                        </CardContent>
                            </Card>
                           
                        </Grid>
        </React.Fragment>
    );
    })
}else{
    return(
        <React.Fragment>
            <></>
        </React.Fragment>
    )
}
    
};


export default CommonBlogs;