import React, { useState, useEffect } from 'react'
import logo from '../assets/images/logo.png';
//import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
//import Toolbar from '@mui/material/Toolbar';
//import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
//import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import { Grid, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import newsImg2 from '../assets/images/newsImg2.jpg'
import newsImg3 from '../assets/images/newsImg3.jpg'
import newsImg4 from '../assets/images/newsImg4.jpg'
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';


//import Button from '@mui/material/Button';
import e2eImage from '../assets/images/e2e-image.jpg';
import bspImage from '../assets/images/bsp-img.jpg';
import { homePageResponse} from '../Service';
import HeroSection from '../components/HeaderSections/HeroSection';
import Capabilities from './sections/HomePageSections/CapabilityCardSection';
import {
  field_row_2_data,  field_row_7_data, formatCapabilityData, formatNewsCardSectionData, formatStrategicData,
  formatHeroSectionData, row_Bio_diff_content, fieldBuildingStrategic, row_Bio_diff_header, newsResource1
} from '../common/utils/utils';
import FieldFinalRow from './sections/HomePageSections/FieldFinalRow';
import { HOME_PAGE_CONTENT_URL} from '../common/APi/apiurl';
import Header from '../components/HeaderSections/Header';
import Footer from './Footer';
import BioDifferenceSection from './sections/HomePageSections/Row4sectionHome';
import EndToEnd from './sections/HomePageSections/EndToEnd';
import BuildingStrategicSection from './sections/HomePageSections/BuildingStrategicSection'
import NewsSection from './sections/HomePageSections/NewsSection';
import CommonCreativeSolution from '../components/CommonCreativeSolution'
import ModalPopup from './ModalPopup';

const Home = () => {
  const [loading, setLoading] = useState<boolean>(true)
  //const [pageContent, setPageContent] = useState<null | any>(homedata);
  const [pageContent, setPageContent] = useState<null | any>(null);
  const [newsSectionContent, setNewsSection] = useState<null | any>(null);
  const [capabilitiesData, setCapabilitySectionData] = useState<null | any>(null);
  const [strategicData, setStrategicData] = useState<null | any>(null);
  const [row7Data, setRow7Data] = useState<null | any>(null);
  const [row2Data, setRow2Data] = useState<null | any>(null);
  const [BuildingStrategicData, setRow4Data] = useState<null | any>(null);
  const [row_bio_diff_data, setBiodiffData] = useState<null | any>(null);
  const [isOpen, setOpen] = useState(false);
  const [heroSectionData, setHeroData] = useState<null | any>(null);
  
  const [row_bio_diff_header, setBiodiffHeadData] = useState<null | any>(null);
  const [newsContent, setNewsContent] = useState<null | any>(null);
  const fetchResponse = async () => {
    const res = await homePageResponse(HOME_PAGE_CONTENT_URL);
    setPageContent(res)
     setLoading(false);
  };
  const formatData = () => {

    let newsData;
    let titlesection: any;
    let capabilityData;
    let strategicData;
    let fieldRow7Data;
    let fieldRow2Data;
    let heroData;
    let field_Bio_diff_data;
    let BuildingStrategicData;
    let bioDiffHeadData;
    let newsContent;
    if (pageContent) {
      newsData = formatNewsCardSectionData(pageContent);
      titlesection = newsData.filter((nd: any) => {
        return nd.title !== "News &amp; resources"
      })

      capabilityData = formatCapabilityData(pageContent);
      strategicData = formatStrategicData(pageContent);
      fieldRow7Data = field_row_7_data(pageContent);
      fieldRow2Data = field_row_2_data(pageContent);
      heroData = formatHeroSectionData(pageContent);
      field_Bio_diff_data = row_Bio_diff_content(pageContent)
      BuildingStrategicData = fieldBuildingStrategic(pageContent);
      bioDiffHeadData = row_Bio_diff_header(pageContent);
      newsContent = newsResource1(pageContent)
      setNewsSection(titlesection);
      setCapabilitySectionData(capabilityData);
      setStrategicData(strategicData)
      setRow7Data(fieldRow7Data)
      setRow2Data(fieldRow2Data);
      setHeroData(heroData);
      setBiodiffData(field_Bio_diff_data);
      setRow4Data(BuildingStrategicData)
      setBiodiffHeadData(bioDiffHeadData);
      setNewsContent(newsContent)

    }
    //console.log("page", pageContent)
    //console.log("capabilitiesData", capabilitiesData)
  }
  useEffect(() => {
    fetchResponse();
  }, []);
  useEffect(() => {
    formatData();
  }, [pageContent])

  return (
    <>
     {/* <React.Fragment>
          <Header/>  <Footer /></React.Fragment> */}
      {
      loading ? 
      <img className='loader'
        src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
        alt='Loading' style={{display:'none'}}
      /> 
      :
        <React.Fragment>
          {/* <Header/> */}
          <HeroSection data={heroSectionData} />
          <EndToEnd sectionId='e2e' contentData={row2Data} />
          <Capabilities data={capabilitiesData} />
          <BioDifferenceSection sectionId='bio-diff' data={row_bio_diff_data} sectionHead={row_bio_diff_header} /> 
          <BuildingStrategicSection sectionId='bsp' imgUrl={bspImage} contentData={BuildingStrategicData} />
          <NewsSection data={newsContent}/>
          <CommonCreativeSolution />
          {/* <Footer /> */}
        </React.Fragment>
        
      } 
      {/* <ModalPopup /> */}
    </>

  )
}
export default Home;
