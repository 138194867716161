import React, { useState, useEffect } from 'react'
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
//component section

//Service

//API 
import { PERFORMANCE_MATERIALS_RFQ_PAGE_CONTENT_URL } from '../common/APi/apiurl'
import { LeadGenPageResponse } from '../Service/LeadGenMaterial';
import { formatContactUs, formatLeadAddress, formatLeadGenHeader, formatLeadGenInfusion } from '../common/utils/utils';
import LeadGenHeadSection from './sections/PerformanceRFQSections/LeadGenHeadSection';
import LeadGenInfusion from './sections/PerformanceRFQSections/LeadInfusionSection';
import { useLocation } from 'react-router-dom';
import LeadGenFooterSection from './sections/PerformanceRFQSections/LeadGenFooterSection';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: '30px',
    },

    '& .MuiInputBase-input': {
        width: '100%',
        borderRadius: 0,
        position: 'relative',
        backgroundColor: '#ffffff',
        fontSize: 16,
        padding: '11px 14px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1);',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
    },
}));


const PerformanceMaterialsRFQ = () => {
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(true)
    const [pageContent, setPageContent] = useState<null | any>(null);
    const [LeadHeaderDetail, setLeadHeaderData] = useState<null | any>(null);
    const [LeadInfusionDetail, setLeadInfusionData] = useState<null | any>(null);
    const [LeadUsData, setLeadUsData] = useState<null | any>(null);
    const [LeadAddressDetail, setLeadAddressData] = useState<null | any>(null);

    const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(false)
    const [LeadGenData, setLeadGenData] = useState<null | any>(null);

    const handelSuccessBanner = (flag: boolean) => {
        setShowSuccessBanner(flag)
    }
    const fetchResponse = async () => {
        const response = await LeadGenPageResponse(PERFORMANCE_MATERIALS_RFQ_PAGE_CONTENT_URL);
        setPageContent(response)
        setLoading(false);
    };

    const formatData = () => {
        let newsData;
        let titlesection: any;
        let LeadHeaderData;
        let LeadInfusionData;
        let LeadUsData;
        let LeadAddressData;
        let LeadGenData;
        if (pageContent) {
            LeadHeaderData = formatLeadGenHeader(pageContent);
            LeadInfusionData = formatLeadGenInfusion(pageContent);
            LeadAddressData = formatLeadAddress(pageContent);
            LeadGenData = formatContactUs(pageContent);
            setLeadInfusionData(LeadInfusionData);
            setLeadHeaderData(LeadHeaderData);
            setLeadUsData(LeadUsData);
            setLeadAddressData(LeadAddressData);
            setLeadGenData(LeadGenData);
        }
    }
    useEffect(() => {
        fetchResponse();
    }, []);
    useEffect(() => {
        formatData();
    }, [pageContent])
    useEffect(()=>{
        window.scroll(0,0)
    },[location])
    return (
        <>
            {loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
                <React.Fragment>
                    <LeadGenHeadSection data={LeadHeaderDetail} />
                    <LeadGenInfusion data={LeadInfusionDetail} handelSuccessBannerCallback={handelSuccessBanner} />
                    <LeadGenFooterSection data={LeadGenData} showBanner={showSuccessBanner} />
                </React.Fragment >
            }
        </>
    );
}
export default PerformanceMaterialsRFQ;
