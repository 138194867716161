//Home contetn

import Api from "../common/APi/api";

export const homePageResponse=async(url:string)=>{
const response=await Api.get(url);
// export const enggComponentsPageResponse=async(url:string)=>{
//     const response=await Api.get(url);
//console.log("response",response);

return response.data;
}
export const HomeModalPopup=async(url:string)=>{
    const response=await Api.get(url);
    // export const enggComponentsPageResponse=async(url:string)=>{
    //     const response=await Api.get(url);
    //console.log("response",response);
    
    return response.data;
    }
