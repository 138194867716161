import React from 'react'
import { Container, Box, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import img1 from '../../assets/images/img1.jpg'
import arrow from '../../../assets/images/arrow.svg'
import Link from '@mui/material/Link';
import DesignImg from '../../../assets/images/DesignServiceImage.jpg';
import FDImg from '../../../assets/images/FDImage.jpg';
import MaterialImg from "../../../assets/images/MaterialImg.jpg";
import ECImage from "../../../assets/images/EcImage.png";
import MaterialComp from  "../../../assets/images/Home_Material&Comp.jpg";
//Get Images by const variable
const IMAGE_DATA=[DesignImg,MaterialComp,ECImage,FDImg];

const Capabilities=(props: any) => {
    const { data } = props;
    // txt.split('\r\n')    
console.log("data in Capabilities",props)
    return (
        <Box className='capabilities' pt={6} pb={{xs:'50px', md:'112px'}}>
        <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>
          <Box >
            <Typography variant="h4" fontWeight="500" color="#4A4948" className='section-heading'>
            {data[0].title}
            </Typography>
            <Typography fontSize="26px" mb={{ xs: '30px', lg: '64px' }}>
            {data[0].field_description}
            </Typography>
          </Box>
          <Grid container spacing={{ xs: 5, lg: 8 }}>
            {data[0].field_description2.split('\r\n').map((data: any, index: any) => {
              return (<Grid item xs={12} md={6} lg={3} key={index}>
                <CardContent sx={{p:'0'}}>
                    <img src={IMAGE_DATA[index]} alt="" width="100%" />
               </CardContent>
                   {/* <Link > <CardData data={data} key={index} /></Link> */}
                   <a href={data ==="ENGINEERED COMPONENTS" ? "/EngineeredComponents":
                   data === "DESIGN SERVICES" ? "/DesignServices":
                   data === "MATERIALS" ? "/Materials":
                   data === "FINISHED DEVICES" ? "/FinishedDevices":
                    "/Materials"}
                  >
                    <CardData  data={data} key={index}/>
                   </a>
                </Grid>)
              })
            }
            {/* //{index}==="3" ? href="/EngineeredComponents" :""}  */}
        </Grid></Container></Box>
    )
}

const CardData = (props: any) => {
    const { data,key } = props
  //  const { title, field_button_text } = data;
    ///console.log("checked", data);
    let title1;
    if(data.includes("&amp;")){
        title1=data.replaceAll('&amp;', '&');
     }
   
     return (
        <Card className="card">
             <Link className="btn-link">{(data.includes("&amp;"))?data
               .replaceAll('&amp;', '&'):data}<img src={arrow} alt="" />
             </Link>
        </Card>
        )
};

export default Capabilities;
