import React, { FC, useEffect, useState } from 'react';
// import Carousel from 'react-material-ui-carousel';
import {
    Container, Box, Typography, Grid, Card, CardContent, Button, List, CardMedia,
    ListItem, ListItemAvatar, ListItemText
} from '@mui/material';
import axios from 'axios';
import { useParams } from "react-router-dom";

import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
 import TextField from '@mui/material/TextField';
// import SearchIcon from '@mui/icons-material/Search';
// import Footer from '../../Footer';
import CommonCreativeSolution from '../../../components/CommonCreativeSolution';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';
import { useLocation } from 'react-router-dom';
import Api from '../../../common/APi/api'
import { truncateString } from '../../../common/utils/utils';
import useAnalyticsEventTracker from '../../useAnalyticsEventTracker';
import { postRfqFormData } from '../../../Service/Rfq';
import {env_api_url} from '../../../common/Constants';
import { Helmet } from 'react-helmet';
import TopSubMenuNewsAndResource from '../ServiceDetailsLevel2/TopSubMenuNewsAndResource';
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: '30px',
    },

    '& .MuiInputBase-input': {
        width: '100%',
        borderRadius: 0,
        position: 'relative',
        backgroundColor: 'transparent',
        border: '1px solid #FFFFFF',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
        fontSize: 16,
        padding: '11px 14px',
        color: '#fff',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
    },
}));

interface SearchResultDataOfBlogs {
    nid: string;
    title: string;
    field_blog_bannerimage: string;
    field_blog_banner_text: string;
    field_date: string;
    field_order: string;
    field_topic: string;
    field_type: string;
    field_reference: string;
    field_description: string;
    field_blog_address_url:
    string;
    counter: string;
    field_is_pinned: string;
}


interface userContactInformation{
    email:string;
}
const  initial_value:userContactInformation={
  email:'',
}
interface ErrorMessage{
  email:string
}
const BlogDetails = (props: any) => {
   
    // const params = useParams();
    const location = useLocation();
    const currentPage = location.pathname;
     //const{ isPinnedBlog}=location.state;
    // const [showPaggination, setShowPagination] = useState<boolean>(location?.state?.isPinnedBlog ? location?.state?.isPinnedBlog : true);
    const [data, setData] = React.useState<any>([]);
    const [searchResultsContent, setSearchResultContent] = useState<SearchResultDataOfBlogs[]>(location?.state?.searchResultData)
    const [urlParams, setUrlParams] = useState<string>(location.pathname.split("/")[2]);
    const [currentIndexBlogDetails, setCurrentIndexBlogDetails] = useState<number>(location.state?.currentIndex)
    const [totalSearchItem, setTotalSearchItem] = useState(location.state?.searchResultData.length)
    const [showPagination,setShowPagination]=useState<boolean>(location?.state?.showPagination==="true"?true:false)
    // const {isPinnedBlog} = location?.state; //old-by-sandeep
    const [ pathnames ,setPathnames]= useState<string[] |any>(useLocation().pathname.split("/").filter((x:any) => x));
    const [isPinnedBlog, setIsPinnedBlog]=useState<boolean |any>(location?.state?.isPinnedBlog==="true"?true:false)
    
    const [mailingData,setMailingData]=useState<userContactInformation>(initial_value);
    const [error,setError]=useState<ErrorMessage>({
    email:''});
    const [verified, setVerified] = useState<string>("");
    const [disabled, setDisabled]=useState<boolean>(true)

    //////////////

    const handleSubmit = async() => {
        const body={
         email:mailingData.email
        }
        const response=await postRfqFormData(JSON.stringify(body));
        if(JSON.parse(response).data.statusCode===200){
          setVerified("here are msg");
          setMailingData(initial_value)
        }else{
          alert("something went wrong!")
        }
    }
      
      const handleChange=  (e:React.ChangeEvent<HTMLInputElement> )=>{
      const {name, value}=e.target;
      
    setMailingData({...mailingData,[name]:value})
        if(name==='email'){
            if (!value) {
              setError({...error, [name]:"Please enter your email address."});
              setDisabled(true)
            }else{
              setError({...error, [name]:""});
              setDisabled(true)
           }
            if (typeof value !== "undefined") {
              var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
              if (!pattern.test(value)) {
               // isValid = false;
               // errors["email"] = "Please enter valid email address.";
                setError({...error, [name]:"Please enter your valid email address."})
                setDisabled(true)
              }else{
                  setError({...error, [name]:""})
                  setDisabled(false)
              }
            }
        }
      //setRfqFormData({...rfqFormData,[name]:value}) 
    }

    
    /////////////
    useEffect(()=>{
        // setPathnames(location.pathname.split("/").filter((x:any) => x))
    },[])
    useEffect(() => {
        window.scrollTo(0, 0);
         setPathnames(location.pathname.split("/").filter((x:any) => x));
         if(location.state===null){
           
            setShowPagination(true)
            setIsPinnedBlog(true)
         }else{
        setShowPagination(location?.state?.showPagination==="true"?true:false)
        setIsPinnedBlog(location?.state?.isPinnedBlog==="true"?true:false)
         }
    }, [location])
    // const pathnames = location.pathname.split("/").filter((x:any) => x);
    useEffect(() => {
        // console.log(location,"location in useE")
        window.scroll(0, 0)
        axios.get(`${env_api_url}bio/v1/biomerics/blog-details?_format=json&address_url=/${pathnames[1]}`)
            .then(res => {
                //console.log(res)
                setData(res.data)
            })
            .catch(err => {
                
            })
    }, [urlParams, pathnames]);
    
    function decodeEntity(inputStr:any) {
        var textarea = document.createElement("textarea");
        textarea.innerHTML = inputStr;
        return textarea.value;
    } 
    const gaEventTracker = useAnalyticsEventTracker(window.location.pathname + window.location.search);

    const blogDetails = () => {
        var detailData = data;
        return detailData.map((commonData: any) => {
            
            return (<>
             
             {commonData.field_seo_title != undefined ?
               <Helmet>
                        <meta charSet="utf-8" />
                         <title> {!commonData.field_seo_title[0] ? commonData.field_seo_title.split("&amp;")[0] : commonData.field_seo_title.split("amp;")[0]}</title>
                         <meta name="description" content={commonData.field_seo_description}></meta>
                         <meta name="keywords" content={commonData.field_seo_keywords}></meta>
                         <meta property='keywords' content={commonData.field_seo_keywords}></meta>
                         <meta property="og:title" content={!commonData.field_seo_title[0] ? commonData.field_seo_title.split("&amp; ")[0] : commonData.field_seo_title.split("amp; ")[0]} />
                         <meta property="og:description" content={commonData.field_seo_description}/>
                        <meta property="og:image" content={commonData.field_seo_image}/> 
                 </Helmet> 
                 :""}

                <Box className='news-and-resource blog-detail' display='flex' justifyContent='center' mb={{ xs: '30px', lg: '98px' }}>
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center">
                        {commonData.field_blog_bannerimage !== "" ?
                            <img src={commonData.field_blog_bannerimage} alt="" /> : ''}
                        <Box className='news-box' display="flex" flexDirection="column" justifyContent="space-between"
                            pl={{ xs: '20px', md: '24px' }} pr={{ xs: '20px', md: '56px' }} pt={{ xs: '20px', md: '56px' }} pb={{ xs: '20px', md: '40px' }} maxWidth='557px'>
                          
                                <Box>
                                    <Button href='#' onClick={()=>gaEventTracker('blogDetail')}></Button>                  
                                    <Typography variant="h1"  mb="20px" fontSize={{lg:'34px', xl:'45px'}}>
                                        {/* <div dangerouslySetInnerHTML={{ __html: commonData.title }} /> */}
                                        {/* {truncateString(commonData?.title, 80)} */}
                                        {/* {commonData.title } */}
                                       {decodeEntity(commonData.title)}
                                    </Typography>

                                </Box>
                          
                        </Box>
                    </Box>
                </Box>
                <Typography  >
                    <div dangerouslySetInnerHTML={{ __html: commonData.body }}/>
                </Typography></>
            )

        })
    };
    const handlePrevButton = () => {
        if (currentIndexBlogDetails === 0) {
            const { currentPage } = location?.state;
            return getPrevPageContent(currentPage)
        }

        setCurrentIndexBlogDetails(currentIndexBlogDetails - 1)
        const findNewParams = location.state?.searchResultData[currentIndexBlogDetails - 1];
        
        // setUrlParams(findNewParams.field_blog_address_url.replace("/", ""))
        const apiKeyPath:string[]=["",findNewParams.field_blog_address_url.replace("/", "")]
        setPathnames(apiKeyPath)

    }
    const getPrevPageContent = (currentPage: number) => {
        //console.log("get nex page prosp", currentPage)
           
        async function fetchPageData() {
            const pageUrl=`bio/v1/biomerics/blogs/?_format=json&is_pinned=false&type=${location.state.typeSearched}&topic=${location.state.topicSearched}&body=${location.state.textSearched}&page=${currentPage-2}`
            try {
                const { data } = await axios.get(`${env_api_url}bio/v1/biomerics/blogs/?_format=json&is_pinned=false&type=${location.state.typeSearched}&topic=${location.state.topicSearched}&body=${location.state.textSearched}&page=${currentPage-2}`);
                // const ff=await Api.get(pageUrl)
                // console.log(ff,ff)
                const pageData = data;
                const newData = [...data, ...searchResultsContent];
                setCurrentIndexBlogDetails((newData.length / 2) - 1)
                setSearchResultContent(newData);
                setTotalSearchItem(newData.length);
                // setUrlParams(newData[(newData.length / 2) - 1].field_blog_address_url.replace("/", ""))
                const newPath=newData[(newData.length / 2) - 1].field_blog_address_url.replace("/", "")
                const apiKeyPath:string[]=["",newPath]
                setPathnames(apiKeyPath)

            } catch (e) {
                console.log(e);
            }
        }
        fetchPageData();
    }


    const getNextPageContent = (textSearched: string, typeSearched: string, currentPage: number, topicSearched: string) => {
        //console.log("get nex page prosp", currentPage)
        async function fetchPageData() {
            

            try {
                const { data } = await axios.get(`${env_api_url}bio/v1/biomerics/blogs/?_format=json&is_pinned=false&type=${location.state.typeSearched}&topic=${location.state.topicSearched}&body=${location.state.textSearched}&page=${currentPage}`);
                const pageData = data;
                const newData = [...searchResultsContent, ...data];
               
                setSearchResultContent(newData);
                setCurrentIndexBlogDetails(currentIndexBlogDetails + 1)
                setTotalSearchItem(newData.length);
                // setUrlParams(newData[currentIndexBlogDetails + 1].field_blog_address_url.replace("/", ""))
                const newPath=newData[(newData.length / 2) + 1].field_blog_address_url.replace("/", "")
                const apiKeyPath:string[]=["",newPath]
                setPathnames(apiKeyPath)
                //setLoading(false);
            } catch (e) {
                console.log(e);
            }
        }
        fetchPageData();
    }
    const handleNextButton = () => {
       
        if ((currentIndexBlogDetails + 1) === totalSearchItem) {
            // console.log(props, "props in last page ")
            const {textSearched, typeSearched, currentPage, topicSearched } = location.state
            return getNextPageContent(textSearched, typeSearched, currentPage, topicSearched);
        }
        setCurrentIndexBlogDetails(currentIndexBlogDetails + 1);
        const findNewParams = searchResultsContent[currentIndexBlogDetails + 1];
        // setUrlParams(findNewParams.field_blog_address_url.replace("/", ""))
        const apiKeyPath:string[]=["",findNewParams.field_blog_address_url.replace("/", "")]
        setPathnames(apiKeyPath)
    }

    
    
    return (
        <React.Fragment>
            {/* <Header /> */}
            <TopSubMenuNewsAndResource currentPage={currentPage}/>
            {/* <TopSubMenuNewsAndResource/> */}
            <Box className='blog blog-detail '>
                <Container maxWidth={false} sx={{ maxWidth: '1332px' }}>
                     {blogDetails()}
                    <Box display='flex' justifyContent='center' mt={10} mb={7} className='pagination'>
                        {/* <Stack spacing={2}>
                            <Pagination  count={5}   color="primary"  onChange={(e, pageNumber)=>console.log("pagination clicked")}/>
                        </Stack> */}
                        {/* {!showPaggination &&
                            <React.Fragment>
                                <Button className='pagination_prev' onClick={handlePrevButton} >Prev</Button>
                                <Button className='pagination_next' onClick={handleNextButton}
                                >Next</Button>
                            </React.Fragment>
                        } */}
                {(!isPinnedBlog  || !showPagination )&&
                        <React.Fragment>
                            {/* <Button className='pagination_prev' onClick={handlePrevButton}  disabled ={!isNaN(currentIndexBlogDetails)?false:true}>Prev</Button>
                            <Button className='pagination_next' onClick={handleNextButton} disabled ={!isNaN(+currentIndexBlogDetails)?false:true}
                            >Next</Button> */}
                            
                            <Button className='pagination_prev' onClick={handlePrevButton}  disabled ={!isNaN(currentIndexBlogDetails)?false:true}>Prev</Button>
                            <Button className='pagination_next' onClick={handleNextButton} disabled ={!isNaN(+currentIndexBlogDetails)?false:true}
                            >Next</Button>
                        </React.Fragment>
                        }
                    </Box>
                </Container>
                <Box className='subscribe'>
                    <Container maxWidth={false} sx={{ maxWidth: '1332px', position: 'relative', zIndex: '9' }} >
                        <Typography fontSize={{ xs: '25px', md: '36px' }} mb={5} color='#fff' fontWeight='700' textAlign='center'>
                            Sign up for upcoming news
                        </Typography>
                        <Box display='flex' justifyContent='center' mb={6}>
                            <FormControl variant="standard" sx={{ mr: '24px', width: '477px', maxWidth: '100%' }}>
                            <BootstrapInput placeholder='Email*' name='email' onChange={handleChange} value={mailingData.email} />
                            {error.email.length>0?<Typography color='red' variant="body2" >{error.email}</Typography>:""}
                            </FormControl>
                            {/* className={disabled?'btn-grey':'btn-blue'} */}
                            <Button variant='contained'
                            className={disabled?'btn-grey':'btn-white'}
                            // className='btn-white btn-subscribe'
                             sx={{ minWidth: '100px' }} 
                             onClick={handleSubmit} disabled={disabled}>SUBMIT</Button>
                        </Box>
                        { verified !==""?
                        <Typography fontSize='15px' mb={5} className='mail-msg' color='#fff' 
                        > Your request has been submitted!</Typography>
                        :''
                        }
                        <Typography fontSize='20px' mb={5} color='#fff' textAlign='center'>
                            Stay on top of the latest happenings in Biomerics by signing up.
                        </Typography>
                    </Container>
                </Box>
                <CommonCreativeSolution />
                {/* <Footer /> */}
            </Box>
        </React.Fragment>

    );
}



export default BlogDetails;