import React, { useState, useEffect } from 'react'
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
//component section
import ContactHeading from './sections/ContactSection/ContactCatalystSection';
import ContactInfusion from './sections/ContactSection/ContactInfusionSection';
import ContactUs from './sections/ContactSection/ContactingUsSection';
//Service
import { contactUsPageResponse } from '../Service/ContactUs'

//utitl files
import { formatContactHeader, formatContactInfusion, formatContactUs, formatContactAddress } from '../common/utils/utils';

//API 
import { CONTACT_US_PAGE_CONTENT_URL } from '../common/APi/apiurl';
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: '30px',
    },

    '& .MuiInputBase-input': {
        width: '100%',
        borderRadius: 0,
        position: 'relative',
        backgroundColor: '#ffffff',
        fontSize: 16,
        padding: '11px 14px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1);',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
    },
}));


const Contact = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [pageContent, setPageContent] = useState<null | any>(null);
    const [ContactHeaderDetail, setContactHeaderData] = useState<null | any>(null);
    const [ContactInfusionDetail, setContactInfusionData] = useState<null | any>(null);
    const [ContactUsData, setContactUsData] = useState<null | any>(null);
    const [ContactAddressDetail, setContactAddressData] = useState<null | any>(null);

    const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(false)


    const handelSuccessBanner = (flag: boolean) => {
        setShowSuccessBanner(flag)
    }
    const fetchResponse = async () => {
        const response = await contactUsPageResponse(CONTACT_US_PAGE_CONTENT_URL);
        setPageContent(response)
        setLoading(false);
        //console.log(response,"api response")
    };

    const formatData = () => {
        let newsData;
        let titlesection: any;
        let ContactHeaderData;
        let ContactInfusionData;
        let ContactUsData;
        let ContactAddressData;
        if (pageContent) {
            ContactHeaderData = formatContactHeader(pageContent);
            ContactInfusionData = formatContactInfusion(pageContent);
            ContactUsData = formatContactUs(pageContent);
            ContactAddressData = formatContactAddress(pageContent);
            setContactInfusionData(ContactInfusionData);
            setContactHeaderData(ContactHeaderData);
            setContactUsData(ContactUsData);
            setContactAddressData(ContactAddressData);
        }
        //console.log("page", ContactInfusionData)
    }
    useEffect(() => {
        fetchResponse();
    }, []);
    useEffect(() => {
        formatData();

    }, [pageContent])
    return (
        <>
            {loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
                <React.Fragment>
                    <ContactHeading data={ContactHeaderDetail} />
                    <ContactInfusion data={ContactInfusionDetail} handelSuccessBannerCallback={handelSuccessBanner} />
                    <ContactUs data={ContactUsData} showBanner={showSuccessBanner} />
                </React.Fragment >
            }
        </>
    );
}
export default Contact;
