
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useLocation } from 'react-router-dom';
import { Grid, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import Link from '@mui/material/Link';
import Footer from './Footer';
import CommonCreativeSolution from '../components/CommonCreativeSolution';
import Header from '../components/HeaderSections/Header';

//Api url
import { OUR_BOARD_PAGE_CONTENT_URL } from '../common/APi/apiurl';
import { OurBoardHeroSection, LeadershipCardSection } from '../common/utils/utils';
import ExploreAboutBiomerics from '../components/sections/ServiceDetailsLevel2/ExploreAboutBiomerics'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TopSubMenuAboutUs from './sections/ServiceDetailsLevel2/TopSubMenuAboutUs';
import CardSection from './sections/OurBoard/CardSection';
import { OurBoardPageResponse } from '../Service/OurBoard';
import BannerSection from './sections/OurBoard/BannerSection';


const OurBoard = () => {
    let pageURL;
    const pathName = useLocation();
    const currentPage = pathName.pathname;
    const location = useLocation().pathname;
    const [loading, setLoading] = useState<boolean>(true)
    const [pageContent, setContent] = useState<null | any>(null);
    const [HeadingSectionData, setHeadingSection] = useState<null | any>(null);
    const [OurBoardCardData, setOurBoardCardData] = useState<null | any>(null);

    const urlContentMappings = [
        { url: "/OurBoard", contentUrl: OUR_BOARD_PAGE_CONTENT_URL }
    ];


    const lowercaseURL = urlContentMappings.map((e) => e.url.toLowerCase());
    console.log("url..", lowercaseURL)

    function compareUrl(currentPage: string, lowercaseURL: string[]): boolean[] {
        currentPage = currentPage.toLowerCase(); // Convert currentPage to lowercase

        const matchArray = lowercaseURL.map((pageName) => currentPage === pageName);

        return matchArray; // Return the array of booleans
    }

    const matchResults = compareUrl(currentPage, lowercaseURL);

    const isSame = matchResults.some((match: any) => match);


    // const fetchResponse = async () => {

    //     if (isSame) {
    //         const matchedIndex = matchResults.findIndex(match => match);
    //         const response = await LeadershipPageResponse(urlContentMappings[matchedIndex].contentUrl);
    //         setContent(response);
    //         setLoading(false);
    //     } else {

    //         console.error("No matching URL found for currentPage:");
    //     }
    // };

    const fetchResponse = async () => {
        //console.log("currentPage")
        if (currentPage === "/OurBoard") {
            const response = await OurBoardPageResponse(OUR_BOARD_PAGE_CONTENT_URL);
            console.log("response in ourBoard page********",response)
            setContent(response)
        console.log("response in level4:",response)
            setLoading(false);
        }
    };

    const formatData = () => {
        let newsData;
        let titlesection: any;
        let HeadingSection;
        let OurBoardCardData;

        if (pageContent) {
            HeadingSection = OurBoardHeroSection(pageContent);
            OurBoardCardData = LeadershipCardSection(pageContent);
            setHeadingSection(HeadingSection);
            setOurBoardCardData(OurBoardCardData);

        }

    }

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchResponse();
    }, [location])

    useEffect(() => {
        formatData();

    }, [pageContent])
    return (
        <>
            <TopSubMenuAboutUs currentPage={location} />
            {loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
                <React.Fragment>

                    <BannerSection data={HeadingSectionData} />
                    <CardSection />
                    <ExploreAboutBiomerics />
                    <CommonCreativeSolution />
                    {/* <Footer /> */}
                </React.Fragment >
            }

        </>
    );
}
export default OurBoard;