import React from 'react'
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, 
ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import aboutCompanyImg from '../../../assets/images/about-company-img.png';
const GrowthAndExpansion6 = (props:any) => {
    const { data } = props;
    var bulletsColor = { "--bullet-bg-color": data[0].field_bullets_fgcolor } as React.CSSProperties; 
return (
<Box pt={{ xs: '50px', md: '80px' }} className='living-our-values' bgcolor='#F2F3F4'>
<Container maxWidth={false} sx={{ maxWidth: '1340px' }}>
    <Typography variant="h1" fontWeight="500" color="#4A4948" mb="40px !important" textAlign='center' mx='auto' className='section-heading' maxWidth={{ xs: '100%', lg: '700px' }} style={bulletsColor}>
    {!data[0].title ? data[0].title.split("&amp;")[0] : data[0].title}  
    </Typography>
    <Typography component="p" mb={4} textAlign='center' mx='auto' fontSize='25px' maxWidth={{ xs: '100%', lg: '1080px' }} >
    {!data[0].field_description ? data[0].field_description.split("&amp;")[0] : data[0].field_description} 
    </Typography>
    <Box  mt={10} display='flex' justifyContent='center'>
        <img src={data[0].field_image_url_1} alt="" className='img-responsive'/>
    </Box>
</Container>
</Box>
)
}
export default GrowthAndExpansion6;