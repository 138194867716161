import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { TermsAndConditionsSection } from '../common/utils/utils';
import { TERMS_AND_CONDITIONS_URL } from '../common/APi/apiurl';
import { TermsAndConditionsPageResponse } from '../Service/TermsAndConditions';

const TermsAndConditions = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [pageContent, setPageContent] = useState<null | any>(null);
    const [termsAndConditionsSectionData, setTermsAndConditionsSectionData] = useState<null | any>(null);

    const fetchResponse = async () => {
        try {
            const res = await TermsAndConditionsPageResponse(TERMS_AND_CONDITIONS_URL);
            setPageContent(res);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching privacy policy data:', error);
            setLoading(false);
        }
    };

    const formatData = () => {
        if (pageContent) {
            const termsAndConditionsSectionData = TermsAndConditionsSection(pageContent);
            setTermsAndConditionsSectionData(termsAndConditionsSectionData);
        }
    };

    useEffect(() => {
        fetchResponse();
    }, []);

    useEffect(() => {
        if (pageContent) {
            formatData();
        }
    }, [pageContent]);

    return (
        <React.Fragment>
            {loading ? (
                <>Loading....</>
            ) : (
                <Box pt="77px" mb={14}>
                    <Container maxWidth={false} sx={{ maxWidth: '1000px' }}>
                        <Grid container>
                            <Grid item xs={12} lg={12} display="flex" justifyContent="center" flexDirection="column">
                                <Box textAlign="center">

                                    <Typography
                                        variant="h3"
                                        fontWeight="900"
                                        fontFamily="Ubuntu"
                                        textAlign="center"
                                 
                                    >
                                        Purchase Order Terms & Conditions
                                    </Typography>
                                    <Typography
                                        variant="h3"
                                        fontWeight="900"
                                        fontFamily="Ubuntu"
                                        textAlign="center"
                                      
                                    >
                                        Biomerics, LLC
                                    </Typography>


                                    <Typography
                                        variant="h5"
                                        fontWeight="700"
                                        textAlign="center"
                                        mt={1}
                                    >
                                        {termsAndConditionsSectionData[0].field_title1}
                                    </Typography>

                                    <Typography
                                        mt={4}
                                        dangerouslySetInnerHTML={{ __html: termsAndConditionsSectionData[0].body }}
                                    ></Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            )}
        </React.Fragment>
    );
};

export default TermsAndConditions;
