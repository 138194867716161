import React, { useEffect, useState, createRef } from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { fetchLeadershipCardData } from '../../../Service/Leadership';
import {formatRequiredDatafromApi} from '../../../common/utils/utils';
const CardDataSection = (props: any) => {
    const [selectedCID, setSelectedCID] = useState<string>('');
    const [cardData,setCardData]=useState([]);

    useEffect(()=>{
    const apiResponse=fetchLeadershipCardData();
    apiResponse.then((res:any)=>{
        // const HTML=res[0].body.replace(/<\/?[^>]+(>|$)/g, "");
        //.replace(/\n/g, " ");
        // var o = JSON.parse(parseData);

        const removeHTML=res[0].body.replace(/<\/?[^>]+(>|$)/g, "");
        const removeALLWhiteSpace=removeHTML.replace(/\s/g,' ');
        const parseData=JSON.parse(removeALLWhiteSpace)
        // console.log("data:::::::::::::::::PARSE JS", removeALLWhiteSpace)
       
        var o = parseData[0].card_data;
        console.log("data in card section leadership:",o);
        setCardData(o)
        
        }).catch((e)=>{
        console.log(e)
    })
    },[])
  
    const removeActiveItem = (event:any) => {
        event.preventDefault()
        event.stopPropagation();
        setSelectedCID('')
    }

    const showDesHandler = (id: string) => {
        setSelectedCID(id)
    }
   
    const Data = () => {
        return cardData.map((list: any, index: any) => {
            return (
                <Grid item xs={12} md={6} lg={4}>
                    <Box onClick={() => { showDesHandler(list.cid) }}
                        className={`base-state ${list.cid === selectedCID && 'selected'}`}>
                        <Box className='team-overlay' onClick={(event) => removeActiveItem(event)}></Box>
                        <Card>
                            <CardMedia component="img"
                                image={list.field_image_url_1 !== "" ? list.field_image_url_1 : list.field_static_image}
                                alt="team1"
                                className='img-responsive' />
                            <CardContent className='text-center' >
                                <Typography gutterBottom variant="h5">
                                    {list.title_name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {list.title_facility.split('amp;')}
                                </Typography>
                            </CardContent>
                            <Box className='team-details-popover'>
                                <IconButton aria-label="close" className='btn-close' onClick={(event) => removeActiveItem(event)}>
                                    <CloseIcon />
                                </IconButton>
                                <Typography>
                                    {list.field_description.split("amp;")}
                                </Typography>
                            </Box>
                        </Card>
                    </Box>
                </Grid>
            )
        })
    };
    Data();

    return (
        <Box py={15} className='team-section'>
            <Container maxWidth={false} sx={{ maxWidth: '1218px' }}>
                <Grid container spacing={3} >
                    {Data()}
                </Grid>
            </Container>
        </Box>

    )
}

export default CardDataSection;
