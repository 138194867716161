import React from 'react'
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, 
ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import aboutEnggCompany from '../../../assets/images/about-engineer-company.jpg';
const RightTextsection1 = (props: any) => {
    const { data } = props;
   // console.log(data[0].title, "data");
   function decodeEntity(inputStr:any) {
    var textarea = document.createElement("textarea");
    //console.log(textarea,"textarea")
    textarea.innerHTML = inputStr;
    return textarea.value;
}
const field_description =  decodeEntity(data[0].field_description);

const fieldDescription = [
    {
      fieldDescription:
      field_description
    }
  ];  
return (
<Box className='e2e-capablities proactively-engineered-solutions'>
            <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>
                <Grid container>
                    <Box className='white-box'></Box>
                    <Grid item xs={12} md={6}>
                        <img src={data[0].field_image_url_1} alt="" width="100%" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box px={4} className='content'>
                            <Typography variant="h1" fontWeight="500" color="#4A4948" mb={3} maxWidth={{ xs: '100%', lg: '480px' }} 
                             className='section-heading'>
                             {!data[0].title ? data[0].title.split("&amp;")[0] : data[0].title}  
                            </Typography>
                            {/* <Typography component="p" mb={4} maxWidth={{ xs: '100%', lg: '730px' }}>
                             {!data[0].field_description ? data[0].field_description.split("&amp;") : data[0].field_description.split("amp;")} 
                            </Typography> */}
                            {fieldDescription.map((v) => (
                                    <Typography component="p" mb="20px" fontSize='25px' maxWidth="672px">
                                        <div dangerouslySetInnerHTML={{ __html: v.fieldDescription }} /> 
                                    </Typography>
                                        ))} 
                            <Typography component="p" maxWidth={{ xs: '100%', lg: '730px' }}>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
</Box>
)
}
export default RightTextsection1;