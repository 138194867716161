import React, { FC, useState, useCallback, useEffect, useRef } from 'react'
import { Box, Container, Grid, MenuItem, Select, Typography } from '@mui/material'
import e2eImage from '../../../assets/images/img4.jpg';
import Link from '@mui/material/Link';
import iconCall from '../../../assets/images/icons/call.svg';
import iconPin from '../../../assets/images/icons/pin.svg';
import iconEmail from '../../../assets/images/icons/email.svg';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import ContactAddress from '../ContactSection/ContactAddress';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';

import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import MuiPhoneNumber from 'material-ui-phone-number';
import { postRfqFormData } from '../../../Service/Rfq';
import { GOOGLE_CAPTCHA_SITE_KEY, PROD_GOOGLE_CAPTCHA_SITE_KEY, DEV_GOOGLE_CAPTCHA_SITE_KEY } from '../../../common/Constants';
import { fetchMaterialsOfInterestDropdownValue, fetchPolymerProcessingDropdownValue, postUserFormDataForPerformanceRFQ } from '../../../Service/LeadGenMaterial';
// console.log(ContactAddress, "lll");
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: '30px',
    },

    '& .MuiInputBase-input': {
        width: '100%',
        borderRadius: 0,
        position: 'relative',
        backgroundColor: '#ffffff',
        fontSize: 16,
        padding: '11px 14px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1);',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
    },
}));

interface userContactInformation {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    materialEvaluation: string;
    polymerAdditionalInfo: string;
    companyName: string;
    materialsOfInterest: string;
    polymerProcessing: string;
    ReCAPTCHA: string;
    sme: string;
}


export interface ErrorMessage {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    companyName: string;
    materialsOfInterest: string;
    polymerProcessing: string;
    materialEvaluation: string;
    polymerAdditionalInfo: string;
    ReCAPTCHA: string;

}

export interface Phonenum {
    phone: string;
}
const phone_value: Phonenum = {
    phone: ''
};


const initial_value: userContactInformation = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    materialEvaluation: '',
    materialsOfInterest: "",
    polymerProcessing: "",
    polymerAdditionalInfo: "",
    ReCAPTCHA: '',
    sme: "No"
}

interface ApiResponseDropdown {
    body: string[],
    loading: boolean
}

const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;


const LeadGenInfusion = (props: any) => {
    const { data } = props;

    const [contactInputField, setContactFormField] = useState<userContactInformation>(initial_value);
    const [contactNumberField, setContactNumberField] = useState<Phonenum>(phone_value);
    const [loading, setLoading] = useState<boolean>(true);
    const [phone, setPhone] = useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [materialsOfInterestApiResponse, setMaterialsOfInterestApiResponse] = useState<ApiResponseDropdown>({
        loading: true,
        body: []
    });

    const [polymerProcessingApiResponse, setPolymerProcessingApiResponse] = useState<ApiResponseDropdown>({
        loading: true,
        body: []
    })
    //const [disable, setDisable]=useState<boolean>(true);
    //const [area]
    const [error, setError] = useState<ErrorMessage>({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        companyName: '',
        email: '',
        materialsOfInterest: '',
        polymerProcessing: '',
        materialEvaluation: '',
        polymerAdditionalInfo: '',
        ReCAPTCHA: ''
    });

    const clearInputField = () => {
        setContactFormField(initial_value);
    }

    useEffect(() => {

        window.scroll(0, 0)
        const response = fetchMaterialsOfInterestDropdownValue();
        response.then((res): void => {
            setLoading(false);
            const dummyString = res.body.replace(/(^"|"$)/g, '')
            setMaterialsOfInterestApiResponse({ loading: false, body: dummyString.split(",") })
        }).catch((err) => {
            console.log('e', err)
        })
    }, [])


    useEffect(() => {

        window.scroll(0, 0)
        const response = fetchPolymerProcessingDropdownValue();
        response.then((res): void => {
            setLoading(false);
            const dummyString = res.body.replace(/(^"|"$)/g, '')
            setPolymerProcessingApiResponse({ loading: false, body: dummyString.split(",") })
        }).catch((err) => {
            console.log('e', err)
        })
    }, [])

    const clearPhoneField = () => {

        setContactNumberField(phone_value);
    }

    const handelInputChange = (e: any) => {
        const { name, value, id } = e.target;

        const re = /^\S*$/;
        let errors: ErrorMessage = error;
        setContactFormField({ ...contactInputField, [name]: value, [id]: value })
        if (name === 'firstName') {

            if (!value) {
                setError({ ...error, [name]: "Please add valid name" })
            } else {
                setError({ ...error, [name]: "" })
            }
            if (value.length < 4) {
                setError({ ...error, [name]: "Please add valid name" })

            } else {
                setError({ ...error, [name]: "" })
            }
        } else if (name === 'lastName') {
            if (!value) {
                setError({ ...error, [name]: "Please add valid Last name" })
            } else {
                setError({ ...error, [name]: "" })
            }
            if (value.length < 4) {
                setError({ ...error, [name]: "Please add valid last name" })
                //errors["firstName"] = "Please enter valid first name.";
            }
            //else {
            //     setError({...error, [name]:""})
            //    }
        } else if (name === 'email') {
            if (!value) {
                //isValid = false;
                setError({ ...error, [name]: "Please enter your email Address." })
                //errors["email"] = ";
            } else {
                setError({ ...error, [name]: "Please fill email field" })
            }

            if (typeof value !== "undefined") {
                if (!value) {
                    // isValid = false;
                    // errors["email"] = "Please enter valid email address.";
                    setError({ ...error, [name]: "Please enter valid email address." })
                } else {
                    setError({ ...error, [name]: "" })
                }
            }
        } else if (name === 'phoneNumber') {
            if (!value) {
                // isValid = false;
                //errors["phoneNumber"] = "Please enter your phoneNumber";
                setError({ ...error, [name]: "Please enter valid phoneNumber" })
            } else {
                setError({ ...error, [name]: "" })
            }


            if (typeof value !== "undefined") {
                //   const re = /^\S*$/;
                var phoneNum = value.replace(/[^\d]/g, '');
                if (phoneNum.length < 6 || phoneNum.length < 10) {
                    //isValid = false;
                    //errors["phoneNumber"] = "Please enter valid phone number.";
                    setError({ ...error, [name]: "Please enter your valid phoneNumber" })
                } else {
                    setError({ ...error, [name]: "" })
                }
            }
        } else if (name === "materialsOfInterest") {
            if (!value) {
                // errors["materialsOfInterest"]="Please select any one";
                setError({ ...error, [name]: "Please select any one" })
            } else {
                setError({ ...error, [name]: "" })
            }
        } else if (verified) {
            if (!verified) {
                // errors["materialsOfInterest"]="Please select any one";
                setError({ ...error, [id]: "Please select captcha" })
            } else {
                setError({ ...error, [id]: "" })
            }
        }


    }


    const [verified, setVerified] = useState(false);

    const myRef = useRef(null);
    const scrollToRef = (ref: any) => window.scrollTo(0, 1200)

    const handelSubmit = (e: React.SyntheticEvent) => {
        // window.scrollTo(0,1200);
        scrollToRef(myRef);
        let screen_width = window.screen.width;
        if (screen_width < 768) {
            window.scrollTo(0, 2000);
        }

        e.preventDefault();
        const contactPayload = {
            "firstname": contactInputField.firstName,
            "lastname": contactInputField.lastName,
            "company": contactInputField.companyName,
            "email": contactInputField.email,
            "Materials_of_Interest_c": contactInputField.materialsOfInterest,
            "Polymer_Processing_c": contactInputField.polymerProcessing,
            "Material_Evaluation_c": contactInputField.materialEvaluation,
            "Polymer_AdditionalInfo_c": contactInputField.polymerAdditionalInfo,
            // "lead_source": "Website",
        }


        let clearPhone: '';
        const response = postUserFormDataForPerformanceRFQ(JSON.stringify(contactPayload));
        response.then((res) => {

            //alert("Submitted!!")
            props.handelSuccessBannerCallback(true);
            clearPhoneField();
            clearInputField();
            setPhone("");
        }).catch((err) => {
            alert('Something went wrong');
        })
    }
    return (
        <Box className="connecting-frm">
            <Grid container spacing={9}>
                <Grid item xs={12} lg={6}>
                    <Box pl={{ xs: '20px', lg: '100px', xl: '330px' }} pr={{ xs: '20px', md: '0' }} pt={7} pb={4}>
                        <Typography variant="h1" fontSize='36px' color='#4A4948' fontWeight='500'
                            className='content-heading'>{data[0].title.replace("&#039;", "'")}</Typography>
                        <Typography fontSize='25px' color='#131313'>
                            {data[0].field_description.replace("&#039;", "'")}
                        </Typography>
                        {/* <Box my={4}>
                            <RouterLink to="/Rfq" className='btn-blue btn-rfq'>
                                {data[0].field_button_text}
                            </RouterLink>
                        </Box> */}
                        <Typography fontSize='18px' color='#131313'>
                            {data[0].field_description2.replace("&#039;", "'")}
                        </Typography>
                        <Box className='contact-details' mt={9} mb={9}>
                            <Typography fontSize='18px' variant='h5' color='#29A7DF' mb={3}>
                                Biomerics Corporate Headquarters
                            </Typography>
                            <Typography fontSize='18px' color='#131313'><a href="tel:1 888.874.7787"><img src={iconCall} alt="" /></a>
                                <Link href="tel:1 888.874.7787" underline="none" color="#4A4948">+1 888-874-7787</Link></Typography>
                            <Typography fontSize='18px' color='#131313'><a href="tel:1 801.355.2705"><img src={iconCall} alt="" /></a>
                                <Link href="tel:1 801-355-2705" underline="none" color="#4A4948">+1 801-355-2705</Link></Typography>
                            <Typography fontSize='18px' color='#131313'>
                                <a href='mailto:info@biomerics.com' target="_blank" rel="noreferrer"><img src={iconEmail} alt="" /></a>
                                <Link href="mailto:info@biomerics.com" target="_blank" color="#4A4948">info@biomerics.com</Link></Typography>
                            <Typography fontSize='18px' color='#131313'>
                                <a href="https://g.page/biomerics?share" target="_blank" rel="noreferrer">
                                    <img src={iconPin} alt="" />
                                </a>
                                6030 W. Harold Gatty Dr., Salt Lake City, UT 84116, USA</Typography>
                            <Typography fontSize='18px' color='#131313'>
                                <a href="/Locations"><img src={iconPin} alt="" /></a>
                                <Link href="/Locations" color='#29A7DF'>View other locations</Link></Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Box pr={{ xs: '20px', lg: '60px', xl: '220px' }} pl={{ xs: '20px', lg: '65px' }} py={7} pb={5} className='contact-frm'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='First Name*' name='firstName' onChange={handelInputChange} value={contactInputField.firstName} />
                                    {error.firstName.length > 0 ? <FormHelperText>{error.firstName}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Last Name*' name='lastName' onChange={handelInputChange} value={contactInputField.lastName} />
                                    {error.lastName.length > 0 ? <FormHelperText>{error.lastName}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Company*' name='companyName' onChange={handelInputChange} value={contactInputField.companyName} />
                                    {error.companyName.length > 0 ? <FormHelperText>{error.companyName}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Email*' name='email' onChange={handelInputChange} value={contactInputField.email} />
                                    {error.email.length > 0 ? <FormHelperText>{error.email}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Box>
                                    <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                        Materials of interest*
                                    </InputLabel>
                                    <Select sx={{
                                        width: '100%', borderRadius: '0', backgroundColor: '#ffffff',
                                        fontSize: '16px', boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)', border: 'none'
                                    }}
                                        className='w-100 frm-select '

                                        value={contactInputField.materialsOfInterest}
                                        onChange={handelInputChange}
                                        name='materialsOfInterest'
                                        // label="General capabilities" 
                                        displayEmpty
                                        renderValue={contactInputField.materialsOfInterest !== "" ? undefined : () => <Box className='select-placeholder'>Quadraflex® ALE

                                        </Box>}
                                        input={<OutlinedInput id="bootstrap-input" />}
                                    >

                                        {materialsOfInterestApiResponse.body?.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}

                                            >
                                                {name}

                                            </MenuItem>
                                        ))}

                                    </Select>
                                    {/* <FormHelperText>Required</FormHelperText> */}
                                    {error.materialsOfInterest.length > 0 ? <FormHelperText>{error.materialsOfInterest}</FormHelperText> : ''}
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box>
                                    <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                        How will the polymer be processed?*
                                    </InputLabel>
                                    <Select sx={{
                                        width: '100%', borderRadius: '0', backgroundColor: '#ffffff',
                                        fontSize: '16px', boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)', border: 'none'
                                    }}
                                        className='w-100 frm-select '

                                        value={contactInputField.polymerProcessing}
                                        onChange={handelInputChange}
                                        name='polymerProcessing'
                                        // label="General capabilities" 
                                        displayEmpty
                                        renderValue={contactInputField.polymerProcessing !== "" ? undefined : () => <Box className='select-placeholder'> Injection Molding
                                        </Box>}
                                        input={<OutlinedInput id="bootstrap-input" />}
                                    >

                                        {polymerProcessingApiResponse.body?.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}

                                            >
                                                {name}

                                            </MenuItem>
                                        ))}

                                    </Select>
                                    {/* <FormHelperText>Required</FormHelperText> */}
                                    {error.polymerProcessing.length > 0 ? <FormHelperText>{error.polymerProcessing}</FormHelperText> : ''}
                                </Box>
                            </Grid>


                            <Grid item xs={12}>
                                <FormControl variant="standard" sx={{ mb: '6px', mt: '6px' }}>
                                    <InputLabel
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                            position: 'relative',
                                            bottom: 0
                                        }}
                                    >
                                        Have you considered any other materials for this application? If so, what advantages and disadvantages did you observe?
                                    </InputLabel>
                                    <BootstrapInput
                                        multiline={true}
                                        placeholder=''
                                        rows={5}
                                        name='materialEvaluation'
                                        onChange={handelInputChange}
                                        value={contactInputField.materialEvaluation}
                                        sx={{ position: 'relative', marginTop: '0px !important' }}
                                    />
                                </FormControl>
                            </Grid>


                            <Grid item xs={12}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                        Please share any additional information here.
                                    </InputLabel>
                                    <BootstrapInput multiline={true} placeholder='' rows={5} name='polymerAdditionalInfo' onChange={handelInputChange}
                                        value={contactInputField.polymerAdditionalInfo} />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mt={2}>
                                    <Button variant="contained" className='' sx={{ px: '24px' }} onClick={handelSubmit} disabled={
                                        (contactInputField.firstName && contactInputField.lastName &&
                                            // contactInputField.materialsOfInterest && 
                                            // contactInputField.polymerProcessing && 
                                            contactInputField.companyName &&
                                            contactInputField.email) ? false : true}>SUBMIT</Button>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}

export default LeadGenInfusion;
