import React from 'react'
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, 
    ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import img1 from '../../assets/images/img1.jpg'
import arrow from '../../assets/images/arrow.svg'
import Link from '@mui/material/Link';
//import DesignImg from '../../assets/images/DesignServiceImage.jpg'
import FDImg from '../../assets/images/FDImage.jpg';
import MaterialImg from "../../assets/images/MaterialImg.jpg";
import ECImage from "../../assets/images/EcImage.png";
//Get Images by const variable
import centerExcellence from '../../../assets/images/center-excellence.jpg';
const CommonBottomSection=(props?: any) => {
const { data } = props;
console.log("data in common bottom section",data)
function decodeEntity(inputStr:any) {
  var textarea = document.createElement("textarea");
  textarea.innerHTML = inputStr;
  return textarea.value;
} 

const field_description =  decodeEntity(data[0].field_description);
  
const fieldDescription = [
    {
      fieldDescription:
      field_description
    }
  ];
//console.log("SpeedInnovation::::", data);
    return (
      <Box className='speed-and-innovation' mt='60px'  id={"section"+(data[0].nid)}>
      <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <img src={data[0].field_image_url_1} alt="" width="100%" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className='content' p={5}>
              <Typography variant="h1" fontWeight="500" color="#4A4948" className='section-heading' sx={{maxWidth:'450px'}}>
                {/* Centers of Excellence<br />
                drive speed &amp; innovation */}
                {data[0].title.split("amp;")}
              </Typography>
              {fieldDescription?.map((v) => (
                                    <Typography component="p" mb="20px" fontSize='25px' maxWidth="672px">
                                        <div  dangerouslySetInnerHTML={{ __html: v.fieldDescription }} /> 
                                    </Typography>
                                        ))}
              {data[0]!==''?<Typography component="p" mb="20px" fontSize='25px' maxWidth="672px">
                  <div  dangerouslySetInnerHTML={{ __html: data[0].body }} /> 
              </Typography>  
               :''}
                                      
              {data[0].field_button_text!==""?

         
              <Button variant="contained" className='btn-blue' sx={{ px: '24px' }} href={data[0].field_button_url}>{data[0].field_button_text}
              </Button>
              :""
              }
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
    )}

export default CommonBottomSection;
