import React,{FC} from 'react'
import { Box,Container,Grid,Typography, Button } from '@mui/material'
import e2eImage from '../assets/images/e2e-image.jpg'
import Link from '@mui/material/Link';

interface IProps{
    sectionId:string,
    imgUrl:string,
    contentData:{
        title:string
        field_description:string
        field_title1:string
        field_button_url:string
    }
}
const BuildingStrategicSection:FC<IProps> = ({sectionId,imgUrl,contentData}) => {
    //console.log(contentData, "contentData")
    const className=()=>{
        if(sectionId==="e2e"){
            return "content"
        }else{
            return "content1"
        }
    }
    const {field_description, title,field_title1,field_button_url} = contentData;
    //style={{background: '#FFFFFF'}}
        return( 
        <Box className='e2e-capablities building-strategic' pt={{xs:'24px', md:'104px'}} top="0">
         <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>
        <Grid container>
        {/* <Box className='white-box'></Box> */}
        <Grid item xs={12} md={6}>
            <Box p={3} className='content'>
              <Typography variant="h4"  mb='25px' mt={1}>
              <Typography component='div' fontWeight="500" lineHeight='46px' >{title}</Typography>
                <Typography component='span' fontWeight="700" lineHeight='46px'>{field_title1}</Typography>
              </Typography>
              <Typography component="p" fontSize='19px !important' pr={{xs:'0',lg:'128px'}}>
              {field_description}
               </Typography> 
                <Box mt={3}>
                  <Button  variant="contained" className='btn-white' href={field_button_url}>GET TO KNOW US</Button>
                </Box>
            </Box>
            </Grid>
            <Grid item xs={12} md={6}>
            <img src={imgUrl} alt="" width="100%" />
          </Grid>
          <Grid item xs={12}>
            <Box className='blue-bar'></Box>
          </Grid>
        </Grid>
        </Container>
        </Box>
        )
 }

export default BuildingStrategicSection;

