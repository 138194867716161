import React,{FC} from 'react'
import { Box,Container,Grid,Typography } from '@mui/material'
import Link from '@mui/material/Link';

interface IProps{
    sectionId:string,
  
    contentData:{
        title:string
        field_description:string
        field_image_url_1:string
    }
}
const EndToEnd:FC<IProps> = ({sectionId, contentData}) => {
    const className=()=>{
        if(sectionId==="e2e"){
            return "content"
        }else{
            return "content1"
        }
    }
    const {field_description, title, field_image_url_1}=contentData;
    const renderContent=()=>{
        return <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>
        <Grid container>
         {sectionId==="e2e" && <Box className='white-box'></Box>}
          {sectionId==="e2e" && <Grid item xs={12} md={6}>
            <img src={field_image_url_1} alt="" width="100%"/>
          </Grid>}
          <Grid item xs={12} md={6}>
            <Box p={{xs:'20px', md:'32px'}} className='content'>
              <Typography variant="h4" fontSize="40px"  mb='25px' pt={{xs:'0',lg:'56px'}}>
                {/* Building Strategic{tilte.includes} */}
                <Typography variant='subtitle1'   fontWeight="700" lineHeight={1.2}>
                {(title.includes("&amp;"))? title.split("&amp;")[0]: title.split(",")[0]}
                </Typography>
                <Typography variant='subtitle1'   fontWeight="500" lineHeight={1.2}>
                {(title.includes("&amp;"))? title.split("&amp;")[1]: title.split(",")[1]}
                </Typography>
              </Typography>
              <Typography component="p" fontSize='19px' pr={{xs:'0',lg:'100px'}}>
                {field_description}
               </Typography>
            </Box>
          </Grid>
          {sectionId==="bsp" && <Grid item xs={12} md={6}>
            <img src={field_image_url_1} alt="" width="100%" />
          </Grid>}
          <Grid item xs={12}>
            <Box className='blue-bar'></Box>
          </Grid>
        </Grid>
        </Container>
    }
    //console.log("data", contentData)
    
    return (
        <>
        {sectionId === "e2e" ?
          <Box className='e2e-capablities'>
            {renderContent()}
          </Box>:<Box className='e2e-capablities' pt={13} top="0">
            {renderContent()}
            </Box>
            }
        </>
    )
}

export default EndToEnd;

