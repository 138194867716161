import React, {FC} from 'react';
import Carousel from 'react-material-ui-carousel';
import { Container, Box, Typography, Grid, Card, CardContent, Button,List,CardMedia, 
ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import AboutUsImg from '../../../assets/images/About-us-banner.png';
const RfqQuote=(props: any) => {
    const { data } = props;
   if(data[0]){
    return (
        <React.Fragment>
          <Container maxWidth={false} sx={{ maxWidth: '1195px' }}>
                        <Box py={8} textAlign="center" className='rfq-frm'>
                            <Typography variant="h1" fontSize='36px' color='#4A4948' fontWeight='500' className='content-heading'>{data[0].title}</Typography>
                            <Typography fontSize='25px' color='#131313'>
                                {data[0].field_description}
                            </Typography>
                        </Box>
                    </Container>
            </React.Fragment>
    );
}else{
    return (
    <React.Fragment>
     
        </React.Fragment>
);}

};


export default RfqQuote;
