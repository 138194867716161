import React, { useEffect } from 'react';
import './App.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { bioRoutes } from './common/routes';
import Header from './components/HeaderSections/Header';
import Footer from './components/Footer';
import useAnalyticsEventTracker from './components/useAnalyticsEventTracker';
import ReactGA from 'react-ga';
import { GOOGLE_TRACKING_ID } from './common/Constants';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
ReactGA.initialize(GOOGLE_TRACKING_ID);

const theme = createTheme({
  typography: {
    fontFamily: [
      '"Ubuntu"',
     // '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#FFB801',
    },
    secondary: {
      main: '#29A7DF',
    },
  },


})
const Home1 = () => (
  <div>
    <h3>Home1</h3>
  </div>
);
const AppWrapper=()=>{
  const gaEventTracker = useAnalyticsEventTracker(window.location.pathname);
    useEffect(() => {
    //console.log(window.location.pathname, "google")
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  return (
    <div className="App">
      <Router>
      <Header/>
      <div className="content-container" >
        <a onClick={()=>gaEventTracker('call')}> 
        <Routes>
        {bioRoutes.map((route, index)=>{
          return <Route  
                 path={route.path}
                 exact={route.exact}
                 element={<route.component/>}
                 name={route.name}
             />
        })}
        </Routes>
         </a>  
        </div>
     
        <Footer />
      </Router>
    </div>
  );
}

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppWrapper/>
    </ThemeProvider>
  )
};
export default App;





























// const theme = createTheme({
//   typography: {
//     fontFamily: 'Ubuntu, Arial',
//   },
//   });

// const theme=createTheme({
//   typography:{
//     fontFamily: [
//       '"Ubuntu"',
//       '"Segoe UI Symbol"',
//     ].join(','),
//   },
// })

// function App() {
//   return (
//     <ThemeProvider theme={theme}>
//     <div className="App">
//     <Home />
//     </div>
//     </ThemeProvider>
//   );
// }


// export default App;
