import React, { useState, useEffect } from 'react'
import { Box, Container, Typography, Button } from '@mui/material'
import creativeSol from '../assets/images/creative-solutions.jpg';
import { fieldCreativeSolutionData } from '../common/utils/utils';
import { commonCreativeSolPageResponse } from '../Service/CreativeSolution';
import { COMMON_CREATIVE_SOLUTION_CONTANT_URL } from '../common/APi/apiurl';
import CommonCreativeSol from './sections/CreativeSolution'
const CommonCreativeSolution = () => {

    const [loading, setLoading] = useState<boolean>(true)
    const [pageContent, setPageContent] = useState<null | any>(null);
    const [creativeSolutionRes, setCreativeSolutionRes] = useState<null | any>(null);
    const fetchResponse = async () => {
        const res = await commonCreativeSolPageResponse(COMMON_CREATIVE_SOLUTION_CONTANT_URL);
        setPageContent(res)
        setLoading(false);
        //console.log(res, "fffffffff");
    };
    const formatData = () => {
        let newsData;
        let titlesection: any;

        let fieldCreativeSolutionRes;
        let newsContent;
        if (pageContent) {
            fieldCreativeSolutionRes = fieldCreativeSolutionData(pageContent);
            setCreativeSolutionRes(fieldCreativeSolutionRes);
        }
    };
    useEffect(() => {
        fetchResponse();

    }, []);
    useEffect(() => {
        formatData();
        //console.log("row2",pageContent)
    }, [pageContent])


    return (
        <React.Fragment>
            {loading ? <>Loading.... </> :<CommonCreativeSol field_image_url_1={creativeSolutionRes.field_image_url_1} title={creativeSolutionRes.title} field_creative_solutions_for_tou={creativeSolutionRes.field_creative_solutions_for_tou} 
            field_button_text={creativeSolutionRes.field_button_text}
            />}
            </React.Fragment>)
}
 //field_button_text={creativeSolutionRes.field_button_text}
            
           
export default CommonCreativeSolution
