//Page related all the information is described here.
// If we want to add one page under one section we can add here AND  map to the code.
import Config from './Information/Config.json';

export function hasChildren(item:any) {
    const { items: children } = item;
  
    if (children === undefined) {
      return false;
    }
  
    if (children.constructor !== Array) {
      return false;
    }
  
    if (children.length === 0) {
      return false;
    }
  
    return true;
  }
  
const createRouteUrl=(title:any)=>{
    if(title){
     const routeUrl=title.replace(/\s+/g, '');
     return `/${routeUrl}`;
    }
    return 
}

export const menuDataFromConfig:{ id:string,title:string,to:string |any, items:string[] | any}[]=[];

const menu=Config.menu;


 menu.map((data:any)=>{
    const title1=data.title;
    const to=createRouteUrl(title1);
    const id=data._id;
    const items=data.items
    const menuSubItems:any= [];
    items.map((itemData:any)=>{
        // const items1 = itemData.items!=="" ? itemData.items:'NULL';
        const items1 = itemData.items;
        const itemTitle = itemData.title;
        const to = createRouteUrl(itemTitle);
        const subItems:any=[];
        items1?.map((content:any,index:any)=>{
          const title=content.title;
          //console.log("Sub content", items1);
          const to=createRouteUrl(title)
           //console.log("subItem track11:::", subItems);
          return subItems.push({id:id,title:title, to:to})
        })
    
       return menuSubItems.push({id:id,title:itemTitle,to:to, items:subItems});
    })
 return menuDataFromConfig.push({id:id,title:title1,to:to ,items:menuSubItems})
});



//crate a store for 

export const menuPathUrl:{}[]|any=[]
const testObjPath:any={


}


export const routeUrlObject=()=>{
    const pathData=menuRoutPath();
   
    const Keys=Object.keys(pathData);
  
  const routes=Keys.map((key:string | any,index)=>{
    const routeObj={
        name:key,
        path:pathData[key],
        exact:true,
        component:""
    }

      return routeObj;
  })
 
  return routes;

}
//PATH
export const menuRoutPath=()=>{
  const data= menuDataFromConfig
    .map((content,index)=>{
       const path=content.to;
       const title=content.title;
       const items=content.items
       testObjPath[title]=path;
       items.map((contentItem:any)=>{
           const title1=contentItem.title;
           const nestedItems=contentItem.items;
           const subPath=contentItem.to;
           testObjPath[title1]=subPath;
           nestedItems.map((itm:any)=>{
               const title=itm.title;
               const nestedSubPath=itm.to;
               testObjPath[title]=nestedSubPath;
           })
    })
      
    return testObjPath;
    });

return data[0];
}

//API DATA

export const API_URL_DATA=()=>{

     const menuApiUrl:string[]=[]

    const apiUrl1:{}[]|any=[]
    const obj:any={
    };
    
    const testObj:any={};
    

    menu.map((content)=>{
        const apiUrl=content.API_URL;
        const title=content.title;
        testObj[title]=apiUrl;
    const items=content.items;
    items.map((item)=>{
        const apiUrl_1=item.API_URL;
        const title1=item.title
       // console.log("Sub Items Title", title1);
        
        testObj[title1]=apiUrl_1;
       
        const nestedItem:any=item.items;
        nestedItem.map((itm:any)=>{
            const subApiUrl=itm.API_URL;
            const title2=itm.title
             testObj[title2]=subApiUrl
            
          return testObj
        })
    })
    
    
    
    });
    //console.log("testObj",testObj);
    return testObj
}




export const homePage=():{}=>{
const homeData:{}=Config.Home;
console.log("home",homeData)
return homeData;

};

const homeHero=()=>{

}
