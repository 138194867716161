
import React, { FC, useEffect, useState } from 'react';
import { Button, Box, Typography, Container } from '@mui/material';
import MedicalBalloonsBalloonCathetersImg from '../../../assets/images/medicalBalloonsBalloonCathetersImg.jpg';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

interface Heading {
  data: {
    field_button_text: string;
    field_description: string;
    field_image_url?: string;
    field_reference?: string;
    nid?: string;
    title?: string;
    field_image_url_1: string;
    field_title1: string;
    field_about_title: string;
    field_seo_title?: string;
    field_seo_description?: string;
    field_seo_image?: string;
    field_seo_keywords?: string;
  }[];
}

const BannerSection1: FC<Heading> = ({ data }) => {
  console.log("data in BannerSection1.......", data);
  
  return (
    <>
      {data?.map((item: any, i: number) => (
        <Item key={i} {...item} />
      ))}
    </>
  );
};

const Item = (props: any) => {
  const pathName = useLocation();
  const currentPage = pathName.pathname;

  const location = useLocation().pathname;
  
  const [bannerClass, setBannerClass] = useState('');
  const isBackground = true;
  
  const {
    title,
    field_description,
    field_button_text,
    fieldImage,
    nid,
    field_reference,
    field_image_url_1,
    field_title1,
    field_about_title,
    field_seo_title,
    field_seo_description,
    field_seo_image,
    field_seo_keywords,
  } = props;

  useEffect(() => {
    if (field_reference === "L4_HEADER_SECTION") {
      setBannerClass(field_reference);
    }
  }, [field_reference]);

  return (
    <React.Fragment>
      {field_reference === "careers_hero_section" ? (
        <Box className="hero-section about-us" display="flex" justifyContent="space-between" flexDirection="column" pt={{ xs: '25px', md:"40px", lg: '64px', xl: '91px' }}>
          <img src={field_image_url_1} alt="" />
          {field_seo_title !== undefined && (
            <Helmet>
              <meta charSet="utf-8" />
              <title>{field_seo_title.split("&amp;")[0]}</title>
              <meta name="description" content={field_seo_description} />
              <meta name="keywords" content={field_seo_keywords} />
              <meta property="og:title" content={field_seo_title.split("&amp;")[0]} />
              <meta property="og:description" content={field_seo_description} />
              <meta property="og:image" content={field_seo_image} />
            </Helmet>
          )}
          <Container maxWidth={false} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', maxWidth: '1736px' }}>
            <Box position="relative" zIndex="9" mt={{ xs: '0px', lg: '136px' }}>
              <Typography variant="h1" fontSize="88px" fontWeight="600">
                <Typography component="span" fontSize="inherit" fontWeight="600"> CAREERS </Typography>
              </Typography>
            </Box>
          </Container>
        </Box>
      ) : (
        <Box className={bannerClass === "L4_HEADER_SECTION" ? "hero-section level-four-banner" : "hero-section level-two-banner"} display="flex" justifyContent="space-between" flexDirection="column" py={8}>
          <img src={isBackground ? field_image_url_1 : MedicalBalloonsBalloonCathetersImg} alt="" width="100%" />
          {field_seo_title !== undefined && (
            <Helmet>
              <meta charSet="utf-8" />
              <title>{field_seo_title.split("&amp;")[0]}</title>
              <meta name="description" content={field_seo_description} />
              <meta name="keywords" content={field_seo_keywords} />
              <meta property="og:title" content={field_seo_title.split("&amp;")[0]} />
              <meta property="og:description" content={field_seo_description} />
              <meta property="og:image" content={field_seo_image} />
            </Helmet>
          )}
          <Container maxWidth={false} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', maxWidth: '1736px' }}>
            {field_about_title !== "" ? (
              <Box>
                <Typography variant="h1" fontSize="50px" mb={{ xs: '10px', md: '50px', lg: '104px' }}>About Us</Typography>
                <Typography variant="h1" fontSize="88px" fontWeight="700" sx={{ textTransform: 'uppercase' }}>{title.split("&amp;")[0]}</Typography>
              </Box>
            ) : (
              <Box position="relative" zIndex="9">
              {field_about_title===""?
               <Typography variant="h1" fontSize="73px" >{!title.split("&amp;")[0]?title.split("&amp;")[0]:title.split("amp;")[0]}</Typography>
              :''} 
               {field_title1===""? <Typography variant="h1" fontSize="73px" fontWeight="600" mb={3}>{title.split("&amp;")[1]} </Typography>:''}
               {field_title1!==""?
                <Typography variant="h1" fontSize="73px" fontWeight="600" mb={3}>{field_title1} </Typography>:""
               }
              <Box mt={3}>
                   {/* <Button variant="contained" className='btn-primary' sx={{ px: '24px' }}>
                   </Button> */}
               </Box>
           </Box>
              // <Box position="relative" zIndex="9">
              //   <Typography variant="h1" fontSize="73px">{title.split("&amp;")[0]}</Typography>
              //   {field_title1 === "" ? (
              //     <Typography variant="h1" fontSize="73px" fontWeight="600" mb={3}>{title.split("&amp;")[1]}</Typography>
              //   ) : (
              //     <Typography variant="h1" fontSize="73px" fontWeight="600" mb={3}>{field_title1}</Typography>
              //   )}
              //   <Box mt={3}>
              //     {/* Additional elements can be added here */}
              //   </Box>
              // </Box>
            )}
          </Container>
        </Box>
      )}
    </React.Fragment>
  );
};

export default BannerSection1;

