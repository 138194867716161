import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
    levelThreeHeaderDetail, levelThreeOverviewDetail, levelThreeImgContentDetail, levelThreeCenterDataDetail
} from '../common/utils/utils';
import { pageResponse } from '../Service/ServiceDetail';
import { fetchL3MetaData } from '../Service/Level3';
import BannerSection from './sections/ServiceDetailsLevel2/BannerSection';
import SectionRight1 from './sections/ServiceDetailsLevel2/SectionTextRight1';
import SectionCenterText10 from './sections/ServiceDetailsLevel2/SectionCenterText10';
import CommonRepetativeSections from './sections/ServiceDetailsLevel2/CommonRepetativeSections';
import CommonCreativeSolution from './CommonCreativeSolution'
import TopSubMenuAboutUs from './sections/ServiceDetailsLevel2/TopSubMenuAboutUs'
import { env_api_url } from '../common/Constants';
import axios from 'axios';
import ExploreAboutBiomerics from './sections/ServiceDetailsLevel2/ExploreAboutBiomerics';
import PageNotFound from './PageNotFound';


const Level3 = () => {
    let pageURL;
    const pathName = useLocation();
    let currentPage = pathName.pathname;
    const location = useLocation().pathname;
    const [status, setStatus] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(true)
    const [pageContent, setContent] = useState<null | any>(null);
    const [hash, setHash] = useState<string>(pathName.hash ? pathName.hash : "");
    const [levelThreeHeader, setLevelThreeHeader] = useState<null | any>(null);
    const [levelThreeOverview, setLevelThreeOverview] = useState<null | any>(null);
    const [levelThreeImgContentData, setLevelThreeImgContent] = useState<null | any>(null);
    const [levelThreeCenterDetail, setLevelThreeCenterDetail] = useState<null | any>(null);
    const [newApi, newApiData] = React.useState<any>([]);
    const [apliUrl, setApliUrl] = useState<any>([]);

    useEffect(() => {
        const apiResponse = fetchL3MetaData();
        apiResponse.then((res: any) => {

            const HTML = res[0].body.replace(/<\/?[^>]+(>|$)/g, "").replace(/\n/g, " ");
            var api_data = JSON.parse(HTML);

            const pageName = api_data[0].NewApis.map((item: any) => item.page_name);
            const pageNames = api_data[0].NewApis.map((item: any) => item.page_name.toLowerCase());
            function areEqualIgnoreCase(currentPage: string, pageNames: string[]): boolean[] {
                currentPage = currentPage.toLowerCase();
                const matchArray = pageNames.map((pageName) => currentPage === '/' + pageName);

                return matchArray; // Return the array of booleans
            }
            const matchResults = areEqualIgnoreCase(currentPage, pageNames);

            const isSame = matchResults.some((match: any) => match);

            if (isSame) {

                const matchingPageName = pageName.find(
                    (pageName: any, index: any) => matchResults[index]
                );
                if (matchingPageName) {
                    currentPage = "/" + matchingPageName; // Update currentPage directly
                }

                newApiData(api_data[0].NewApis)

            }
            else {
                console.log("no match found!")
            }

            var apiData = mappApis(currentPage, api_data[0].NewApis);

            let resApi = apiData[currentPage];
            axios.get(`${env_api_url}${resApi}`)
                .then(res => {

                    setContent(res.data)
                    setLoading(false);
                    setStatus(false)
                })
                .catch(err => {
                    console.log(err)
                })

        }).catch((e) => {
            console.log(e)
        })

    }, [currentPage])


    function mappApis(currentPage: any, newApi: []) {
        let pageName: any;
        let apis_links: any;
        let urlMap: any = {};
        newApi.map(async (list: any, index: any) => {

            pageName = '/' + list.page_name;
            if (pageName.indexOf(currentPage) > -1) {
                apis_links = list.content_base_url;
           
                urlMap[pageName] = apis_links;

            }
        })

        return urlMap;
    }

    const formatData = () => {
        let levelThreeHeaderData;
        let levelThreeImgContentData;
        let levelThreeOverviewData;
        let levelThreeCenterData;
        if (pageContent) {
            levelThreeHeaderData = levelThreeHeaderDetail(pageContent);
            levelThreeOverviewData = levelThreeOverviewDetail(pageContent);
            levelThreeImgContentData = levelThreeImgContentDetail(pageContent);
            levelThreeCenterData = levelThreeCenterDataDetail(pageContent);
            setLevelThreeHeader(levelThreeHeaderData);
            setLevelThreeOverview(levelThreeOverviewData);
            setLevelThreeImgContent(levelThreeImgContentData);
            setLevelThreeCenterDetail(levelThreeCenterData);
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    useEffect(() => {
        formatData();
    }, [pageContent])

    useEffect(() => {
        setTimeout(() => {
            window.addEventListener("scroll", () => {
                document.querySelector('.headerPadding')?.classList.remove('headerPadding');
            })
        }, 3000)
        return () => {
            window.removeEventListener("scroll", () => {
            })
        }
    }, [])

    return (
        <>
            {
                loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
                    <React.Fragment>
                        {currentPage === "/Locations" ? <TopSubMenuAboutUs currentPage={"/Locations"} />
                            : currentPage === "/WhoWeAre" ? <TopSubMenuAboutUs currentPage={"/WhoWeAre"} /> :
                                currentPage === "/Quality" ? <TopSubMenuAboutUs currentPage={"/Quality"} /> :
                                    // currentPage === "/Careers" ? <TopSubMenuAboutUs currentPage={"/Careers"} /> :
                                        ''
                        }
                        <BannerSection data={levelThreeHeader} />
                        <SectionRight1 data={levelThreeOverview} />
                        <CommonRepetativeSections data={levelThreeImgContentData} />

                        {levelThreeCenterDetail == "" ? <ExploreAboutBiomerics /> :
                            <SectionCenterText10 data={levelThreeCenterDetail} />
                        }
                        <CommonCreativeSolution />

                    </React.Fragment>
            }
        </>
    )
}

export default Level3


