//balloon catherters
import axios from "axios";
import Api from "../../common/APi/api";
import {env_api_url} from '../../common/Constants'
export const BlogPageResponse = async(url:string, )=>{
const response = await Api.get(url);

return response.data;
}
export const fetchBlogDropdownValue=async()=>{
    const response =await axios.get(`${env_api_url}bio/v1/biomerics/type_topic`);
    
     return response.data
}