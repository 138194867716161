import React from 'react'
import { Container, Box, Typography, Grid, Card, CardContent, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import img1 from '../../assets/images/img1.jpg'
import arrow from '../../assets/images/arrow.svg'
import Link from '@mui/material/Link';
//import DesignImg from '../../assets/images/DesignServiceImage.jpg'
import FDImg from '../../assets/images/FDImage.jpg';
import MaterialImg from "../../assets/images/MaterialImg.jpg";
import ECImage from "../../assets/images/EcImage.png";
//Get Images by const variable
import fastbaloonImg from '../assets/images/fast-baloon.png';
import { Link as RouterLink } from 'react-router-dom';
const SectionCenterText10 = (props: any) => {
    const { data } = props;
    //console.log("VerticalIntegration::::",data[0].field_question_button_3_url.split('amp;'));
    function decodeEntity(inputStr:any) {
        var textarea = document.createElement("textarea");
        textarea.innerHTML = inputStr;
        return textarea.value;
      }
      const GetIntouch = decodeEntity(data[0].field_button_url);
      const button1=decodeEntity(data[0].field_question_button_1_url);
      const button2=decodeEntity(data[0].field_question_button_2_url);
      const button3=decodeEntity(data[0].field_question_button_3_url);
    return (
        <>
       
        <Box className='question-about-baloons' pt={14} pb={35}>
           <Container maxWidth={false} sx={{ maxWidth: '1736px' }} >
                <Box position="relative" zIndex="9">
                {/* {data[0].title!==""? */}
                    <Typography variant="h1" fontWeight="500" color="#ffffff" textAlign='center' maxWidth="768px" mx='auto' className='section-heading'>
                    {data[0].title!=="" ? data[0].title.split("amp;") :""}
                    </Typography>
                    {/* :""}     */}
                
                {
                    data[0].field_button_text!==""?
                    <Box mt={5} mb={10} textAlign='center'>
                        <Button component={RouterLink} variant="contained" className='btn-white' sx={{ px: '24px', minWidth:'202px'}} 
                        // to={GetIntouch!==''?GetIntouch:data[0].field_question_button_1_url.split("&amp;","&")}
                        to={GetIntouch!==''?GetIntouch:decodeEntity(data[0].field_question_button_1_url)}
                        
                    > 
                    {/* {data[0].field_button_text.split("&#039;", "'")}</Button> */}
                    {decodeEntity(data[0].field_button_text)}</Button>
                    </Box>:""
                }
                
                <Box position='relative' zIndex={9} display='flex' justifyContent='center' alignItems='center' 
                flexDirection={{ xs: 'column', lg: 'row'}}>
                 </Box>
              
                
                   {data[0].field_question_button_1_url!==""?
                    <Box position='relative' zIndex={9} display='flex' justifyContent='center' alignItems='center' flexDirection={{ xs: 'column', lg: 'row' }}>
                        <RouterLink to={button1} className="link" >
                        <Box textAlign='center' display='flex' justifyContent='center' alignItems='center' m={2} p={4} sx={{ width: '320px', height: '320px', backgroundColor: '#ffffff', boxSizing: 'border-box' }}>
                        <Typography fontSize='21px' color="#000000" className="box-link"  style={{textTransform: 'uppercase'}}>{data[0].field_question_button_1_text.split('amp;')}
                        </Typography >
                    </Box></RouterLink>
                    <RouterLink to={button2} className="link" >
                        <Box textAlign='center' display='flex' justifyContent='center' alignItems='center' m={2} p={4} sx={{ width: '320px', height: '320px', backgroundColor: '#ffffff', boxSizing: 'border-box' }}>
                            <Typography fontSize='21px' color="#000000" className="box-link" style={{textTransform: 'uppercase'}}>{data[0].field_question_button_2_text.split('amp;')}
                            </Typography>
                        </Box></RouterLink>
                    <RouterLink to={button3} className="link" >
                        <Box textAlign='center' display='flex' justifyContent='center' alignItems='center' m={2} p={4} sx={{ width: '320px', height: '320px', backgroundColor: '#ffffff', boxSizing: 'border-box' }}>
                            <Typography fontSize='21px' color="#000000" className="box-link" style={{textTransform: 'uppercase'}}>{data[0].field_question_button_3_text.split('amp;')}</Typography>
                        </Box>
                    </RouterLink>
                    </Box>:""}
                </Box>
            </Container>
            
        </Box>
       
        </> 
    )
     
}
export default SectionCenterText10;

{/* <Box position='relative' zIndex={9} display='flex' justifyContent='center' alignItems='center' flexDirection={{ xs: 'column', lg: 'row' }}>
<RouterLink to="/ComplexExtrusions" className="link" >   <Box textAlign='center' display='flex' justifyContent='center' alignItems='center' m={2} p={4} sx={{ width: '320px', height: '320px', backgroundColor: '#ffffff', boxSizing: 'border-box' }}>
<Typography fontSize='21px' color="#000000" className="box-link">COMPLEX EXTRUSIONS
</Typography>
</Box></RouterLink>
<RouterLink to="/AdvancedCatheters&Steerables" className="link" >
<Box textAlign='center' display='flex' justifyContent='center' alignItems='center' m={2} p={4} sx={{ width: '320px', height: '320px', backgroundColor: '#ffffff', boxSizing: 'border-box' }}>
    <Typography fontSize='21px' color="#000000" className="box-link">ADVANCED CATHETERS &amp; STEERABLES
    </Typography>
</Box></RouterLink>
<RouterLink to="/DesignServices" className="link" >
<Box textAlign='center' display='flex' justifyContent='center' alignItems='center' m={2} p={4} sx={{ width: '320px', height: '320px', backgroundColor: '#ffffff', boxSizing: 'border-box' }}>
    <Typography fontSize='21px' color="#000000" className="box-link">DESIGN SERVICES</Typography>
</Box></RouterLink>
</Box> */}
// const routeData = () => {
//     var DataList =
//     data[0].field_description2.split("\r\n");
//    // console.log(verticalDataList, "listData");
//     return  DataList.map((vd:any)=>{
//     return (
      
//         <RouterLink to="/DesignServices" className="link" >
//         <Box textAlign='center' display='flex' justifyContent='center' alignItems='center' m={2} p={4} sx={{ width: '320px', height: '320px', backgroundColor: '#ffffff', boxSizing: 'border-box' }}>
//             <Typography fontSize='21px' color="#000000" className="box-link">{vd}</Typography>
//         </Box></RouterLink>
//)
//   })
//   };