import React, { useState, useEffect } from 'react'
import Drawer from '@mui/material/Drawer';
import makeStyles from '@mui/styles/makeStyles';
import SegmentIcon from '@mui/icons-material/Segment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { Menu, MenuItem, List, ListItem, ListItemText, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import he from 'he';
import useAnalyticsEventTracker from '../components/useAnalyticsEventTracker';
import ListItemButton from '@mui/material/ListItemButton';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import call from '../assets/images/icons/call.svg';
import Collapse from '@mui/material/Collapse';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CloseIcon from '@mui/icons-material/Close';
import envelope from '../assets/images/icons/envelope.svg';
import { fetchFooterMenuFromServer } from '../Service/Header Menu';
import { formatRequiredDatafromApi } from '../common/utils/utils';
import { useNavigate, useParams, Router, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    color: "#000",
    fontSize: "21px",

  },
  icon: {
    color: "#000"
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    width: '100%',
    position: 'relative',
    border: '1px solid #DEDEDF',
    fontSize: 20,
    height: '35px',
    padding: '2px 12px',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
  },
}));

const DrawerComponent = (props: any) => {

  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [globalData, setGlobalData] = React.useState("");
  const [routeData, setRouteData] = React.useState("");

  const [menuLinks, setMenuLinks] = useState<null | []>(null)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [nestedDataL3, setNestedDataL3] = useState<any>(null);
  const [nestedDataL4, setNestedDataL4] = useState<any>(null);
  const [indexL2, setindexL2] = useState<any>('');
  const [indexL3, setindexL3] = useState<any>('');
  const [openL3, setOpenL3] = React.useState(false);
  const [openL4, setOpenL4] = React.useState(false);


  const gaEventTracker = useAnalyticsEventTracker(window.location.pathname);



  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const handleClickL2 = (event: any, items: any, i: any) => {

    setOpenL3(!openL3);
    setNestedDataL3(items);
    setindexL2(i);

  };
  const handleClickL3 = (event: any, items: any, index: any) => {
    setOpenL4(!openL4);
    setNestedDataL4(items);
    setindexL3(index);

  };

  const handleChange = (event: any) => {
    setGlobalData(event.target.value);
  }
  const cleanGlobalseacrhValue = (callbackValue: string) => {
    setGlobalData(callbackValue)
  }

  let navigate = useNavigate();
  const handleSearch = (event: any) => {
    event.preventDefault();
    if (globalData.trim().length !== 0) {
      if (globalData) {
        navigate("/SearchResults/" + globalData, {
          state: {
            globalSearchDataChangeHandler: cleanGlobalseacrhValue
          }
        });
        setGlobalData("");
      } else {
        navigate("/SearchResults");
      }
    } else {
    }

  };

  function handleKeyDown(event: any) {

    if (event.target.value.trim().length !== 0) {
      if (event.keyCode === 13) {
        const value = event.target.value;

        if (value) {
          navigate("/SearchResults/" + value, {
            state: {
              globalSearchDataChangeHandler: cleanGlobalseacrhValue
            }
          });
          //history.push("/SearchResults/" + value)  
          setGlobalData("");
        } else {
          navigate("/SearchResults");
        }
      }
    } else {
    }
  }


  useEffect(() => {
    const fFd = fetchFooterMenuFromServer();
    fFd.then((res: any) => {
      const requiredData = formatRequiredDatafromApi(res[0].body);
  
      setMenuLinks(requiredData)
    }).catch((e) => {
      console.log(e);

    })

  }, [])


  return (
    <div>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)} className="mobile-menu"
      >
        <List>

          <ListItem>
            <ListItemText className='mobile-link'>

              {menuLinks && menuLinks.length > 0 ?

                menuLinks.map((menu: any, index: any) => {

                  return (
                    menu.title && menu.title.length && menu.title.length > 0 ?

                      <>

                        <ListItem >

                          <Link to={he.decode(menu.to?.replace(/\s+/g, ''))} className="link" href="#" onClick={() => gaEventTracker('check level-1')} >

                            <ListItemText onClick={() => setOpenDrawer(false)}>
                              {menu.title && menu.title.length && menu.title.length > 0 ? he.decode(menu.title) : null}
                            </ListItemText>
                          </Link>

                          <ListItemButton
                            onClick={(e) => handleClickL2(e, menu.sub_title, index)}
                          >

                            {indexL2 === index && openL3 ? <CloseIcon sx={{ color: "white", fontSize: "20px" }} /> : <ArrowForwardIosSharpIcon style={{ fontSize: "15px", color: "white" }} onClick={() => setOpenDrawer(true)} />
                            }

                          </ListItemButton>


                        </ListItem>

                        <Collapse key={indexL2} in={openL3} timeout="auto" unmountOnExit>
                          {indexL2 === index ?
                            <List component="div" disablePadding className="bg-submenu" key={indexL2} >

                              <li>
                                {menu.sub_title && menu.sub_title.length && menu.sub_title.length > 0 ?
                                  menu.sub_title.map((sub: any, index: any) =>
                                    <>
                                      <ListItem>

                                        <Link to={menu.sub_title && menu.sub_title.length && menu.sub_title.length > 0 ? he.decode(sub?.to?.replace(/\s+/g, '')) : ''} className="link" href="#" onClick={() => gaEventTracker('check level-1')} >

                                          <ListItemText className='mobile-submenu' onClick={() => setOpenDrawer(false)}>{menu.sub_title && menu.sub_title.length && menu.sub_title.length > 0 ? he.decode(sub.title) : null}</ListItemText>
                                        </Link>

                                        {
                                          sub.sub_title && sub.sub_title.length && sub.sub_title.length > 0 ?

                                            <ListItemButton
                                              onClick={(e) => handleClickL3(e, sub.sub_title, index)}
                                            >


                                              {indexL3 === index && openL4 ? <CloseIcon sx={{ color: "white", fontSize: "20px" }} /> : <ArrowForwardIosSharpIcon style={{ fontSize: "15px", color: "white" }} onClick={() => setOpenDrawer(true)} />

                                              }

                                            </ListItemButton>

                                            : null}
                                      </ListItem>



                                      <Collapse key={indexL3} in={openL4} timeout="auto" unmountOnExit>

                                        {indexL3 === index ?

                                          <List component="div" disablePadding className="mobile-L4submenu" key={indexL3}>

                                            <li>

                                              {

                                                sub.sub_title && sub.sub_title.length && sub.sub_title.length > 0 ?

                                                  sub.sub_title.map((ele: any, i: any) => {


                                                    return (
                                                      <>

                                                        <ListItem>

                                                          <Link to={sub.sub_title && sub.sub_title.length && sub.sub_title.length > 0 ? he.decode(ele?.to?.replace(/\s+/g, '')) : ''} className="link" href="#" onClick={() => gaEventTracker('check level-1')} >

                                                            <ListItemText className='mobile-submenu' onClick={() => setOpenDrawer(false)}>{sub.sub_title && sub.sub_title.length && sub.sub_title.length > 0 ? he.decode(ele.title) : null}</ListItemText>
                                                          </Link>

                                                        </ListItem>
                                                      </>
                                                    )
                                                  })
                                                  : null
                                              }
                                            </li>
                                          </List>
                                          : null}
                                      </Collapse>
                                    </>
                                  ) : null}
                              </li>

                            </List>
                            : null}
                        </Collapse>
                      </> : null
                  )
                })
                : null}

<ListItem onClick={() => setOpenDrawer(false)}>
                <Link to="/Careers" className={classes.link}>
                  <ListItemText >Careers</ListItemText>
                </Link>
              </ListItem>


              <ListItem onClick={() => setOpenDrawer(false)}>
                <Link to="/About#section262" className={classes.link}>
                  <ListItemText >Our Values</ListItemText>
                </Link>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <Link to="/contact" className={classes.link}>
                  <ListItemText>
                    Contact
                  </ListItemText>
                </Link>
              </ListItem>
            </ListItemText>

          </ListItem>

        </List>
        <FormControl variant="standard" sx={{ position: 'relative', mt: '30px', mx: 'auto', px: '16px', boxSizing: 'border-box', width: '100%' }}>
          <BootstrapInput id="bootstrap-input" placeholder="Search..." onKeyDown={handleKeyDown} onChange={handleChange} value={globalData} />
          <Box component="span" position="absolute" top="3px" right="12px" className="search-icon"
            onClick={handleSearch}>
            <SearchIcon />
          </Box>
        </FormControl>

        <Box sx={{ mt: '30px', mx: 'auto', px: '16px', boxSizing: 'border-box', width: '100%' }}>
          <Box component='span' mr={4}><a href="tel:1 888.774.7787"><img src={call} alt="" width='24px' /></a>

          </Box>
          <Box component='span'><a href='mailto:info@biomerics.com' target="_blank" rel="noreferrer"><img src={envelope} alt="" /></a></Box>
        </Box>

      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)} className={classes.icon}>
        {openDrawer ? <ArrowBackIcon style={{ color: "#29A6DE", fontSize: "25px" }} /> : <SegmentIcon />}
      </IconButton>
    </div>
  )
}

export default DrawerComponent;
