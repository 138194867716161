import axios, {AxiosInstance} from 'axios';
import UrlSetting from "./UrlSetting";
export var env="production";
class Api {
    private static axiosInstance: AxiosInstance;

    static init(){
        this.axiosInstance = axios.create(
        {
            baseURL: env==="development" ? 
            UrlSetting.defaultSettings.DEV_REACT_APP_BASE_URL:
            UrlSetting.defaultSettings.PROD_REACT_APP_BASE_URL,
        }
    );
    };
    static async get<ResponseType>(url:string){
        return await Api.axiosInstance.get<ResponseType>(url);
    }
    static async post<ResponseType, DataType>(url:string, data?:DataType){
        return await Api.axiosInstance.post<ResponseType, DataType>(url, data)
    }
    
};
export default Api;