//Menu for mobile Device data creation
import { menuDataFromConfig } from "../../Config";

import {
  BIO_DIFF_SECTION_CONTENT_HOME_PAGE_REFERENCE, BIO_DIFF_SECTION_HEADER_HOME_PAGE_REFERENCE,
  CAPABILITY_SECTION_CONTENT_REFERENCE, CREATIVE_SECTION_CONTENT_HOME_PAGE_REFERENCE, END_TO_END_HOME_PAGE_REFERENCE, HERO_SECTION_HOME_PAGE_REFERENCE, NEWS_RESOURCE_SECTION_CONTENT_HOME_PAGE_REFERENCE, STRATEGIC_SECTION_CONTENT_HOME_PAGE_REFERENCE,
  ENNG_COMPONENT_HOME_PAGE_REFERENCE, PROACTIVELY_ENGG_CONTENT_REFERENCE, VERTICAL_INTERGRATION_ENGG_CONTENT_REFERENCE,
  WELL_VERSED_CONTENT_REFERENCE, SPEED_INNOVATION_CONTENT_REFERENCE, 
  COMMON_CREATIVE_SECTION_CONTENT_REFERENCE, 
  
  BALLOON_CATHETERS_HEADING_PAGE_REFERENCE,
  BALLOON_CATHETERS_WE_KNOW_CONTENT_REFERENCE,
  // BALLOON_CATHETERS_VERTICAL_INTERGRATION_REFERENCE,BALLOON_CATHETERS_ENGINEERED_CONTENT_REFERENCE,
  // BALLOON_CATHETERS_CONTENT_INHOUSE_TESTING_REFERENCE,
  // BALLOON_CATHETERS_VIRTUAL_TOUR_CONTENT_REFERENCE,
  // BALLOON_CATHETERS_WIDE_RANGE_CONTENT_REFERENCE,
  // BALLOON_CATHETERS_NEED_RANGE_CONTENT_REFERENCE,
  BALLOON_CATHETERS_HAVE_QUESTION_CONTENT_REFERENCE,
  BALLOON_CATHETERS_TABLE_CONTENT_REFERENCE,
  //BALLOON_CATHETERS_HAVE_TABLE_CONTENT_REFERENCE
  //contact us
  CONTACT_HERADR_REFERENCE,
  CONTACT_INFUSION_REFERENCE,
  CONTACT_US_CONTENT_REFERENCE,
  CONTACT_US_THANKS_CONTENT_REFERENCE,
  // Complex Extrusion
  COMPLEX_EXTRUSION_HEADING_PAGE_REFERENCE,
  COMPLEX_EXTRUSION_CUSTOM_CONTENT_REFERENCE,
  COMPLEX_EXTRUSION_VERTICAL_INTERGRATION_REFERENCE,
  COMPLEX_EXTRUSION_VIRTUAL_TOUR_CONTENT_REFERENCE,
  COMPLEX_EXTRUSION_ADVANCED_CONTENT_REFERENCE,
  COMPLEX_EXTRUSION_BROAD_SPECTRUM_REFERENCE,
  COMPLEX_EXTRUSION_TAPERED_CONTENT_REFERENCE,
  COMPLEX_EXTRUSION_COEXTRUSION_CONTENT_REFERENCE,
  COMPLEX_EXTRUSION_JACKETING_CONTENT_REFERENCE,
  COMPLEX_EXTRUSION_BALLOON_CONTENT_REFERENCE,
  COMPLEX_EXTRUSION_VERSATILE_REFERENCE,
  COMPLEX_EXTRUSION_CUSTOM_FLUID_CONTENT_REFERENCE,
  COMPLEX_EXTRUSION_HAVE_QUESTION_CONTENT_REFERENCE,
  COMPLEX_EXTRUSION_TABLE_CONTENT_REFERENCE,

 //Clean Room Molding
 CLEANROOM_MOLDING_HEADING_PAGE_REFERENCE,
 CLEANROOM_MOLDING_COMPLETE_CONTENT_REFERENCE,
 CLEANROOM_MOLDING_VERTICAL_INTERGRATION_REFERENCE,
 CLEANROOM_MOLDING_WORLD_CLASS_CONTENT_REFERENCE,
 CLEANROOM_MOLDING_CENTER_CONTENT_REFERENCE,
 CLEANROOM_MOLDING_VIRTUAL_TOUR_CONTENT_REFERENCE,
 CLEANROOM_MOLDING_EXPERIENCE_CROSS_CONTENT_REFERENCE,
 CLEANROOM_MOLDING_BROAD_MOLDING_CONTENT_REFERENCE,
 CLEANROOM_MOLDING_MOLDED_CONTENT_REFERENCE,
 CLEANROOM_MOLDING_COMPLETE_TOOLING_CONTENT_REFERENCE,
 CLEANROOM_MOLDING_BALLOON_CONTENT_REFERENCE,
 CLEANROOM_MOLDING_SECONDRY_OPERATION_REFERENCE,
 CLEANROOM_MOLDING_HAVE_QUESTION_CONTENT_REFERENCE,

 //Laser
 LASER_HEADING_PAGE_REFERENCE,
 LASER_UNIVALED_PAGE_REFERENCE,
 LASER_VERTICAL_INTERVENTIONAL_PAGE_REFERENCE,
 LASER_VERTICAL_TABLE_PAGE_REFERENCE,

 //mICROmATCHING
 MICRO_HEADING_PAGE_REFERENCE,
 MICRO_AGILE_PAGE_REFERENCE,
 MICRO_CENTER_OF_METAL_MICROCOMPONENT_PAGE_REFERENCE,

 //COMMON
 SELECTION_TYPE_PAGE_REFERENCE,

///mEDICAL POLYMER
MEDICAL_POLYMER_BROADCAST_REANG,
MEDICAL_POLYMER_HERO_SECTION,
MEDICAL_POLYMER_WONDERING,
//METAL
METALS_HELPING_CHOOES,
METALS_HERO_SECTION,
METALS_RELATED_PRODUCTS,
//ADVANCED_CATHETERS
ADVANCED_CATHETERS_HIGHLY_SPECI,
ADVANCED_CATHETERS_HERO_SECTION,
ADVANCED_CATHETERS_RELATED_PRODUCTS,
ADVANCED_CATHETERS_TABLE_CONTENT,
//
MANUFACTURING_HERO_SECTION,
MANUFACTURING_TRUST_PARTNER,
MANUFACTURING_RELATED_HAVE_QUESTION,
IMAGEGUIDEDINTERVENSION_QUESTION_SECTION,
IMAGEGUIDEDINTERVENSION_TABLE_SECTION,

//Level 1 
//design service 
DESIGN_SERVICES_HERO_SECTION,
DESIGN_SERVICES_ACCELERATING,
DESIGN_SERVICES_FLEXIBLITY,
DESIGN_SERVICES_VERTICAL_DEPTH,
DESIGN_SERVICES_FOCUS,
DESIGN_SERVICES_WORLD_CLASS,
DESIGN_SERVICES_PROJECT_MANAGEMENT,

//MATERIAL COMPOUNDING
MATERIAL_AND_COMPOUNDING_FOCUS,
MATERIAL_AND_COMPOUNDING_WORLD_CLASS,
MATERIAL_AND_COMPOUNDING_PROJECT_MANAGEMENT,

//FINISHED DEVICES
FINISHED_DEVICES_HERO,
FINISHED_DEVICES_END_TO_END,
FINISHED_DEVICES_STRATEGICALLY_LOCATED,

//commmon repititive level 1
SELECTION_TYPE_PAGE_REFERENCE_LEVEL_1,

//About us
ABOUT_US_HERO_SECTION,
ABOUT_US_ENGINEERING_COMPANY,
ABOUT_US_FOCUSED_OUR_VISION,
ABOUT_US_LIVING_OUR_VALUES,
ABOUT_US_GROWTH_EXPANSION,
ABOUT_US_MEDTICAL_DEVICE,
ABOUT_US_GET_TO_KNOW,

//leadership
LEADERSHIP_HERO_SECTION,
LEADERSHIP_MEET_EXECUTIVE_SECTION,

//jOIN tEAM
CAREERS_HERO_SECTION,
CAREERS_JOIN_TEAM_SECTION,
CAREERS_ASK_QUESTION,

//MANUFACTURING LINE TRANSFER
MENUFACTURING_LINE_HERO_SECTION,
MENUFACTURING__LINE_TRANSFER_TRUST_SECTION,
MENUFACTURING_CAREERS_ASK_QUESTION,

//who we are 
WHO_WE_ARE_HERO_SECTION,
WHO_WE_ARE_LINE_TRANSFER_TRUST_SECTION,
WHO_WE_ARE_ASK_QUESTION,
//Market 
MARKETS_HERO_SECTION,
MARKETS_INTERVENTIONAL_SECTION,
MARKETS_ASK_QUESTION,

//QUALITY
QUALITY_FULLY_COMPLAINT_SECTION,
QUALITY_HERO_SECTION,

//
CERTIFICATIONS_HERO_SECTION,
CERTIFICATIONS_FULLY_COMPLAINT_SECTION,

//locations
LOCATIONS_HERO_SECTION,
LOCATIONS_FULLY_COMPLAINT_SECTION,
//

IMAGEGUIDEDINTERVENSION_HERO_SECTION,
IMAGEGUIDEDINTERVENSION_SECTION,
//Blog
BLOG_HERO_SECTION,
BLOG_CARD_SECTION,
BLOG_GET_TO_KNOW_SECTION,
PINNED_BLOG_CARD_SECTION,
//
RFQ_HERO_SECTION,
RFQ_QUOTE_SECTION,
RFQ_QUOTE_SECTION_FOOTER,
PRIVACY_POLICY_DETAILS,

COMPONENT_HERO_SECTION,
COMPONENT_SECTION,
COMPONENT_ASK_QUESTION,


NEW_PRODUCT_HERO_SECTION,
NEW_PRODUCT_SECTION,
NEW_PRODUCT_ASK_QUESTION_SECTION,

TECH_TRANSFER_HERO_SECTION,
TECH_TRANSFER_SECTION,
TECH_TRANSFER_ASK_QUESTION_SECTION,


///////////////
L3_DYNAMIC_HEADER_SECTION,
L3_DYNAMIC_OVERVIEW_SECTION,
L3_DYNAMIC_CONTENT_LOOP_SECTION,
L4_HEADER_SECTION,
L4_CONTENT_LOOP_SECTION,
L4_GET_IN_TOUCH_SECTION,
L3_DYNAMIC_GET_IN_TOUCH_SECTION,


/////

OURBOARD_HERO_SECTION,
OURBOARD_MEET_EXECUTIVE_SECTION,
GET_IN_TOUCH_WITH_AN_EXPERT,
LEAD_GEN_HEADER_REFERENCE,
LEAD_GEN_INFUSION_REFERENCE,
LEAD_GEN_CONTENT_REFERENCE,

 ///////

AUTOMATION_HERO_SECTION,
AUTOMATION_LINE_BUILD_SECTION,
AUTOMATION_GET_IN_TOUCH,


/////////
NEW_AUTO_DEV_HERO_SECTION,
NEW_AUTO_DEV_SUB_SECTION,

//TERMS_AND_CONDITIONS
TERMS_AND_CONDITIONS_SECTION,
} from "./Constant";

export const createData = () => {
  const firstLevelList: any = [];
  menuDataFromConfig.map((menu, index) => {
    const subItems = menu.items
    firstLevelList.push(menu.title);
   })
  return firstLevelList
}
export const createData1 = (menuName: string) => {
  let arr;
  menuDataFromConfig.map((menu, index) => {
    const subItems = menu.items
    if (menuName === menu.title) {
      arr = menu.items
      return arr;
    } else {
      subItems.map((subMenu: any, index: any) => {
        const sub_menu_items = subMenu.items
        if (subMenu.title === menuName) {
          arr = sub_menu_items
          return arr
        } else {
          sub_menu_items.map((sub_menu: any, index: any) => {
            if (sub_menu.title === menuName) {
              arr = sub_menu.items
              return arr
            }
          })
        }
      })
    }

  });
  return arr;
}

//extract data from API for Home Page

//------------------Home Page Component-------------------------------///
export const formatHeroSectionData = (response: any) => {
 
  let heroSectionData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if (field_reference === HERO_SECTION_HOME_PAGE_REFERENCE) {
      heroSectionData.push(resp);
    }
  })
  return heroSectionData;
}

export const formatNewsCardSectionData = (response: any) => {
  let newsResourceData: any | {}[] = []
  response.map((resp: any) => {
    const { field_reference } = resp;
    if (field_reference === "Field_row_bio_news") {
      newsResourceData.push(resp);
    }
  })
  return newsResourceData;
}

//Capabilities section
export const formatCapabilityData = (response: any) => {
  let capabilityData: any | {}[] = []
  response.map((resp: any) => {
    const { field_reference } = resp;
    if (field_reference === CAPABILITY_SECTION_CONTENT_REFERENCE) {
      capabilityData.push(resp);
    }
  })
  
  return capabilityData
}

export const formatStrategicData = (response: any) => {
  let strategicData: any | {}[];
  response.map((resp: any) => {
    const { field_reference } = resp;
    if (field_reference === "HomePage-BuildingStrategicPartnerships") {
      strategicData = resp;
    }
  })
  return strategicData
}

export const field_row_7_data = (response: any) => {
  let fieldRow7Data: any | {}[];
  response.map((resp: any) => {
    const { field_reference } = resp;
    if (field_reference === CREATIVE_SECTION_CONTENT_HOME_PAGE_REFERENCE) {
      fieldRow7Data = resp;
    }
  })
  return fieldRow7Data;
}

//STRATEGIC_SECTION
export const fieldBuildingStrategic = (response: any) => {
  let BuildingStrategicData;
  response.map((resp: any) => {
    const { field_reference } = resp;
    if (field_reference ===STRATEGIC_SECTION_CONTENT_HOME_PAGE_REFERENCE) {
      BuildingStrategicData = resp;
    }
  })
  return BuildingStrategicData;
}

//End to End Capabilities
export const field_row_2_data = (response: any) => {
  let field_row7_data;
  
  response.map((resp: any) => {
    const { field_reference } = resp;
    if (field_reference ===END_TO_END_HOME_PAGE_REFERENCE) {
      field_row7_data = resp;
    }
  })
 
  return field_row7_data;
}

//Header of Bio Differnce section
export const row_Bio_diff_header = (response: any) => {
  let row_4_data: any | {}[] = [];
  response.map((resp: any) => {
    const { field_reference } = resp;
    if (field_reference === BIO_DIFF_SECTION_HEADER_HOME_PAGE_REFERENCE) {
      row_4_data.push(resp)
    }
  });
   return row_4_data;
}

//Content of Bio Differnce section
export const row_Bio_diff_content = (response: any) => {
  let row_4_data: any | {}[] = [];
  response.map((resp: any) => {
    const { field_reference } = resp;
    if (field_reference === BIO_DIFF_SECTION_CONTENT_HOME_PAGE_REFERENCE) {
      row_4_data.push(resp)
    }
  });
  return row_4_data;
}

const dummyData =
  [
    {
      "nid": "164",
      "title": "Creating value as a strategic partner to global OEMs",
      "field_description": "Discover the Biomerics difference.",
      "field_description2": "",
      "field_description3": "",
      "field_description4": "",
      "field_image_url": "",
      "field_video": "",
      "field_anchorlink": "",
      "field_reference": "Field_row_biomerics_difference"
    },
    {
      "nid": "165",
      "title": "ENGINEERING EXPERTIES",
      "field_description": "We meet customers where they are, rather than taking a rigid, &quot;on-size-fits-all&quot; approach. As a midsize company, we&#039;re large enough to offer resources to scale while remaining agile   and customer focused.",
      "field_description2": "",
      "field_description3": "",
      "field_description4": "",
      "field_image_url": "",
      "field_video": "",
      "field_anchorlink": "",
      "field_reference": "Field_row_biomerics_difference"
    },
    {
      "nid": "166",
      "title": "DEEP MARKET",
      "field_description": "Minimally invasive devices are designed to navigate, access, and deliver therapy to some of the hardest-to-reach anatomical locations. We have decades of expertise in medical robotics, cardiovascular, endoscopy, and more.",
      "field_description2": "",
      "field_description3": "",
      "field_description4": "",
      "field_image_url": "",
      "field_video": "",
      "field_anchorlink": "",
      "field_reference": "Field_row_biomerics_difference"
    },
    {
      "nid": "167",
      "title": "CUSTOMER FOCUS",
      "field_description": "We meet customers where they are, rather than taking a rigid, &quot;on-size-fits-all&quot; approach. As a midsize company, we&#039;re large enough to offer resources to scale while remaining agile and customer focused.",
      "field_description2": "",
      "field_description3": "",
      "field_description4": "",
      "field_image_url": "",
      "field_video": "",
      "field_anchorlink": "",
      "field_reference": "Field_row_biomerics_difference"
    },
    {
      "nid": "168",
      "title": "STRATEGIC LOCATION",
      "field_description": "Our seven facilities are strategically located for our customers in medical device hubs around the globe, including California, Minnesota, and Costa Rica.",
      "field_description2": "",
      "field_description3": "",
      "field_description4": "",
      "field_image_url": "",
      "field_video": "",
      "field_anchorlink": "",
      "field_reference": "Field_row_biomerics_difference"
    },]
var testData = row_Bio_diff_content(dummyData);

//News and Resource section
export const newsResource1 = (response: any) => {

  let data: any | {}[] = [];
  response.map((resp: any) => {
    const { field_reference } = resp;
    if (field_reference === NEWS_RESOURCE_SECTION_CONTENT_HOME_PAGE_REFERENCE) {
      data.push(resp)
    }
  });

  return data;
}

// //------------------Engg Component-------------------------------///

// export const formatEnggComponentData = (response: any) => {
//   let EnggComponentData: any | {}[] = []
//   response.map((resp: any, index: any) => {
//     const { field_reference } = resp;
//     if (field_reference === ENNG_COMPONENT_HOME_PAGE_REFERENCE) {
//       EnggComponentData.push(resp);
//     }
//   })
//   return EnggComponentData;
// }

// //extract data from API for EnggComponentData 
// export const proactivelyEnggResponces = (response: any) => {
//   let proactivelyEnggData;
//   response.map((resp: any) => {
//     const { field_reference } = resp;
//     if (field_reference === PROACTIVELY_ENGG_CONTENT_REFERENCE) {
//       proactivelyEnggData = resp;
//     }
//   })
//   return proactivelyEnggData;
// }

// //for EnggComponentData
// export const formatEnggVerticalData = (response: any) => {
//   let enggVerticalData: any | {}[] = []
//   response.map((resp: any) => {
//     const { field_reference } = resp;
//     if (field_reference === VERTICAL_INTERGRATION_ENGG_CONTENT_REFERENCE) {
//       enggVerticalData.push(resp);
//     }
//   })
//   return enggVerticalData
// }

// //WELL_VERSED_CONTENT_REFERENCE for EnggComponentData
// export const formatWellVersedData = (response: any) => {
//   let enggWellVersedData: any | {}[] = []
//   response.map((resp: any) => {
//     const { field_reference } = resp;
//     if (field_reference === WELL_VERSED_CONTENT_REFERENCE) {
//       enggWellVersedData.push(resp);
//     }
//   });
//   return enggWellVersedData
// }

// //SPEED_INNOVATION_CONTENT_REFERENCE for EnggComponentData
// export const formatSpeedInnovationData = (response: any) => {
//   let enggSpeedInnovationData: any | {}[] = []
//   response.map((resp: any) => {
//     const { field_reference } = resp;
//     if (field_reference === SPEED_INNOVATION_CONTENT_REFERENCE) {
//       enggSpeedInnovationData.push(resp);
//     }
//   });
//   return enggSpeedInnovationData
// }

//for engg component page
export const fieldCreativeSolutionData = (response: any) => {
  let fieldCreativeSolutionRes: any | {}[];
  response.map((resp: any) => {
    const { field_reference } = resp;
    if (field_reference ==="Creative_Solutions_For_Tough_Challenges") {
      fieldCreativeSolutionRes = resp;
    }
  })
  return fieldCreativeSolutionRes;
}


    //---------------contact Us--------------------------//

    export const formatContactHeader = (response: any) => {
      let ContactHeader: any | {}[] = []
      response.map((resp: any, index: any) => {
        const { field_reference } = resp;
        if (field_reference === CONTACT_HERADR_REFERENCE) {
          ContactHeader.push(resp);
        }
      })
      return ContactHeader;
    } 

    export const formatContactInfusion = (response: any) => {
      let ContactInfusionData: any | {}[] = []
      response.map((resp: any, index: any) => {
        const { field_reference } = resp;
        if (field_reference === CONTACT_INFUSION_REFERENCE) {
        ContactInfusionData.push(resp);
        }
      })
      return ContactInfusionData;
    }     

    export const formatContactUs = (response: any) => {
      let ContactUsData: any | {}[] = []
      response.map((resp: any, index: any) => {
        const { field_reference } = resp;
        if (field_reference === CONTACT_US_THANKS_CONTENT_REFERENCE) {
          ContactUsData.push(resp);
        }
      })
      return ContactUsData;
    }   
    
    export const formatContactAddress= (response: any) => {
      let ContactAddress: any | {}[] = []
      response.map((resp: any, index: any) => {
        const { field_reference } = resp;
        if (field_reference === CONTACT_US_CONTENT_REFERENCE) {
          ContactAddress.push(resp);
        }
      })
       return ContactAddress;
    } 

  ////-----------Complex Extrusion------------///

  export const formatComplexExtrusion= (response: any) => {
      let ComplexExtrusionData: any | {}[] = []
      response.map((resp: any, index: any) => {
        const { field_reference } = resp;
        if (field_reference === COMPLEX_EXTRUSION_HEADING_PAGE_REFERENCE) {
          ComplexExtrusionData.push(resp);
        }
      })
     return ComplexExtrusionData;
  } 
  export const formatComplexCustom = (response: any) => {
    let ComplexCustomData: any | {}[] = []
    response.map((resp: any, index: any) => {
      const { field_reference } = resp;
      if (field_reference === COMPLEX_EXTRUSION_CUSTOM_CONTENT_REFERENCE) {
        ComplexCustomData.push(resp);
      }
    })
     return ComplexCustomData;
  } 
  export const formatComplexExtrusionVerticalIntergartion = (response: any) => {
    let ComplexExtrusionData: any | {}[] = []
    response.map((resp: any, index: any) => {
      const { field_reference } = resp;
      if(field_reference === COMPLEX_EXTRUSION_VERTICAL_INTERGRATION_REFERENCE) {
          ComplexExtrusionData.push(resp);
      }
    })
    return ComplexExtrusionData;
  } 

  export const formatComplexExtrusionVertualTour = (response: any) => {
    let ExtrusionVertualTourData: any | {}[] = []
    response.map((resp: any, index: any) => {
      const { field_reference } = resp;
      if(field_reference === COMPLEX_EXTRUSION_VIRTUAL_TOUR_CONTENT_REFERENCE) {
        ExtrusionVertualTourData.push(resp);
      }
    })
    return ExtrusionVertualTourData;
  } 

  export const formatComplexExtrusionAdvancedEngg = (response: any) => {
    let ExtrusionAdvancedEnggData: any | {}[] = []
    response.map((resp: any, index: any) => {
      const { field_reference } = resp;
      if(field_reference === COMPLEX_EXTRUSION_ADVANCED_CONTENT_REFERENCE) {
        ExtrusionAdvancedEnggData.push(resp);
      }
    })
    return ExtrusionAdvancedEnggData;
  } 

  export const formatComplexExtrusionBroad = (response: any) => {
    let ComplexExtrusionBroadData: any | {}[] = []
    response.map((resp: any, index: any) => {
      const { field_reference } = resp;
      if(field_reference === COMPLEX_EXTRUSION_BROAD_SPECTRUM_REFERENCE) {
        ComplexExtrusionBroadData.push(resp);
      }
    })
    return ComplexExtrusionBroadData;
  } 
  export const formatComplexExtrusionTapered = (response: any) => {
    let ComplexExtrusionTaperedData: any | {}[] = []
    response.map((resp: any, index: any) => {
      const { field_reference } = resp;
      if(field_reference === COMPLEX_EXTRUSION_TAPERED_CONTENT_REFERENCE) {
        ComplexExtrusionTaperedData.push(resp);
      }
    })
    return ComplexExtrusionTaperedData;
  } 
  export const formatComplexExtrusionCoextrusion = (response: any) => {
    let ComplexExtrusionCoextrusionData: any | {}[] = []
    response.map((resp: any, index: any) => {
      const { field_reference } = resp;
      if(field_reference === COMPLEX_EXTRUSION_COEXTRUSION_CONTENT_REFERENCE) {
        ComplexExtrusionCoextrusionData.push(resp);
      }
    })
    return ComplexExtrusionCoextrusionData;
  } 

  export const formatComplexExtrusionJacketing = (response: any) => {
    let ComplexExtrusionJacketingData: any | {}[] = []
    response.map((resp: any, index: any) => {
      const { field_reference } = resp;
      if(field_reference === COMPLEX_EXTRUSION_JACKETING_CONTENT_REFERENCE) {
        ComplexExtrusionJacketingData.push(resp);
      }
    })
    return ComplexExtrusionJacketingData;
  } 
  
  export const formatComplexBalloonContent = (response: any) => {
    let ComplexBalloonContentData: any | {}[] = []
    response.map((resp: any, index: any) => {
      const { field_reference } = resp;
      if(field_reference === COMPLEX_EXTRUSION_BALLOON_CONTENT_REFERENCE) {
        ComplexBalloonContentData.push(resp);
      }
    })
    return ComplexBalloonContentData;
  } 
  export const formatComplexExtrusionversatile = (response: any) => {
    let ComplexExtrusionversatileData: any | {}[] = []
    response.map((resp: any, index: any) => {
      const { field_reference } = resp;
      if(field_reference === COMPLEX_EXTRUSION_VERSATILE_REFERENCE) {
        ComplexExtrusionversatileData.push(resp);
      }
    })
    return ComplexExtrusionversatileData;
  } 

  
 export const formatComplexCustomFluid = (response: any) => {
  let ComplexCustomFluid: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === COMPLEX_EXTRUSION_CUSTOM_FLUID_CONTENT_REFERENCE) {
      ComplexCustomFluid.push(resp);
    }
  })
  return ComplexCustomFluid;
} 
  export const formatComplexHaveQuestion = (response: any) => {
    let ComplexExtrusionHaveQuestionData: any | {}[] = []
    response.map((resp: any, index: any) => {
      const { field_reference } = resp;
      if(field_reference === COMPLEX_EXTRUSION_HAVE_QUESTION_CONTENT_REFERENCE) {
        ComplexExtrusionHaveQuestionData.push(resp);
      }
    })
    return ComplexExtrusionHaveQuestionData;
  } 


 ///------------------Clean Room Molding ---------------------//

 export const formatCleanRoomMolding = (response: any) => {
  let CleanRoomMoldingData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === CLEANROOM_MOLDING_HEADING_PAGE_REFERENCE) {
      CleanRoomMoldingData.push(resp);
    }
  })
  return CleanRoomMoldingData;
} 

export const formatCleanRoomComplete = (response: any) => {
  let CleanRoomCompleteData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === CLEANROOM_MOLDING_COMPLETE_CONTENT_REFERENCE) {
      CleanRoomCompleteData.push(resp);
    }
  })
  return CleanRoomCompleteData;
} 

export const formatCleanRoomVertical = (response: any) => {
  let CleanRoomVerticalData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === CLEANROOM_MOLDING_VERTICAL_INTERGRATION_REFERENCE) {
      CleanRoomVerticalData.push(resp);
    }
  })
 return CleanRoomVerticalData;
} 

export const formatCleanRoomMoldingCenter = (response: any) => {
  let CleanRoomMoldingCenterData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === CLEANROOM_MOLDING_CENTER_CONTENT_REFERENCE) {
      CleanRoomMoldingCenterData.push(resp);
    }
  })
  
  return CleanRoomMoldingCenterData;
} 
export const formatCleanRoomWorldClass = (response: any) => {
  let CleanRoomWorldClassData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === CLEANROOM_MOLDING_WORLD_CLASS_CONTENT_REFERENCE) {
      CleanRoomWorldClassData.push(resp);
    }
  })
  return CleanRoomWorldClassData;
} 

export const formatCleanRoomVertualTour = (response: any) => {
  let CleanRoomVertualTourData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === CLEANROOM_MOLDING_VIRTUAL_TOUR_CONTENT_REFERENCE) {
      CleanRoomVertualTourData.push(resp);
    }
  })
  
  return CleanRoomVertualTourData;
} 

export const formatCleanRoomExperinceCross = (response: any) => {
  let CleanRoomExperinceCrossData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === CLEANROOM_MOLDING_EXPERIENCE_CROSS_CONTENT_REFERENCE) {
      CleanRoomExperinceCrossData.push(resp);
    }
  })
  
  return CleanRoomExperinceCrossData;
} 

export const formatCleanRoomBroadMolding = (response: any) => {
  let CleanRoomBroadMoldingData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === CLEANROOM_MOLDING_BROAD_MOLDING_CONTENT_REFERENCE) {
      CleanRoomBroadMoldingData.push(resp);
    }
  })
  
  return CleanRoomBroadMoldingData;
} 

export const formatCleanRoomHaveQuestion = (response: any) => {
  let CleanRoomHaveQuestionData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === CLEANROOM_MOLDING_HAVE_QUESTION_CONTENT_REFERENCE) {
      CleanRoomHaveQuestionData.push(resp);
    }
  })
  return CleanRoomHaveQuestionData;
} 


///------------------level 2 ---------------------//

//Banner Section
export const formatHeadingSection = (response: any) => {
  let HeadingSection: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === LASER_HEADING_PAGE_REFERENCE) {
      HeadingSection.push(resp);
    }
    if(field_reference === BALLOON_CATHETERS_HEADING_PAGE_REFERENCE) {
      HeadingSection.push(resp);
    }
    else if(field_reference === MICRO_HEADING_PAGE_REFERENCE){
      HeadingSection.push(resp);
      
    }
    else if(field_reference === CLEANROOM_MOLDING_HEADING_PAGE_REFERENCE){
      HeadingSection.push(resp);
      
    }
    else if(field_reference === MEDICAL_POLYMER_HERO_SECTION){
      HeadingSection.push(resp);
      
    }
    else if(field_reference === METALS_HERO_SECTION){
      HeadingSection.push(resp);
      
    }
    else if(field_reference === ADVANCED_CATHETERS_HERO_SECTION){
      HeadingSection.push(resp);
      
    }else if(field_reference === MANUFACTURING_HERO_SECTION){
      HeadingSection.push(resp);
      
    }
    else if(field_reference === COMPLEX_EXTRUSION_HEADING_PAGE_REFERENCE){
      HeadingSection.push(resp);
      
     }else if(field_reference === CAREERS_HERO_SECTION){
      HeadingSection.push(resp);
      
     }
     else if(field_reference === MENUFACTURING_LINE_HERO_SECTION){
      HeadingSection.push(resp);
      
     }
     else if(field_reference === WHO_WE_ARE_HERO_SECTION){
      HeadingSection.push(resp);
      
     }
     else if(field_reference === MARKETS_HERO_SECTION){
      HeadingSection.push(resp);
      
     }
     else if(field_reference === QUALITY_HERO_SECTION){
      HeadingSection.push(resp);
     }
     else if(field_reference === CERTIFICATIONS_HERO_SECTION){
      HeadingSection.push(resp);
     }
     else if(field_reference === LOCATIONS_HERO_SECTION){
      HeadingSection.push(resp);
     }
     else if(field_reference === IMAGEGUIDEDINTERVENSION_HERO_SECTION){
      HeadingSection.push(resp);
     }
     else if(field_reference === COMPONENT_HERO_SECTION){
      HeadingSection.push(resp);
     }
     else if(field_reference === NEW_PRODUCT_HERO_SECTION){
      HeadingSection.push(resp);
     }
     else if(field_reference === TECH_TRANSFER_HERO_SECTION){
      HeadingSection.push(resp);
     }

     else if (field_reference === AUTOMATION_HERO_SECTION) {
      HeadingSection.push(resp);
    }

    else if (field_reference === NEW_AUTO_DEV_HERO_SECTION) {
      HeadingSection.push(resp);
    }


    
  
     })
  return HeadingSection;
} 
//
// IMAGEGUIDEDINTERVENSION_HERO_SECTION,
// IMAGEGUIDEDINTERVENSION__SECTION,
//Square Section
export const formatRightSection1 = (response: any) => {
  
  console.log("response in loop section.....", response);

  let SectionRightData1: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === LASER_UNIVALED_PAGE_REFERENCE) {
      SectionRightData1.push(resp);
    }
    if(field_reference === CERTIFICATIONS_FULLY_COMPLAINT_SECTION) {
      SectionRightData1.push(resp);
    }
    if(field_reference === LOCATIONS_FULLY_COMPLAINT_SECTION) {
      SectionRightData1.push(resp);
    }
    if(field_reference === BALLOON_CATHETERS_WE_KNOW_CONTENT_REFERENCE) {
      SectionRightData1.push(resp);
    }
    else if(field_reference === MICRO_AGILE_PAGE_REFERENCE){
      SectionRightData1.push(resp);
      
    }else if(field_reference === CLEANROOM_MOLDING_COMPLETE_CONTENT_REFERENCE){
      SectionRightData1.push(resp);

    }
    else if(field_reference === MEDICAL_POLYMER_BROADCAST_REANG){
        SectionRightData1.push(resp);
    }
    else if(field_reference === METALS_HELPING_CHOOES){
      SectionRightData1.push(resp);
    
  }else if(field_reference === ADVANCED_CATHETERS_HIGHLY_SPECI){
    SectionRightData1.push(resp);
  
  }else if(field_reference === MANUFACTURING_TRUST_PARTNER){
    SectionRightData1.push(resp);
  
  }//LEADERSHIP_MEET_EXECUTIVE_SECTION
  else if(field_reference === COMPLEX_EXTRUSION_CUSTOM_CONTENT_REFERENCE){
    SectionRightData1.push(resp);
  
  }
  else if(field_reference === CAREERS_JOIN_TEAM_SECTION){
    SectionRightData1.push(resp);
  }
  else if(field_reference === MENUFACTURING__LINE_TRANSFER_TRUST_SECTION){
    SectionRightData1.push(resp);
  }
  else if(field_reference === WHO_WE_ARE_LINE_TRANSFER_TRUST_SECTION){
    SectionRightData1.push(resp);
  }
  else if(field_reference === MARKETS_INTERVENTIONAL_SECTION){
    SectionRightData1.push(resp);
  }
  else if(field_reference === QUALITY_FULLY_COMPLAINT_SECTION){
    SectionRightData1.push(resp);
  }
  else if(field_reference === IMAGEGUIDEDINTERVENSION_SECTION ){
    SectionRightData1.push(resp);
  }
  else if(field_reference === COMPONENT_SECTION ){
    SectionRightData1.push(resp);
  }
  else if(field_reference === NEW_PRODUCT_SECTION ){
    SectionRightData1.push(resp);
  }
  else if(field_reference === TECH_TRANSFER_SECTION ){
    SectionRightData1.push(resp);
  }
  
  else if (field_reference === AUTOMATION_LINE_BUILD_SECTION) {
    SectionRightData1.push(resp);
}
else if (field_reference === NEW_AUTO_DEV_SUB_SECTION) {
  SectionRightData1.push(resp);
}


  })

  return SectionRightData1;
}

export const formatExpertSection = (response: any) => {
  let expertSection: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference ===GET_IN_TOUCH_WITH_AN_EXPERT) {
      expertSection.push(resp);
    } 
  })
  return expertSection;
} 

export const formatCenterSection10 = (response: any) => {
  let centerSectionData10: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;

    if(field_reference === MARKETS_ASK_QUESTION) {
      centerSectionData10.push(resp);
    } if(field_reference === LASER_VERTICAL_INTERVENTIONAL_PAGE_REFERENCE) {
      centerSectionData10.push(resp);
    }
      if(field_reference === BALLOON_CATHETERS_HAVE_QUESTION_CONTENT_REFERENCE) {
      centerSectionData10.push(resp);
    }
    else if(field_reference === MICRO_CENTER_OF_METAL_MICROCOMPONENT_PAGE_REFERENCE){
      centerSectionData10.push(resp);
    }else if(field_reference === CLEANROOM_MOLDING_HAVE_QUESTION_CONTENT_REFERENCE){
      centerSectionData10.push(resp);
    }
    else if(field_reference === MEDICAL_POLYMER_WONDERING){
      centerSectionData10.push(resp);
    }
    else if(field_reference === METALS_RELATED_PRODUCTS){
      centerSectionData10.push(resp);
    }
    else if(field_reference===ADVANCED_CATHETERS_RELATED_PRODUCTS){
      centerSectionData10.push(resp);
    }
    else if(field_reference===MANUFACTURING_RELATED_HAVE_QUESTION){
      centerSectionData10.push(resp);
    }else if(field_reference===COMPLEX_EXTRUSION_HAVE_QUESTION_CONTENT_REFERENCE)
    {
      centerSectionData10.push(resp);
    }
    else if(field_reference===CAREERS_ASK_QUESTION)
    {
      centerSectionData10.push(resp);
    }
    else if(field_reference===MENUFACTURING_CAREERS_ASK_QUESTION)
    {
      centerSectionData10.push(resp);
    }
    else if(field_reference===WHO_WE_ARE_ASK_QUESTION)
    {
      centerSectionData10.push(resp);
    }else if(field_reference===IMAGEGUIDEDINTERVENSION_QUESTION_SECTION)
    {
      centerSectionData10.push(resp);
    }
    else if(field_reference===COMPONENT_ASK_QUESTION)
    {
      centerSectionData10.push(resp);
    }
    else if(field_reference===NEW_PRODUCT_ASK_QUESTION_SECTION)
    {
      centerSectionData10.push(resp);
    }
    else if(field_reference===TECH_TRANSFER_ASK_QUESTION_SECTION)
    {
      centerSectionData10.push(resp);
    }

    else if (field_reference === AUTOMATION_GET_IN_TOUCH) {
      centerSectionData10.push(resp);
    }
  })
  return centerSectionData10;
} 


export const formatBalloonCathTableType = (response: any) => {
  let TableSectionTypeData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
      if(field_reference === BALLOON_CATHETERS_TABLE_CONTENT_REFERENCE) {
        TableSectionTypeData.push(resp);
      } else if(field_reference === COMPLEX_EXTRUSION_TABLE_CONTENT_REFERENCE) {
        TableSectionTypeData.push(resp);
      } else if(field_reference === ADVANCED_CATHETERS_TABLE_CONTENT) {
        TableSectionTypeData.push(resp);
      }
      else if(field_reference === IMAGEGUIDEDINTERVENSION_TABLE_SECTION) {
        TableSectionTypeData.push(resp);
      }
      
    })
  return TableSectionTypeData;
} 
//Common Repitative Left-Right test Sections
export const formatCommonRepitativeSectionType = (response: any) => {
  let RepitativeSectionTypeData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_section_type } = resp;
      if(field_section_type === SELECTION_TYPE_PAGE_REFERENCE) {
        RepitativeSectionTypeData.push(resp);
      }
    })
  return RepitativeSectionTypeData;
} 
//End Level 2

//------------------lavel 1 ---------------------//

export const formatHeroSection = (response: any) => {
  let HeadingSection: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === DESIGN_SERVICES_HERO_SECTION) {
      HeadingSection.push(resp);
    }else if(field_reference === MATERIAL_AND_COMPOUNDING_FOCUS){
      HeadingSection.push(resp);
    
    }else if(field_reference === FINISHED_DEVICES_HERO){
      HeadingSection.push(resp);
    }
    else if(field_reference === ENNG_COMPONENT_HOME_PAGE_REFERENCE){
      HeadingSection.push(resp);
    }
  
  })
  return HeadingSection;
}

export const formatRightTextSection1 = (response: any) => {
 
  let SectionRightData1: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === DESIGN_SERVICES_ACCELERATING) {
      SectionRightData1.push(resp);
    }else if(field_reference === MATERIAL_AND_COMPOUNDING_WORLD_CLASS){
      SectionRightData1.push(resp);
    }
    else if(field_reference === FINISHED_DEVICES_END_TO_END){
      SectionRightData1.push(resp);
     }
    else if(field_reference === PROACTIVELY_ENGG_CONTENT_REFERENCE){
      SectionRightData1.push(resp);
    }

   })
   
  return SectionRightData1;
}

export const formatCommonBottomSection = (response: any) => {
  let CommonBottomSection: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
      if(field_reference === DESIGN_SERVICES_PROJECT_MANAGEMENT) {
        CommonBottomSection.push(resp);
      }else if(field_reference === MATERIAL_AND_COMPOUNDING_PROJECT_MANAGEMENT){
        CommonBottomSection.push(resp);
      }else if(field_reference === FINISHED_DEVICES_STRATEGICALLY_LOCATED){
        CommonBottomSection.push(resp);
      }else if(field_reference === SPEED_INNOVATION_CONTENT_REFERENCE){
        CommonBottomSection.push(resp);
      }
     
      
    })
  return CommonBottomSection;
}
//Common Repitative Left-Right test Sections
export const formatCommonRepitativeSectionTypeLevel1 = (response: any) => {
  let RepitativeSectionTypeData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_section_type } = resp;
      if(field_section_type === SELECTION_TYPE_PAGE_REFERENCE_LEVEL_1) {
     
        RepitativeSectionTypeData.push(resp);
      
      }
    })
  return RepitativeSectionTypeData;
} 

//----------------About Us----------------------------//

export const AboutHeroSection = (response: any) => {
  let HeadingSection: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === ABOUT_US_HERO_SECTION) {
      HeadingSection.push(resp);
    }
  })
  return HeadingSection;
}

export const AboutEnggCompSection = (response: any) => {
  let AboutEnggCompData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === ABOUT_US_ENGINEERING_COMPANY) {
      AboutEnggCompData.push(resp);
    }
    
  })
  return AboutEnggCompData;
}
export const AboutFocusedOurVisionSection = (response: any) => {
  let FocusedOurData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === ABOUT_US_FOCUSED_OUR_VISION) {
      FocusedOurData.push(resp);
    }
  })
  return FocusedOurData;
}
export const AboutLivingValues = (response: any) => {
  let LivingValuesData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === ABOUT_US_LIVING_OUR_VALUES) {
      LivingValuesData.push(resp);
    }
  })
  return LivingValuesData;
}
export const AboutGrowthExpansion = (response: any) => {
  let GrowthExpansionData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === ABOUT_US_GROWTH_EXPANSION) {
      GrowthExpansionData.push(resp);
    }
  })
  return GrowthExpansionData;
}
export const AboutMedicalDevice = (response: any) => {
  let MedicalDeviceData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === ABOUT_US_MEDTICAL_DEVICE) {
      MedicalDeviceData.push(resp);
    }
  })
  return MedicalDeviceData;
}
export const AboutGetToKnow = (response: any) => {
  let GetToKnowData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === ABOUT_US_GET_TO_KNOW) {
      GetToKnowData.push(resp);
    }
  })
  return GetToKnowData;
}

//----------Leadership-------------------------//


export const LeadershipHeroSection = (response: any) => {
  let HeadingSection: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === LEADERSHIP_HERO_SECTION) {
      HeadingSection.push(resp);
    }
  })
  return HeadingSection;
}

    export const LeadershipCardSection = (response:any) => {
     //  console.log(card_data,"card_data")
      let LeadershipCardData: any | {}[] = []
     
      // response.map((resp: any, index: any) => {
      //   const { field_reference } = resp;
      //   if(field_reference === "card data") {
      //     console.log(field_reference,"field_reference")
      //     LeadershipCardData.push(resp);
      //   }
      // })
       //return LeadershipCardData;
    }
   // var teamData = LeadershipCardSection(card_data);
   // console.log(teamData,"teamData")



export const BlogHeroSection = (response: any) => {
  let HeadingSection: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === BLOG_HERO_SECTION) {
      HeadingSection.push(resp);
    }
  })
  return HeadingSection;
}
export const BlogCarsSection = (response: any) => {
  let BlogCarsSection: any | {}[] = []
   response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === BLOG_CARD_SECTION) {
      BlogCarsSection.push(resp);

    }
  })
  return BlogCarsSection;
}

export const GetToKnoewSection = (response: any) => {
  let GetToKnoewSection: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === BLOG_GET_TO_KNOW_SECTION) {
      GetToKnoewSection.push(resp);
    }
  })
  return GetToKnoewSection;
}

export const PinnedBlogSection = (response: any) => {
  let PinnedBlogSection: any | {}[] = []

  if(response!==""){
    response.map((resp: any, index: any) => {
      const { field_reference } = resp;
      if(field_reference === PINNED_BLOG_CARD_SECTION) {
        PinnedBlogSection.push(resp);
      }
     
    })
  }else{
    PinnedBlogSection.push("No Data Avaialble");
  }
 
  return PinnedBlogSection;
}

export const BlogSearchSection = (response: any) => {
  let BlogSearchSection: any | {}[] = []
   response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === BLOG_CARD_SECTION) {
      BlogSearchSection.push(resp);

    }
  })
  return BlogSearchSection;
}




export const RfqHeroSection = (response: any) => {
  let RfqHeadingSection: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === RFQ_HERO_SECTION) {
      RfqHeadingSection.push(resp);
    }
  })
  return RfqHeadingSection;
}

export const RfqQouteSection = (response: any) => {
  let RfqQouteSectionData: any | {}[] = []
  
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === RFQ_QUOTE_SECTION) {
      RfqQouteSectionData.push(resp);
    }
  })
  return RfqQouteSectionData;
}
export const RfqQouteFooterSection = (response: any) => {
  let RfqFooterSectionData: any | {}[] = []
  
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === RFQ_QUOTE_SECTION_FOOTER) {
      RfqFooterSectionData.push(resp);
    }
  })
  return RfqFooterSectionData;
}

export const privacyPolicyDetail = (response: any) => {
  let privacyPolicySectionData: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === PRIVACY_POLICY_DETAILS) {
        privacyPolicySectionData.push(resp);
    }
    
  })
  return privacyPolicySectionData;
}



export const formatRequiredDatafromApi=(str:string)=>{
  const filterdHTML=str.replace(/<\/?[^>]+(>|$)/g, "")
   
    
  const newStr = filterdHTML.replace(/\n/g, " ").replace(/\s+/g,' ') ; 
  var o = JSON.parse(newStr);
  return o;
}

export const convertResponseToRequiredDs=(body:string)=>{
  const newStr = body.replace(/^"(.*)"$/, "$1");

  const removed = newStr.replace(/(\r\n|\n|\r)/gm, "");
  const parseStr = removed.replace(/[^\x20-\x7E]/gim, "");

return JSON.parse(parseStr.replace( /(<([^>]+)>)/ig, ''));
};


export const truncateString=(str:string, num:number)=> {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

/** Level 3 field refrence  */

export const levelThreeHeaderDetail = (response: any) => {
  let levelThreeHeaderData: any | {}[] = []
 
  response.map((resp: any, index: any) => {
    
    const { field_reference } = resp;
  
    if(field_reference === L3_DYNAMIC_HEADER_SECTION) {
      levelThreeHeaderData.push(resp);
    }
    
  })
  return levelThreeHeaderData;
}
export const levelThreeOverviewDetail = (response: any) => {
  let levelThreeOverviewData: any | {}[] = []
 
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === L3_DYNAMIC_OVERVIEW_SECTION) {
      levelThreeOverviewData.push(resp);
    }
    
  })
  return levelThreeOverviewData;
}

export const levelThreeImgContentDetail = (response: any) => {
  let levelThreeImgContentData: any | {}[] = []
 
  response.map((resp: any, index: any) => {
    const { field_section_type } = resp;
    if(field_section_type === L3_DYNAMIC_CONTENT_LOOP_SECTION) {
      levelThreeImgContentData.push(resp);
    }
    
  })
  return levelThreeImgContentData;
}
export const levelThreeCenterDataDetail = (response:any)=>{
  let levelThreeCenterData:any | {}[]=[]
  response.map((resp:any,index:any)=>{
    const { field_reference } = resp;
    if(field_reference === L3_DYNAMIC_GET_IN_TOUCH_SECTION){
      levelThreeCenterData.push(resp);
    }
  })
  return levelThreeCenterData;
}

// Level 4 field reference

export const level4HeaderDetail = (response: any) => {
  let level4HeaderData:any | {}[] = []
  response.map((resp:any,index:any)=>{
    const { field_reference } = resp;
    if(field_reference === L4_HEADER_SECTION){
      level4HeaderData.push(resp)
    }
  })
  return level4HeaderData;
}

// export const level4OverviewDetail = (response: any) => {
//   let level4OverviewData: any | {}[] = []
 
//   response.map((resp: any, index: any) => {
//     const { field_reference } = resp;
//     if(field_reference === L4_OVERVIEW_SECTION) {
//       level4OverviewData.push(resp);
//     }
    
//   })
//   return level4OverviewData;
// }

export const level4ImgContentDetail = (response: any) => {
  let level4ImgContentData: any | {}[] = []
 
  response.map((resp: any, index: any) => {
    const { field_section_type } = resp;
    if(field_section_type === L4_CONTENT_LOOP_SECTION) {
      level4ImgContentData.push(resp);
    }
    
  })
  return level4ImgContentData;
}

export const level4TableSection = (response:any)=>{
  let level4TableData:any | {}[] = []
  response?.map((resp:any,index:any)=>{
    const {field_reference} = resp;
      if(field_reference === LASER_VERTICAL_TABLE_PAGE_REFERENCE) {
        level4TableData.push(resp);
      }
  })
  return level4TableData;
}

export const level4CenterSection = (response:any)=>{
  let level4CenterSectionData:any | {}[] = []
  response.map((resp:any,index:any)=>{
    const {field_reference} = resp;
    if(field_reference === L4_GET_IN_TOUCH_SECTION){
      level4CenterSectionData.push(resp);
    }
  })
  return level4CenterSectionData;
}

////////Our Board

export const OurBoardHeroSection = (response: any) => {
console.log("OUR BOARD response",response)
  let HeadingSection: any | {}[] = []
  response.map((resp: any, index: any) => {
    const { field_reference } = resp;
    if(field_reference === OURBOARD_HERO_SECTION) {
      HeadingSection.push(resp);
    }
  })
  return HeadingSection;
}


    //---------------Lead Gen Page Material--------------------------//

    export const formatLeadGenHeader = (response: any) => {
      let LeadHeader: any | {}[] = []
      response.map((resp: any, index: any) => {
        const { field_reference } = resp;
        if (field_reference ===  LEAD_GEN_HEADER_REFERENCE) {
          LeadHeader.push(resp);
        }
      })
      return LeadHeader;
    } 

    export const formatLeadGenInfusion = (response: any) => {
      let LeadInfusionData: any | {}[] = []
      response.map((resp: any, index: any) => {
        const { field_reference } = resp;
        if (field_reference ===  LEAD_GEN_INFUSION_REFERENCE) {
          LeadInfusionData.push(resp);
        }
      })
      return LeadInfusionData;
    }     

    export const formatLeadAddress= (response: any) => {
      let LeadAddress: any | {}[] = []
      response.map((resp: any, index: any) => {
        const { field_reference } = resp;
        if (field_reference ===  LEAD_GEN_CONTENT_REFERENCE) {
          LeadAddress.push(resp);
        }
      })
       return LeadAddress;
    } 

    export const TermsAndConditionsSection = (response: any) => {
      let termsAndConditionsSectionData: any | {}[] = []
      response.map((resp: any, index: any) => {
        const { field_reference } = resp;
        if(field_reference === TERMS_AND_CONDITIONS_SECTION) {
            termsAndConditionsSectionData.push(resp);
        }
        
      })
      return termsAndConditionsSectionData;
    }