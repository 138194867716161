import React from 'react'
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, 
ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import Link from '@mui/material/Link';
import visionImg from '../../../assets/images/about-vision.jpg';
const MoreAboutUs2 = (props:any) => {
    const { data } = props;
   // console.log(data[0].title, "data");  
return (
    <Box className='more-about-us' py={{xs:'48px',md:'96px'}}>
    <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>
        <Box position="relative" zIndex="9">
            <Typography variant="h1" fontWeight="500" color="#ffffff" mb={3} className='section-heading'>
                Get to know more about us
            </Typography>
            <Box className='page-links'>
                <Grid container rowSpacing={{ xs: 2, lg: 5 }} columnSpacing={{ xs: 3, lg: 7 }}>
                    <Grid item xs={12} md={6}>
                        <Link href='/WhoWeAre'>Who We Are</Link>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Link href='/Markets'>Markets</Link>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Link href='/Leadership'>Leadership</Link>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Link href='/Quality'>Quality</Link>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Link href='/Locations'>Locations</Link>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <Link href='/Careers'>Careers</Link>
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <Link href='/OurBoard'>Our Board</Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    </Container>
</Box>
)
}
export default MoreAboutUs2;