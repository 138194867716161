//balloon catherters
import axios from "axios";
import Api from "../../common/APi/api";
import {env_api_url} from '../../common/Constants'
export const OurBoardPageResponse = async(url:string)=>{
const response = await Api.get(url);
console.log("Service:", response);
return response.data;
}

export const fetchOurBoardCardData=async()=>{
    const response=await axios.get(`${env_api_url}bio/v1/biomerics/OurBoard`);
    console.log("Our board API response**********",response)
    return response.data;
   }
   