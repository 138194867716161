

import React, { FC } from 'react';
import Carousel from 'react-material-ui-carousel';
import {
    Container, Box, Typography, Grid, Card, CardContent, Button, List, CardMedia,
    ListItem, ListItemAvatar, ListItemText
} from '@mui/material';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
const NoResultFound = () => {
     return (
        <React.Fragment>
            
                <Box py={8} display="flex" alignItems="center" justifyContent="center">
                    <Box className='' textAlign='center'>
                        <Box className='question-icon'><QuestionMarkOutlinedIcon /></Box>
                        <Typography mb="20px" fontSize='30px' >
                          0 results found, search again.
                        </Typography>
                    </Box>
                </Box>
        </React.Fragment>
    );

};


export default NoResultFound;
