import React, { useState, useEffect } from 'react'
import logo from '../../assets/images/logo.png';
import graphic from '../../assets/images/logo_graphic.png';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
//import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
//import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import MenuTest from '../MainMenus';
//import SidemenuNavigation from './SidebarMenu';
import SearchIcon from '@mui/icons-material/Search';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DrawerComponent from '../Drawer';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { useNavigate, useParams, Router, Route } from 'react-router-dom';
//import {useHistory} from "react-router-dom"
import TextField from "@mui/material/TextField";
import GlobalSearchText from '../../components/sections/GlobalSearch/GlobalSearchText'
import { useLocation } from 'react-router-dom';
import { fetchMenuFromServer } from '../../Service/Header Menu';
import he from 'he';
import { routeMapping } from "../../Information/RouteMapping.json";



const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        width: '341px',
        position: 'relative',
        border: '1px solid #DEDEDF',
        fontSize: 16,
        height: '30px',
        padding: '2px 12px',
        boxSizing: 'border-box',
    },
}));

const aboutSubMenu = [
    "/Quality",
    "/Markets",
    "/Locations",
    "/Leadership",
    "/WhoWeAre",
    "/OurBoard"
]

const Header = () => {

    const pathName = useLocation();
    const currentPage = pathName.pathname;
    const [isActive, setActive] = useState<boolean>(false);
    const [aboutClassName, setAboutClassName] = useState<string>('')
    const [OurValueVlass, setOurValueClass] = useState<string>('')
    const location = useLocation();
    const [news_ResourcesClassName, setNews_ResourcesClassName] = useState<string>("")
    const [contactClassName, setContactClassName] = useState<string>("");
    const [ourValueClassName, setOurValueClassName] = useState<string>("");
    const [careersClassName, setCareersClassName] = useState<string>("")
    const [menuData, setMenuData] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const headerMenuData = fetchMenuFromServer();
        headerMenuData.then((res: any) => {
            setLoading(false);
            const str = res[0].body.replace(/^"(.*)"$/, "$1");
            const removed = str.replace(/(\r\n|\n|\r)/gm, "");
            const parseStr = removed.replace(/[^\x20-\x7E]/gim, "")
            //console.log("Header menu data API res", parseStr)
            setMenuData(JSON.parse(parseStr))

        }).catch((e) =>
            console.log("e", e)
        )
    }, [])
    useEffect(() => {
        // if()

        let value = '';
        let news_resource = "";
        let contact_ClassName = ''
        let ourValue = ''
        let careers_ClassName = ''

        switch (location.pathname) {
            case '/WhoWeAre':
                value = "active";
                break;
            case "/Leadership":
                value = "active";
                break;
            case "/Locations":
                value = "active";
                break;
            case "/Quality":
                value = "active";
                break;
            // case "/Careers":
            //     value="active";
            // break;
            case "/OurBoard":
                value = "active";
                break;


            case "/About":
                // if (location.hash==='#section262'){
                //     ourValue='active'
                //     value="active";
                // }
                if (location.pathname === '/About') {
                    if (location.hash === '#section262') {
                        ourValue = 'active';
                    } else {
                        ourValue = '';
                    }
                } else if (location.pathname === '/Values') {
                    ourValue = 'active';
                }

                value = "active";
                break;

            case "/Careers":
                careers_ClassName = "active"
                break;
            case "/News&Resources":
                news_resource = "active"
                break;
            case "/contact":
                contact_ClassName = "active"
                break;

            default:
                value = '';
                news_resource = "";
                contact_ClassName = ""
                ourValue = '';
                careers_ClassName = ""
                break;
        }
        // setActive(value);

        setAboutClassName(value);
        setCareersClassName(careers_ClassName);
        setNews_ResourcesClassName(news_resource)
        setContactClassName(contact_ClassName)
        setOurValueClassName(ourValue)
        //aboutSubMenu.forEach((s)=>s===location.pathname?setActive(true):setActive(false) )
        setGlobalData("");
    }, [location.pathname])


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [scroll, setScroll] = useState(false)
    const [globalData, setGlobalData] = React.useState("");
    const [routeData, setRouteData] = React.useState("");
    const handleChange = (event: any) => {
        setGlobalData(event.target.value);
    }
    const cleanGlobalseacrhValue = (callbackValue: string) => {
        console.log('callbackValue', callbackValue)
        setGlobalData(callbackValue)
    }

    let navigate = useNavigate();
    let state: any;

    function GetMappedUrl(currentPage: any) {
        let newUrl: any;
        // let routData= routeMapping.map((obj:any) => {
        //     let bigString = obj.key.toString().replace(/%20/g, '');
        //     if(bigString.toLowerCase().indexOf(currentPage.toLowerCase())> -1){
        //        newUrl = obj.redirect_url.replace(/ /g, '-');
        //     }
        // });

        let routData = routeMapping.filter(e => e.key === currentPage).map((obj: any) => {
            let bigString = obj.key.toString().replace(/%20/g, '');
            if (bigString.toLowerCase().includes(currentPage.toLowerCase())) {
                newUrl = obj.redirect_url.replace(/ /g, '-');
            }
        })

        useEffect(() => {
            if (newUrl) {
                return navigate(newUrl)
            }
        }, [newUrl])
    };
    GetMappedUrl(currentPage);


    ////////////-----------------------/////////////
    const handleSearch = (event: any) => {
        event.preventDefault();
        if (globalData.trim().length !== 0) {
            navigate("/SearchResults/" + globalData);
            window.location.reload();
            setGlobalData("");  // Clear the search input after navigating
        }
    };


    function handleKeyDown(event: any) {
        if (event.target.value.trim().length !== 0) {
            if (event.keyCode === 13) {  // 13 is the keycode for Enter
                const value = event.target.value;
                navigate("/SearchResults/" + value);
                window.location.reload();
                setGlobalData("");  // Clear the search input after navigating
            }
        }
    }

    const PassSearchValue = () => {
        let { someValue } = useParams();
        return <div>Value Passed: {someValue}</div>;
    };


    return (
        <>
            <AppBar position="fixed" color="inherit" sx={{ boxShadow: 'none', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>
                    <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                        <Box display="flex" alignSelf="stretch">
                            <RouterLink to="/" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <Box className="logo" display="flex" alignItems="center" height="100%">
                                    <img src={logo} alt="Logo" />
                                </Box>
                            </RouterLink>

                        </Box>

                        {isMobile ? (
                            <DrawerComponent mobileMenu={menuData} />
                        ) : (<Box className="header-navlinks" sx={{ display: { xs: 'block', sm: 'none', md: 'block' } }} >
                            <Box className='top-navs' sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: '22px' }}>
                                <Link sx={{ py: '8px', ml: { xl: '50px', xs: '20px' } }} href="/About" underline="none"
                                    className={aboutClassName} >
                                    ABOUT</Link>

                                <Link sx={{ py: '8px', ml: { xl: '50px', xs: '20px' } }} href="/Careers"
                                    underline="none" className={careersClassName}>CAREERS</Link>

                                <Link onClick={() => setOurValueClassName('active')} sx={{ py: '8px', ml: { xl: '50px', xs: '20px' } }}
                                    href="/Values" underline="none" className={ourValueClassName}
                                > OUR VALUES</Link>
                                <Link sx={{ py: '8px', ml: { xl: '50px', xs: '20px' } }} href="/News&amp;Resources" underline="none"
                                    className={news_ResourcesClassName}>NEWS &amp; RESOURCES</Link>
                                <Link sx={{ py: '8px', ml: { xl: '50px', xs: '20px' } }} href="/contact"
                                    underline="none" className={contactClassName}>CONTACT</Link>
                                <FormControl variant="standard" sx={{ position: 'relative', ml: { xl: '38px', xs: '20px' } }}>
                                    <BootstrapInput id="bootstrap-input" placeholder="Search..." onKeyDown={handleKeyDown} onChange={handleChange} value={globalData} />
                                    <Box component="span" position="absolute" top="3px" right="12px" className="search-icon"
                                        onClick={handleSearch}
                                    >
                                        <SearchIcon />
                                    </Box>
                                </FormControl>
                            </Box>
                            {/* <GlobalSearchText data={globalData}  style={{ display: 'none' }}/> */}
                            {loading ? <></> :
                                <MenuTest menuData={menuData} />
                            }

                        </Box>)}
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    )
}

export default Header