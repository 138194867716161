import React, {useEffect,useState} from 'react'
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, 
ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import {fetchOurValuesCardData} from '../../../Service/OurValues';
const LivingOurValues4 = (props:any) => {
    const { data } = props;
    const [flipCardData,setFlipCardData]=useState([]);

    // useEffect(()=>{
    //     //window.scrollTo(0,0)
    //     setTimeout(()=>{
    //         window.addEventListener("scroll", () => {
    //             document.querySelector('.headerPadding')?.classList.remove('headerPadding');
            
    //           })
    //     },3000)
    //  return()=>{
    //     window.removeEventListener("scroll", () => {
    //         //  document.querySelector('.headerPadding')?.classList.remove('headerPadding');
            
    //         })
    //  }            
    // },[])

    
    useEffect(()=>{
        const apiResponse=fetchOurValuesCardData();
        apiResponse.then((res:any)=>{
        const HTML=res[0].body.replace(/<\/?[^>]+(>|$)/g, "").replace(/\n/g, " ");
        var o = JSON.parse(HTML);
        setFlipCardData(o[0].flip_card_data)
        }).catch((e)=>{
            console.log(e)
        })
        },[])
    const listData = () => {
    return  flipCardData.map((filp_list_data:any)=>{
            function decodeEntity(inputStr:any) {
                var textarea = document.createElement("textarea");
                textarea.innerHTML = inputStr;
                return textarea.value;
            } 
        return (
            <List disablePadding className='flip-box'>
            <ListItem className="flip-card">
                <Box className="flip-card-inner">
                    <Box className="flip-card-front">
                        <Box className="content" display='flex' flexDirection='column' justifyContent='center' alignContent='center' height='100%'>
                            <Box className='icon-box' component='span' mb={4}>
                                <img src={filp_list_data.field_image} alt="" />
                            </Box>
                            <Typography component="p" textAlign='center' fontSize='25px'>{decodeEntity(filp_list_data.title_name)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="flip-card-back">
                        <Box className="content">
                            <Typography component="h4">
                                {decodeEntity(filp_list_data.title_name)}
                                </Typography>
                            <Typography component="p">
                            {decodeEntity(filp_list_data.field_description)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </ListItem>
            
        </List>
        )
      })
      };
      listData(); 
      var bulletsColor = { "--bullet-bg-color": data[0].field_bullets_fgcolor } as React.CSSProperties; 
return (
    <Box pt={{ xs: '50px', md: '80px' }} pb={{ xs: '50px', md: '80px' }} className='living-our-values' id={"section"+(data[0].nid)} >
    <Container maxWidth={false} sx={{ maxWidth: '1340px' }}>
        <Typography variant="h1" fontWeight="500" color="#4A4948" mb={3} textAlign='center' className='section-heading' style={bulletsColor}>
        {!data[0].title ? data[0].title.split("&amp;")[0] : data[0].title}  
        </Typography>
        <Typography component="p" mb={4} textAlign='center' mx='auto' fontSize='25px' maxWidth={{ xs: '100%', lg: '1080px' }} >
          {!data[0].field_description ? data[0].field_description.split("&amp;")[0] : data[0].field_description} 
        </Typography>

        <List disablePadding className='flip-box'>
          {listData()}
        </List>
    </Container>
    </Box>
)
}
export default LivingOurValues4;