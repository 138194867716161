import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import Link from '@mui/material/Link';

import CommonCreativeSolution from '../components/CommonCreativeSolution';

//Api url
import { BLOG } from '../common/APi/apiurl';
import { LeadershipHeroSection, BlogCarsSection, convertResponseToRequiredDs } from '../common/utils/utils';
import { BlogPageResponse, fetchBlogDropdownValue } from '../Service/Blog';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import BlogsPagination from '../components/sections/GlobalSearch/BlogsPagination';
import globalSearchBanner from '../assets/images/global-search-banner.jpg';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import he from 'he';

//Api url
import { env_api_url } from '../common/Constants';
import { Helmet } from 'react-helmet';
const SearchResults = () => {
    const params = useParams()


    const pathName = useLocation();
    const [globalSearchValue, setGlobalSearchValue] = useState(params.searchValue ? params.searchValue : "");
    const currentPage = pathName.pathname;
    const location = useLocation().pathname;
    const [loading, setLoading] = useState<boolean>(true)
    const [pageContent, setContent] = useState<null | any>(null);
    const [HeadingSectionData, setHeadingSection] = useState<null | any>(null);
    const [BlogCardSectionData, setBlogCardSection] = useState<null | any>(null);

    const [commonBlog, setCommonBLogData] = useState<any>(null);//
    const [commonsearchData, setCommonsearchData] = React.useState<any>([]);
    const [searchText, setSearchData] = React.useState<any>([]);
    //const[SearchData, setSearchData]=useState<any>(null);

    const [showSearchResult, setShowSearchResult] = useState(false);
    const [searchResultCount, setSearchResultCount] = useState<string | number>("");
    const [valueOfsearchResult, setValueOfSearchResult] = useState('');
    const [pages, setPages] = useState<number>()

    const [topicDropdownValues, setTopicDropdownValues] = useState([]);
    const [typeDropdownValues, setTypeDropdownValues] = useState([]);
    const [searchHeader, setSearchHeader] = useState([])

    const fetchResponse = async () => {
        // const response = await BlogPageResponse(BLOG);

        const blogGlobalCount = await getGlobalSearchResultBlogPageCount();
        const globalSearchCount = await getGlobalSearchResultPageCount();
        const getDropdwonValue = await fetchBlogDropdownValue()

        const parsedValue = convertResponseToRequiredDs(getDropdwonValue[0].body);

        setTopicDropdownValues(parsedValue.body.topicData)
        setTypeDropdownValues(parsedValue.body.type)
        const totalPage = Math.max(blogGlobalCount, globalSearchCount)

        setPages(Math.ceil(totalPage / 3));

        fetchGlobalSearchBlogsData();
        fetchPageData();
        fetchSearchHeader();

    };

    var searchHeaderName
    async function fetchSearchHeader() {
        try {
            const { data } = await axios.get(`${env_api_url}bio/v1/biomerics/global_search_header`);
            searchHeaderName = data
            setSearchHeader(searchHeaderName)
        } catch (e) {
            console.log(e)
        }
    }

    async function fetchGlobalSearchBlogsData() {
        try {
            const { data } = await axios.get(`${env_api_url}bio/v1/biomerics/blogs_search/?_format=json&&body=${globalSearchValue}&page=${page - 1}`);

            setCommonBLogData(data);

            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    async function fetchPageData() {
        try {
            const { data } = await axios.get(`${env_api_url}bio/v1/biomerics/search/?_format=json&body=${globalSearchValue}&page=${page - 1}`);
            pageData = data;
            setSearchData(pageData);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    const getGlobalSearchResultBlogPageCount = async () => {
        const response = await axios.get(`${env_api_url}bio/v1/biomerics/blogs_search_count/?_format=json&body=${globalSearchValue}`);

        return response.data.length;
    }
    const getGlobalSearchResultPageCount = async () => {

        const response = await axios.get(`${env_api_url}bio/v1/biomerics/search_count/?_format=json&body=${globalSearchValue}`);

        return response.data.length;
    }

    const formatData = () => {
        let newsData;
        let titlesection: any;
        let HeadingSection;
        let BlogCarsSectionData;
        if (pageContent) {
            HeadingSection = LeadershipHeroSection(pageContent);
            BlogCarsSectionData = BlogCarsSection(pageContent);
            setHeadingSection(HeadingSection);
            setBlogCardSection(BlogCarsSectionData);
            setCommonBLogData(BlogCarsSectionData);
           
        }

    }

    useEffect(() => {
        fetchResponse();

    }, [])

    useEffect(() => {
        formatData();

    }, [pageContent])



    const [data, setData] = React.useState<any>([]);
    const [page, setPage] = React.useState(1);
    const handleChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    // var total_items=91;
    // const size = 3
    // var pages=Math.ceil(total_items/size);

    var pageData;


    useEffect(() => {
        if (!globalSearchValue) {
            fetchSearchData('', "", page);
            fetchBlogData('', "", page);
        } else {
            fetchGlobalSearchBlogsData();
            fetchPageData();
        }

    }, [page])






    ///====================Global search and blog from nay where in website  =========================////


    ////====================search section and blog section filter data using three filters==========================//////////


    const [selected, setSelected] = useState('');
    const [topic, setTopic] = useState('');
    const [search, setSearch] = useState('');

    const [isTypeSelected, setTypeSelected] = useState(false);
    const [isTopicSelected, setIsTopicSelected] = useState(false);
    const [typeCount, setTypeCount] = useState<string | number>('');
    const [topicCount, setTopicCount] = useState<string | number>('');

    const getPageCount = async (selectedValue: string, topicValue: string) => {
        const selectedQuery = selected ? selected : selectedValue;
        const topicQuery = topic ? topic : topicValue;

        const response = await axios.get(`${env_api_url}bio/v1/biomerics/blogs_search_count/?_format=json&is_pinned=false&type=${selectedQuery}&topic=${topicQuery}&body=${search}`);
       
        return response.data.length;
    }

    const getSearchResultPageCount = async (selectedValue: string, topicValue: string) => {
        const selectedQuery = selected ? selected : selectedValue;
        const topicQuery = topic ? topic : topicValue;

        const response = await axios.get(`${env_api_url}bio/v1/biomerics/search_count/?_format=json&is_pinned=false&type=${selectedQuery}&topic=${topicQuery}&body=${search}`);

        return response.data.length;
    }

    async function handleTypeChange(e: any) {
        const value = e.target.value;
        setSelected(value);
        setShowSearchResult(false)
        const blogPageCount = await getPageCount(value, "");
        const search_count = await getSearchResultPageCount("", "");
        const totalPage = Math.max(blogPageCount, search_count)
        setPages(Math.ceil(totalPage / 3))
        const searchData = await fetchSearchData(value, "", 1);
        const blogData = await fetchBlogData(value, "", 1);
        setTypeSelected(true)
        setTypeCount(blogData.length + searchData.length)

    }

    async function handleTopicChange(e: any) {
        setGlobalSearchValue("");
        const value = e.target.value;
        setTopic(value);
        setShowSearchResult(false)
        e.preventDefault()
        e.stopPropagation();
        setSearchData('');
        const blogPageCount = await getPageCount("", value);
        const search_count = await getSearchResultPageCount("", value);
        const totalPage = Math.max(blogPageCount, search_count)
        setPages(Math.ceil(totalPage / 3))

        const searchData = await fetchSearchData("", value, 1);

        const blogData = await fetchBlogData("", value, 1);
        setIsTopicSelected(true)
        setTopicCount(blogData.length + searchData.length)
        setPage(1)
    }

    const handelSearchIcon = async () => {
        setGlobalSearchValue("");
        const searchData = await fetchSearchData("", "", 1);
        const blogData = await fetchBlogData("", "", 1);
        const blogPageCount = await getPageCount("", "");
        const search_count = await getSearchResultPageCount("", "");
        const totalPage = Math.max(blogPageCount, search_count)
        setPages(Math.ceil(totalPage / 3))
        setShowSearchResult(true)
        setSearchResultCount(blogData.length + searchData.length)
        setValueOfSearchResult(search)
        setPage(1)
    }

    const handleSearch = async (e: any) => {
        setGlobalSearchValue("");
        const value = e.target.value;
        if (e.keyCode === 13) {

            // setSearch(value);
            e.preventDefault()
            e.stopPropagation();
            setSearchData('');
            setPage(1)
            const searchData = await fetchSearchData("", "", 1);
            const blogData = await fetchBlogData("", "", 1);
            const blogPageCount = await getPageCount("", "");
            const search_count = await getSearchResultPageCount("", "");
            const totalPage = Math.max(blogPageCount, search_count)
            setPages(Math.ceil(totalPage / 3))
            setShowSearchResult(true)
            setSearchResultCount(blogData.length + searchData.length)
            setValueOfSearchResult(value)

        }
        //setSearch(value);
    }

    async function fetchSearchData(selectedValue: string, topicValue: string, pageQuery: number) {
        // setShowSearchResult(true)
        const selectedQuery = selected ? selected : selectedValue
        const topicQuery = topic ? topic : topicValue;
        try {
            const { data } = await axios.get(`${env_api_url}bio/v1/biomerics/search/?_format=json&type=${selectedQuery}&topic=${topicQuery}&body=${search}&page=${pageQuery - 1}`);
            setCommonsearchData(data);
            setSearchData('');
            setLoading(false);
            return data;
        } catch (e) {
            console.log(e);
        }

    }

    async function fetchBlogData(selectedValue: string, topicValue: string, pageQuery: number) {
        //setShowSearchResult(true)

        const selectedQuery = selected ? selected : selectedValue
        const topicQuery = topic ? topic : topicValue;
        try {
            const { data } = await axios.get(`${env_api_url}bio/v1/biomerics/blogs_search/?_format=json&is_pinned=false&type=${selectedQuery}&topic=${topicQuery}&body=${search}&page=${pageQuery - 1}`);
            //blogData = data.slice(0, limit);
            setCommonBLogData(data);
            setLoading(false);
            return data;
        } catch (e) {
            console.log(e);
        }
    }

    const showResultMessage = () => {
        if (showSearchResult) {
            return `${searchResultCount} Results for “${valueOfsearchResult}”`
        } else if (isTypeSelected && isTopicSelected) {
            return `${typeCount} Results for “${selected}”  “${topic}”`;
        }
        else if (isTypeSelected) {
            return `${typeCount} Results for “${selected}”`;
        } else if (isTopicSelected) {
            return `${topicCount} Results for “${topic}”`;
        } else if (showSearchResult || isTopicSelected || isTypeSelected) {
            return `${searchResultCount} Results for “${valueOfsearchResult}”   “${selected}”  “${topic}”`
        }
        return `${counts} Results for “${globalSearchValue}”`;
    }


    var blogData;


    // useEffect(() => {
    //     //setShowSearchResult(false)
    //     const fetchData=async()=>{
    //     const searchData= await fetchSearchData();
    //     const blogData=await fetchBlogData();
    //     console.log("searchData", searchData)
    //     console.log("blogData", blogData)
    //    // console.log()
    // //    setShowSearchResult(true)
    //    setSearchResultCount(blogData.length+searchData.length)
    //     }
    //     fetchData();
    // }, [selected, topic]);










    //=======================end=========================//

    //  console.log(commonsearchData,"commonsearchData");

    function decodeEntity(inputStr: any) {
        var textarea = document.createElement("textarea");
        textarea.innerHTML = inputStr;
        return textarea.value;
    }



    function groupBy(arrayObjects: any, key: any) {
        if (arrayObjects.length > 0) {
            return arrayObjects.reduce(function (result: any, currentObject: any) {
                const val = currentObject[key]
                result[val] = result[val] || []
                result[val].push(currentObject)
                return result
            }, [])
        }
        return [];
    }
    let searchTextCategory = groupBy(searchText, 'field_category')
    let commonSearchCategory = groupBy(commonsearchData, 'field_category')

    let seachAndArrayList = searchText ? searchTextCategory :
        commonSearchCategory;

    const counts = searchText.length ? searchText.length + (commonBlog && commonBlog.length)
        : commonsearchData.length + (commonBlog && commonBlog.length);
    const dropDownSearch = selected !== "" ? selected :
        topic !== "" ? topic :
            search !== "" ? search : '';
    const GlobalSearch = () => {


        return Object.keys(seachAndArrayList).map(category =>
            <React.Fragment>
                <Box className='result-box' mb='110px'>
                    <Typography variant="h5" fontWeight="700" fontSize='35px' color="#000" className='search-section-heading' style={{ textTransform: 'uppercase' }}>
                        <div dangerouslySetInnerHTML={{ __html: category.toString() }} />
                    </Typography>

                    {
                        seachAndArrayList[category].map(function (item: any, key: any) {

                            const field_description = decodeEntity(item.field_description);
                            let currentURl = window.location.href.split('SearchResults');
                            let sub_cat = decodeEntity(item.field_sub_category)
                            //href={currentURl[0]+`${item.field_sub_category.trim().split("amp;").join('') +"#section"+(item.nid)}`} 
                            const Description = [
                                {
                                    field_description: field_description
                                }
                            ];
                          
                            return (
                                <Box mt="56px" >

                                    <Link
                                        href={`${currentURl[0]}${sub_cat.replace(/ /g, '')}#section${item.nid}`}
                                        fontWeight="500"
                                        fontSize='35px'
                                        color="#29A7DF"
                                        mb="40px"
                                        display='block'
                                    >
                                        {item.field_sub_category ?
                                            item.field_sub_category.split('amp;') : ''} - {item.title ?
                                                item.title.split('amp;') : ''}
                                    </Link>

                                    {Description.map((v) => (
                                        <Typography fontSize='26px' color="#7A7A7A"  >
                                            <div dangerouslySetInnerHTML={{ __html: v.field_description }} />
                                        </Typography>
                                    ))}
                                    <Typography fontSize='26px' color="#7A7A7A"   >
                                        <div dangerouslySetInnerHTML={{ __html: item.body }} />
                                    </Typography>
                                </Box>
                            )
                        })
                    }
                </Box>
            </React.Fragment>
        )


    };
    function showMessage(count: number | string, searchText: string, selected: string, type: string) {
        if (type || selected || searchText) {
            if (type && selected && searchText) {
                return `${count} Results for “${type}” “${selected}” “${searchText}”`;
            } else if (type && searchText) {
                return `${count} Results for “${type}”  “${searchText}”`;
            } else if (type && selected) {
                return `${count} Results for “${type}” “${selected}” `;
            } else if (type) {
                return `${count} Results for “${type}”  `;
            } else if (selected) {
                return `${count} Results for “${selected}”  `;
            } else if (searchText) {
                return `${count} Results for  “${searchText}” `;
            }

            return `${count} Results for “${searchText}”`;
        }
    }

    return (
        <>
            {loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
                <React.Fragment>
                    {/* <Header /> */}
                    {searchHeader.map((searchData: any) => {
                        return (
                            <>
                                {searchData.field_seo_title != undefined ?
                                    <Helmet>
                                        <meta charSet="utf-8" />
                                        <title> {!searchData.field_seo_title[0] ? searchData.field_seo_title.split("&amp;")[0] : searchData.field_seo_title.split("amp;")[0]}</title>
                                        <meta name="description" content={searchData.field_seo_description}></meta>
                                        <meta name="keywords" content={searchData.field_seo_keywords}></meta>
                                        <meta property='keywords' content={searchData.field_seo_keywords}></meta>
                                        {/*OG:TAGS */}
                                        <meta property="og:title" content={!searchData.field_seo_title[0] ? searchData.field_seo_title.split("&amp; ")[0] : searchData.field_seo_title.split("amp; ")[0]} />
                                        <meta property="og:description" content={searchData.field_seo_description} />
                                        <meta property="og:image" content={searchData.field_seo_image} />
                                    </Helmet>
                                    : ""
                                }
                            </>
                        )
                    })}
                    <Box className="hero-section search-result" display="flex" justifyContent="space-between" alignItems='center' pt='0'>
                        <img src={globalSearchBanner} alt="" />
                        <Container maxWidth={false} sx={{ maxWidth: '1132px' }}>
                            <Box position="relative" zIndex="9" >
                                <Typography variant="h3" textAlign="center" fontSize={{ xs: '28px', md: '55px' }} fontWeight="700" mb="30px">What are you looking for?</Typography>
                                <Box className='search-frm'>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={4}>
                                            <FormControl variant="filled" className='w-100 filter-dropdown'>
                                                <InputLabel id="demo-simple-select-filled-label">TYPE</InputLabel>
                                                <Select labelId="demo-simple-select-filled-label" id="demo-simple-select-filled"
                                                    value={selected}
                                                    onChange={handleTypeChange}>
                                                    {/* {typeData()} */}
                                                    {typeDropdownValues.map((topic: string, index: number) => {
                                                        return <MenuItem value={he.decode(topic)} key={index}>{he.decode(topic)}</MenuItem>
                                                    })}

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl variant="filled" className='w-100 filter-dropdown' >
                                                <InputLabel id="demo-simple-select-filled-label">TOPIC</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled" onChange={handleTopicChange} value={topic} >

                                                    {topicDropdownValues.map((topic: string, index: number) => {
                                                        return <MenuItem value={he.decode(topic)} key={index}>{he.decode(topic)}</MenuItem>
                                                    })}



                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box position='relative'>
                                                <TextField id="filled-basic" label="SEARCH" variant="filled" className='search-input' fullWidth={true}
                                                    onChange={(event) => {
                                                        setShowSearchResult(true)
                                                        setSearch(event.target.value)
                                                    }} name={search} onKeyDown={handleSearch} />
                                                <Box component="span" position="absolute" top="3px" right="12px"
                                                    className="search-icon"><SearchIcon onClick={handelSearchIcon} />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                    <Box className='search-content' pt={10} >
                        <Container maxWidth={false} sx={{ maxWidth: '1380px' }}>
                            <Typography variant="h5" fontWeight="500" fontSize='25px' color="#000" mb="67px">
                                {/* {showSearchResult?`${searchResultCount} Results for “${valueOfsearchResult}” ${topic} ${selected}`
                         :`${counts} Results for “${searchValue}” 
                         `}  */}


                                {
                                    showResultMessage()
                                }
                            </Typography>
                            {GlobalSearch()}
                        </Container>
                    </Box>
                    <Box className='search-blog' mt={5}>
                        <Container maxWidth={false} sx={{ maxWidth: '1380px' }} >
                            <Typography variant="h4"
                                fontWeight="500"
                                color="#4A4948"
                                mb="40px !important"
                                className='global-search-section-heading'
                                style={{ textTransform: 'uppercase' }}>
                                News &amp; resources
                            </Typography>
                            <Grid container spacing={4}>
                                <BlogsPagination data={commonBlog} currentPage={currentPage} />
                            </Grid>
                            <Box display='flex' justifyContent='center' mt={10} mb={7} className='pagination'>
                                <Stack spacing={2}>
                                    <Pagination count={pages} page={page}
                                        onChange={handleChange} color="primary" />
                                </Stack>
                            </Box>
                        </Container>
                    </Box>

                    <CommonCreativeSolution />
                    {/* <Footer /> */}
                </React.Fragment >
            }
        </>
    );
}
export default SearchResults;

// className={commonBlog && commonBlog.length < 3 ? "desabled":''}
