import React, {FC} from 'react';
import Carousel from 'react-material-ui-carousel';
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, 
ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import AboutUsImg from '../../../assets/images/About-us-banner.png';
import {Helmet} from 'react-helmet';
// const BannerSection = () => {
// return (
// <Box className="hero-section about-us" display="flex" justifyContent="space-between" flexDirection="column" py={8}>
// <img src={AboutUsImg} alt="" />
// <Container maxWidth={false} sx={{
//     display: 'flex', justifyContent: 'center',
//     flexDirection: 'column', maxWidth: '1736px'
// }}>
//     <Box position="relative" zIndex="9" mt={{ xs: '40px', lg: '136px' }}>
//         <Typography variant="h1" fontSize="88px" fontWeight="600">ABOUT</Typography>
//         <Typography variant="h1" fontSize="65px"> 30 years of trusted partnerships</Typography>
//     </Box>
// </Container>
// </Box>)
// }

interface HeroSection {
    data: {
        field_description: string,
        nid?: string
        title?: string
        field_seo_title?:string 
        field_seo_description?:string 
        field_seo_image?:string
        field_seo_keywords?:string
    }[]
};
const BannerSection: FC<HeroSection> = ({ data }) => {
    const data1 = [
        {
            name: 'Engineering Innovative Solutions',
            description: "Biomerics is a medical device contract manufacturer that anticipates the complex demands of bringing interventional components and devices to life. As a trusted partner to OEMs, we proactively engineer creative customer solutions.",
            buttonText: "Discover Biomerics"
        },
        {
            name: 'John Doe',
            description: 'Author',
            buttonText: "Discover Biomerics"
        },
        {
            name: 'Pitsu Coma',
            description: 'Math Student',
            buttonText: "Discover Biomerics"
        },
    ];
    return (
      <
        >
            {data?.map((item: any, i: number) => {
                return <Item key={i} {...item} />
            })}
        </>
    );

}

const Item = (props: any) => {
    const { title, field_description, field_button_text, field_image_url_1,field_seo_title, field_seo_description,field_seo_image,field_seo_keywords } = props
    return (
        <React.Fragment>
            <Box className="hero-section about-us" display="flex" justifyContent="space-between" flexDirection="column" py={8}>
                <img src={field_image_url_1} alt="" />
                {field_seo_title != undefined ?
                <Helmet>
                        <meta charSet="utf-8" />
                        <title> {!field_seo_title[0] ? field_seo_title.split("&amp;")[0] : field_seo_title.split("amp;")[0]}</title>
                        <meta name="description" content={field_seo_description}></meta>
                        <meta name="keywords" content={field_seo_keywords}></meta>
                        <meta property='keywords' content={field_seo_keywords}></meta>
                        {/*OG:TAGS */}
                        <meta property="og:title" content={!field_seo_title[0] ? field_seo_title.split("&amp; ")[0] : field_seo_title.split("amp; ")[0]}/>
                        <meta property="og:description" content={field_seo_description}/>
                        <meta property="og:image" content={field_seo_image}/>
                 </Helmet> :""}
                <Container maxWidth={false} sx={{
                    display: 'flex', justifyContent: 'center',
                    flexDirection: 'column', maxWidth: '1736px'
                }}>
                    <Box position="relative" zIndex="9" mt={{ xs: '0px', lg: '136px' }}>
                        <Typography variant="h1" fontSize="88px" fontWeight="600">{!title[0] ? title.split("&amp;")[0] : title.split("amp;")[0]}
                        </Typography>
                        <Typography variant="h1" fontSize="65px" fontWeight="400">  {!field_description.split("&amp;") ? 
                            field_description.split("&amp;") : field_description.split("amp;")}</Typography>
                    </Box>
                </Container>
            </Box>
        </React.Fragment>
    );
};


export default BannerSection;