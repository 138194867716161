import React, { FC } from 'react'
import { Box, Container, Typography, Button } from '@mui/material'
import creativeSol from '../assets/images/creative-solutions.jpg';
interface IData {
  title: string,
  // imgUrl:string,
  field_creative_solutions_for_tou: string,
  field_button_text: string,
  field_image_url_1: string

}
const CommonCreativeSol: FC<IData> = ({ title, field_creative_solutions_for_tou, field_button_text, field_image_url_1 }) => {
  var bgColor = { "--bg-color": field_image_url_1 } as React.CSSProperties;
  return (
    <Box mt={{ xs: '30px', md: '112px' }}>
      <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>
        <Box className="creative-solutions" style={{ backgroundImage: `url(${field_image_url_1})` }} display="flex" flexDirection="column"
          //style={{backgroundImage:'url:("../../assets/images/creative-solutions.jpg")'}}
          alignItems="center" justifyContent="center" color="#ffffff" textAlign="center">
          {/* <img src={field_image_url_1!=="" ? field_image_url_1: ''} alt="" width="100%"/> */}
          <Typography variant="h4" fontSize="42px" maxWidth='954px' fontWeight="500" mb={4}>
            {title}
          </Typography>
          <Typography fontSize="24px" maxWidth='869px'>
            {field_creative_solutions_for_tou}
          </Typography>
          <Box textAlign="center" mt={3}>
            <Button className='btn-blue' sx={{ minWidth: '218px' }} variant="contained" href='/Rfq'>{field_button_text.replace("&#039;", "'")}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default CommonCreativeSol
