
import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material';
import { privacyPolicyDetail } from '../common/utils/utils';
import { PrivacyPolicyPageResponse } from '../Service/PrivacyPolicy';
import { PRIVACY_POLICY } from '../common/APi/apiurl';
import AboutPrivacyPolicy from './sections/PrivacyPolicySubSections/AboutPrivacyPolicy'
const PrivacyPolicy = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [pageContent, setPageContent] = useState<null | any>(null);
    const [privacyPolicySectionData, setPrivacyPolicySectionData] = useState<null | any>(null);
    const fetchResponse = async () => {
        const res = await PrivacyPolicyPageResponse(PRIVACY_POLICY);
       
        setPageContent(res)
        setLoading(false);
       
    };
    const formatData = () => {
        let newsData;
        let titlesection: any;
         let privacyPolicySectionData;
        let newsContent;
        if (pageContent){
            privacyPolicySectionData = privacyPolicyDetail(pageContent);
            setPrivacyPolicySectionData(privacyPolicySectionData);
            
        }
    };
    useEffect(() => {
        fetchResponse();
    
      }, []);
      useEffect(() => {
        formatData();
      }, [pageContent])
    
    return (<>
        <React.Fragment>
             {loading ? <>Loading.... </> :
            <AboutPrivacyPolicy  data={privacyPolicySectionData}/>
        } 
        </React.Fragment>
        </>)

}
export default PrivacyPolicy;

