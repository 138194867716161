import React from 'react'
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, 
ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import singleImg from '../../../assets/images/about-img.jpg';
const SingleImageSection5 = (props:any) => {
    const { data } = props;
return (
    <Box className='single-image'>
    <img src={singleImg} alt="" width="100%" />
</Box>
)
}
export default SingleImageSection5;