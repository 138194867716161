import React, {useEffect,useState} from 'react'
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, 
ListItem, ListItemAvatar, ListItemText, Link } from '@mui/material';
import aboutEnggCompany from '../../../assets/images/about-engineer-company.jpg';
import {Link as RouteLink} from 'react-router-dom';
import axios from 'axios';
import he from 'he';
const TopSubMenuAboutUs = (props:any) => {
    const { menuData } = props;
    const [nestedData, setNestedData] = useState<any>(null);
    const submenu_data=[
        {
            "nid": "2",
            "title":"Explore more About Biomerics",
            "field_reference": "about_sub_menu_data",
            "about_submenu_data":
                [
                    {
                        "sid": "0",
                        "title_name": "WHO WE ARE",
                        "link":"/WhoWeAre"
                        
                    },
                    {
                        "sid": "1",
                        "title_name": "LEADERSHIP",
                         "link":"/Leadership"
                     },
                     
                     {
                      "sid": "2",
                      "title_name": "OUR BOARD",
                       "link":"/OurBoard"
                   },
                    {
                       "sid": "3",
                       "title_name": "LOCATIONS",
                        "link":"/Locations",
                    },
                    // {
                    //     "sid": "3",
                    //     "title_name": "MARKETS",
                    //      "link":"/Markets",
                    //  },
                     {
                        "sid": "4",
                        "title_name": "QUALITY",
                         "link":"/Quality"
                     },
                    //  {
                    //     "sid": "5",
                    //     "title_name": "CAREERS",
                    //      "link":"/Careers"
                    //  }
                ]
        },
    ];

   
        
    const about_data = submenu_data[0].about_submenu_data;
    const listData = () => {
        return  about_data.map((submenu_list:any)=>{ 
                return (
                <ListItem className={props.currentPage===submenu_list.link?"active":''}>
                   <Link  component={RouteLink}  to={submenu_list.link}>{submenu_list.title_name}</Link>
                 </ListItem>
                )
        })
    };
        
return (
    <Box className='sub-nav top'>
         <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>
           {/* <ClickAwayListener onClickAway={handleClose}>
             <div> */}
              <List >
                 {listData()}
              </List>
             {/* </div>
           </ClickAwayListener> */}                                                                     
        </Container>
    </Box>

)
}
export default TopSubMenuAboutUs;
