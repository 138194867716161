import React, {FC} from 'react';
import Carousel from 'react-material-ui-carousel';
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, 
ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import OurBoardImg from '../../../assets/images/Board_01_header.png';
import {Helmet} from 'react-helmet';
interface HeroSection {
    data: {
        field_description: string,
        nid?: string
        title?: string
        field_description3:string
        field_seo_title?:string 
        field_seo_description?:string 
        field_seo_image?:string
        field_seo_keywords?:string
    }[]
};
const BannerSection: FC<HeroSection> = ({ data }) => {
   
    return (
        <>
            {data?.map((item: any, i: number) => {
                return <Item key={i} {...item} />
            })}
        </>
    );

}

const Item = (props: any) => {
    console.log("props in banner section ourboard:",props)
    const { title, field_description, field_button_text, fieldImage,field_description3,field_seo_title, field_seo_description,field_seo_image, field_seo_keywords } = props
    return (
        <Box className="hero-section leadership" display="flex" justifyContent="space-between" flexDirection="column" py={8}>
                <img src={OurBoardImg} alt="" />
                {field_seo_title != undefined ?
               <Helmet>
                        <meta charSet="utf-8" />
                         <title> {!field_seo_title[0] ? field_seo_title.split("&amp;")[0] : field_seo_title.split("amp;")[0]}</title>
                         <meta name="description" content={field_seo_description}></meta>
                         <meta name="keywords" content={field_seo_keywords}></meta>
                         <meta property='keywords' content={field_seo_keywords}></meta>
                         <meta property="og:title" content={!field_seo_title[0] ? field_seo_title.split("&amp; ")[0] : field_seo_title.split("amp; ")[0]} />
                         <meta property="og:description" content={field_seo_description}/>
                        <meta property="og:image" content={field_seo_image}/> 
                 </Helmet> 
                 :""}
                <Container maxWidth={false} sx={{
                    display: 'flex', justifyContent: 'center',
                    flexDirection: 'column', maxWidth: '1736px'
                }}>
                    <Box position="relative" zIndex="9">
                    <Box position="relative" zIndex="9" >
                    <Typography variant="h1" fontSize="50px"  mb={{xs:'10px', md:'50px',lg:'104px'}}>{!title.split("amp;")[0]?title.split("amp;")[0]:title.split("amp;")[0]}</Typography>
                        <Typography variant="h1" fontSize="88px" fontWeight="700" sx={{textTransform:'uppercase'}}>{!field_description.split("&amp;")[0]?field_description.split("&amp;")[0]:field_description.split("amp;")[0]}</Typography>
                        <Typography variant="h1" fontSize="65px" mb={3}>{!field_description3.split("&amp;")[0]?field_description3.split("&amp;")[0]:field_description3.split("amp;")[0]}</Typography>
                    </Box>
                    </Box>
                </Container>
            </Box>
    );
};


export default BannerSection;