import React, { FC } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Button, Box, Typography, Container } from '@mui/material';
//import { formatHeroSectionData, formatNewsCardSectionData } from '../Config';
import engineerComponent from '../../../assets/images/engineer-component.jpg';
import { useLocation } from 'react-router-dom'
import {Helmet} from 'react-helmet';
interface HeroSection {
    data: {
        field_description: string,
        nid?: string
        title?: string
        field_button_url: string
        field_title1: string
        field_image_url_1: string
        field_og_description?:string
        field_seo_title?:string 
        field_seo_description?:string 
        field_og_image?:string 
        field_seo_image?:string
        field_seo_keywords?:string

    }[]
};
const BannerSection: FC<HeroSection> = ({ data }) => {
   
//console.log(pathName,"location in level  page ")
    const data1 = [
        {
            name: 'Engineering Innovative Solutions',
            description: "Biomerics is a medical device contract manufacturer that anticipates the complex demands of bringing interventional components and devices to life. As a trusted partner to OEMs, we proactively engineer creative customer solutions.",
            buttonText: "Discover Biomerics"
        },
        {
            name: 'John Doe',
            description: 'Author',
            buttonText: "Discover Biomerics"
        },
        {
            name: 'Pitsu Coma',
            description: 'Math Student',
            buttonText: "Discover Biomerics"
        },
    ];
    return (
        <
            >
            {data?.map((item: any, i: number) => {
                return <Item key={i} {...item} />
            })}
        </>
    );

}



const Item = (props: any) => {
    const pathName = useLocation();
    const currentPage = pathName.pathname;
    const location=useLocation().pathname;
    const { title, field_description, field_button_text, fieldImage, field_button_url, field_title1, field_image_url_1,
        field_og_description, field_seo_title, field_seo_description,field_seo_image, field_seo_keywords } = props
        //console.log(props, "hhhh")
    return (
        <React.Fragment>
            <Box className={"hero-section engineer-component " + (field_button_text !== "" ? 'banner-with-button' : '')} display="flex" justifyContent="space-between" flexDirection="column" py={{md:"40px",lg:"64px"}}>
                <img src={field_image_url_1} alt="" />
                {field_seo_title != undefined ?
               <Helmet>
                        <meta charSet="utf-8" />
                         <title> {!field_seo_title[0] ? field_seo_title.split("&amp;")[0] : field_seo_title.split("amp;")[0]}</title>
                         <meta name="description" content={field_seo_description}></meta>
                         <meta name="keywords" content={field_seo_keywords}></meta>
                         <meta property='keywords' content={field_seo_keywords}></meta>
                         
                         <meta property="og:title" content={!field_seo_title[0] ? field_seo_title.split("&amp; ")[0] : field_seo_title.split("amp; ")[0]} />
                         <meta property="og:description" content={field_seo_description}/>
                        <meta property="og:image" content={field_seo_image}/> 
                 </Helmet> 
                 :""}
                <Container maxWidth={false} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', maxWidth: '1736px' }}>
                    <Box position="relative" mt={{ sx: '0px', lg: '100px' }} zIndex="9">
                        <Typography variant="h1" fontSize="88px" lineHeight='1'>
                            {!title[0] ? title.split("&amp;")[0] : title.split("amp;")[0]}
                        </Typography>
                        <Typography variant="h1" fontSize="88px" fontWeight="600" >
                            {!title[1] ? title.split("&amp;")[1] : title.split("amp;")[1]}
                        </Typography>
                        {field_title1 !== "" ?
                            <Typography variant="h1" fontSize="88px" fontWeight="600" lineHeight='1'>
                                {field_title1 !== "" ? field_title1.split("amp;") : ''}
                            </Typography> : ''}
                        {field_button_text !== "" ?
                            <Box mt={3}>
                                <Button variant="contained" className='btn-primary' sx={{ px: '24px' }} 
                                    href={field_button_url}>{field_button_text?field_button_text:''}
                                </Button>
                            </Box> : ""
                        }
                    </Box>
                </Container>
            </Box>
        </React.Fragment>
    );
};


export default BannerSection;
