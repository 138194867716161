import axios from 'axios';
import {env_api_url} from '../../common/Constants'
export const fetchMenuFromServer = async () => {
  const response = await axios.get(`${env_api_url}bio/v1/biomerics/menuList`);  
  return response.data;
}


export const fetchFooterMenuFromServer = async () => {
  const response = await axios.get(`${env_api_url}bio/v1/biomerics/footerMenu`);  
  return response.data;
}

