//balloon catherters
import axios from "axios";
import Api from "../../common/APi/api";
import {env_api_url} from '../../common/Constants'


export const fetchL3MetaData=async()=>{
 const response=await axios.get(`${env_api_url}bio/v2/biomerics/l3-meta-data`);
 console.log("response in L3:",response)
 return response.data;
}

