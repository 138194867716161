import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useLocation } from 'react-router-dom';
import { Grid, Button, } from '@mui/material';
//import Footer from './Footer';
import CommonCreativeSolution from '../components/CommonCreativeSolution';
import { BlogPageResponse, fetchBlogDropdownValue } from '../Service/Blog';
import { BlogHeaderPageResponse } from '../Service/BlogHeader';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import newsImg from '../assets/images/newsImg.jpg'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import CommonBlogs from '../components/sections/NewsAndSearch/CommonBlogs'
import { BLOG, BLOG_PINNED } from '../common/APi/apiurl';
import { BlogHeroSection, BlogCarsSection, GetToKnoewSection, PinnedBlogSection, convertResponseToRequiredDs } from '../common/utils/utils';
import Header from '../components/HeaderSections/Header';
import BannerHeader from '../components/BannerHeader';
import GetToKnowUs from '../components/sections/NewsAndSearch/GetToKnowUs';
import PinnedBlog from '../components/sections/NewsAndSearch/PinnedBlog';
import NoResultFound from '../components/sections/NewsAndSearch/NoResultFound';
import axios from 'axios';
import blogDropdownData from '../Information/blogDropdown.json';
import he from 'he';
import { postRfqFormData } from '../Service/Rfq';
import {env_api_url} from '../common/Constants';
import TopSubMenuNewsAndResource from './sections/ServiceDetailsLevel2/TopSubMenuNewsAndResource';
interface userContactInformation{
    email:string;
}
const  initial_value:userContactInformation={
  email:'',
}
interface ErrorMessage{
  email:string
}
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: '30px',
    },

    '& .MuiInputBase-input': {
        width: '100%',
        borderRadius: 0,
        position: 'relative',
        backgroundColor: 'transparent',
        border: '1px solid #FFFFFF',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
        fontSize: 16,
        padding: '11px 14px',
        color: '#fff',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
    },
}));
// console.log(BLOG, "BLOG")


const Blog = () => {
    let pageURL;



    const pathName = useLocation();
    const currentPage1 = pathName.pathname;
    const location = useLocation().pathname;
    const [loading, setLoading] = useState<boolean>(true)
    const [pageContent, setContent] = useState<null | any>(null);
    const [HeadingSectionData, setHeadingSection] = useState<null | any>(null);
    const [BlogCarsSectionData, setBlogCardSection] = useState<null | any>(null);
    const [GetToKnoewSectionData, setGetToKnoewSection] = useState<null | any>(null);
    //  const [PinnedBlogSectionData, setPinnedBlogSectionData] = useState<null | any>(null);

    const [commonBlog, setCommonBLogData] = useState<any>([]);//
    const [pinnedBlog, setCommonPinnedBlogSectionData] = useState<any>(null);
    //console.log(commonBlog, "jjj")

    //const [BlogsPaginationData,setBlogPaginationData]=useState<[]>([]);
    const [pages, setPages] = useState<number>();
    const [page, setPage] = React.useState(1);
    const getDefaultPageCount = async () => {
        
        const response = await axios.get(`${env_api_url}bio/v1/biomerics/blogs_count/?_format=json`);
        return response.data.length;
    }
    const defaultPageUrl = `/bio/v1/biomerics/blogs?_format=json&page=${page - 1}&is_pinned=false`;
    const pinnedBlogUrl = `${env_api_url}bio/v1/biomerics/blogs?_format=json&is_pinned=true`

    const [topicDropdownValues, setTopicDropdownValues] = useState([]);
    const [typeDropdownValues, setTypeDropdownValues] = useState([]);





    const fetchResponse = async () => {
        var response: any | {}[] = [];
        var response1: any | {}[] = [];
        var response2: any | {}[] = [];
        const defaultPageCount = await getDefaultPageCount();
        response1 = await BlogPageResponse(defaultPageUrl);
        //response2 = await BlogPageResponse(BLOG_PINNED);
        response2 = await axios.get(pinnedBlogUrl);
        const getDropdwonValue = await fetchBlogDropdownValue()

        const parsedValue = convertResponseToRequiredDs(getDropdwonValue[0].body);

        setTopicDropdownValues(parsedValue.body.topicData)
        setTypeDropdownValues(parsedValue.body.type)

        if (response2) {
            response = response1.concat(response2);
        } else {
            response = response1;
        }


        setPages(Math.ceil(defaultPageCount / 9))
        setContent(response)
        setCommonBLogData(response1);
        setCommonPinnedBlogSectionData(response2.data)
        setLoading(false);
    };



    useEffect(() => {

        fetchResponse();
    }, [location])
    useEffect(() => {
        window.scrollTo(0, 0)
        //formatData();

    }, [pageContent])




    // useEffect(()=>{
    // window.scrollTo(0,0)
    // },[commonBlog,pinnedBlog])



    //=========================PAGINATION===========================================================//
    const [totalLength, setTotalLength] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = React.useState<any>([]);
    const [count, setCount] = useState(null);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);


    const handleChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);


    };
    var total_items = 91;

    //var pages = Math.ceil(total_items / 9);
    //console.log(page, "ddddd");
    var pageData;


    useEffect(() => {
        const nextPageDataContent = async () => {
            if (!selected && !search && !topic) {
                const response1 = await BlogPageResponse(defaultPageUrl);
                const response2 = await axios.get(pinnedBlogUrl);
                setCommonBLogData(response1);
                setCommonPinnedBlogSectionData(response2.data)
                setLoading(false);


            } else {
                await fetchFilterData("", "", page);
            }

        }

        nextPageDataContent();

    }, [page])


    // =========================DropDown===========================================================//

    const type = [
        {
            "title": "TOPIC",
            "body": "Press Release\n\nMaterials &amp; Compounding\n\nInjection Molding\n\nFinished Devices\n\
             nLaser Processing\n\nQuality\n\nComplex Extrusion\n\nAdv. Cath &amp; Steerables\n\n\"All of CoEs\"\n\nDesign Services",
        }
    ];
    const [selected, setSelected] = useState('');
    const [topic, setTopic] = useState('');
    const [search, setSearch] = useState('');

    const [valueData, setValueData] = useState(1);
    const [dropdown, setdropdown] = useState<any>([]);
    const typeData = () => {
        var typeList = type[0].body.split("\n\n");
        return typeList.map((tl: any) => {
            return (
                <MenuItem value={tl}>  <div dangerouslySetInnerHTML={{ __html: tl }} />
                </MenuItem>
            )

        })
    };


    useEffect(() => {
        const fetch = async () => {
            const pageCount = await getPageCount("", "");
            setPages(Math.ceil(pageCount.length / 9))
            await fetchFilterData("", "", 1);
            await fetchPinnedData("", "", 1);
        }
        fetch();

    }, [selected, topic])
    async function handleTypeChange(e: any) {
        const value = e.target.value;
        setSelected(value);
        setPage(1)

    }

    async function handleTopicChange(e: any) {
        const value = e.target.value;
        setTopic(value);

    }


    async function fetchFilterData(selectedValue: string, topicValue: string, pageQuery: number) {
        const selectedQuery = selected ? selected : selectedValue
        const topicQuery = topic ? topic : topicValue;

        try {
            const { data } = await axios.get(`${env_api_url}bio/v1/biomerics/blogs/?_format=json&is_pinned=false&type=${selectedQuery}&topic=${topicQuery}&body=${search}&page=${pageQuery - 1}`);
            setCommonBLogData(data);
            setLoading(false);
            return data;
        } catch (e) {
            console.log(e);
        }
    }
    async function fetchPinnedData(selectedValue: string, topicValue: string, pageQuery: number) {
        const selectedQuery = selected ? selected : selectedValue
        const topicQuery = topic ? topic : topicValue;
        try {
            const { data } = await axios.get(`${env_api_url}bio/v1/biomerics/blogs/?_format=json&is_pinned=true&type=${selectedQuery}&topic=${topicQuery}&body=${search}&page=${pageQuery - 1}`);
            setCommonPinnedBlogSectionData(data);
            setLoading(false);
            return data;
        } catch (e) {
            console.log(e);
        }
    }



    const getPageCount = async (selectedValue: string, topicValue: string) => {
        const selectedQuery = selected ? selected : selectedValue;
        const topicQuery = topic ? topic : topicValue;

        const response = await axios.get(`${env_api_url}bio/v1/biomerics/blogs_count/?_format=json&is_pinned=false&type=${selectedQuery}&topic=${topicQuery}&body=${search}`);
        // console.log(respone, 'res')
        return response.data;
    }

    //     window.scroll(0,0)
    //     const response=getPageCount("","");
    //     response.then((res)=>{
    //         console.log(res.length,'res')
    //       setPages(Math.ceil(res.length/ 9))
    //     }).catch((e)=>{
    //         console.log(e,'e') 
    //     })
    //     fetchFilterData("","",page);
    // }, []);


    // useEffect(() => {
    //     window.scroll(0,0)   

    //     fetchPinnedData();
    // }, [selected, topic, search] );

    const handelSearchIcon = async () => {
        setPage(1)
        const pageCount = await getPageCount("", "");
        setPages(Math.ceil(pageCount.length / 9))

        await fetchFilterData("", "", 1);
        await fetchPinnedData("", "", 1);
    }

    const handleSearch = async (e: any) => {
        const value = e.target.value;
        if (e.keyCode === 13) {

            setSearch(value);
            e.preventDefault()
            e.stopPropagation();
            setPage(1)
            const pageCount = await getPageCount("", "");
            // console.log("pageCount", pageCount);
            setPages(Math.ceil(pageCount.length / 9))
            await fetchFilterData("", "", 1);
            await fetchPinnedData("", "", 1);
        }
        //setSearch(value);
    }


    const [mailingData,setMailingData]=useState<userContactInformation>(initial_value);
    const [error,setError]=useState<ErrorMessage>({
    email:''});
    const [verified, setVerified] = useState<string>("");
    const [disabled, setDisabled]=useState<boolean>(true)

    //////////////

    const handleSubmit = async() => {
        const body={
         email:mailingData.email,
         lead_source:'Newsletter'

        }
        const response=await postRfqFormData(JSON.stringify(body));
        if(JSON.parse(response).data.statusCode===200){
          setVerified("here are msg");
          setMailingData(initial_value)
        }else{
          alert("something went wrong!")
        }
    }
      
      const handleEmailChange=  (e:React.ChangeEvent<HTMLInputElement> )=>{
      const {name, value}=e.target;
      
    setMailingData({...mailingData,[name]:value})
        if(name==='email'){
            if (!value) {
              setError({...error, [name]:"Please enter your email address."});
              setDisabled(true)
            }else{
               setError({...error, [name]:""});
              setDisabled(true)
           }
            if (typeof value !== "undefined") {
              var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
              if (!pattern.test(value)) {
               // isValid = false;
               // errors["email"] = "Please enter valid email address.";
                setError({...error, [name]:"Please enter your valid email address."})
                setDisabled(true)
              }else{
                  setError({...error, [name]:""})
                  setDisabled(false)
              }
            }
        }
      //setRfqFormData({...rfqFormData,[name]:value}) 
    }


    // console.log("common blog data ::::::::::::::::::::::::::::::::::::::", commonBlog)
    return (
        <>
            {loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
                <React.Fragment>
                    {/* <Header /> */}
                    {/* <TopSubMenuAboutUs  currentPage={currentPage}/> */}
                    <TopSubMenuNewsAndResource currentPage={currentPage}/>
                    <BannerHeader />
                    <Box className='blog' pt={{ xs: "30px !important", lg: "77px" }}>
                        <Container maxWidth={false} sx={{ maxWidth: '1332px' }}>
                            <GetToKnowUs data={GetToKnoewSectionData} />
                            <Box className='search-frm' mb={10}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={4}>
                                        <FormControl variant="filled" className='w-100 filter-dropdown'>
                                            <InputLabel id="demo-simple-select-filled-label">TYPE</InputLabel>
                                            <Select labelId="demo-simple-select-filled-label" id="demo-simple-select-filled" value={selected}
                                                onChange={handleTypeChange}>
                                                {/* {typeData()} */}

                                                {typeDropdownValues.map((topic: string, index: number) => {
                                                    return <MenuItem value={he.decode(topic)} key={index}>{he.decode(topic)}</MenuItem>
                                                })}




                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl variant="filled" className='w-100 filter-dropdown' >
                                            <InputLabel id="demo-simple-select-filled-label">TOPIC</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled" onChange={handleTopicChange} value={topic}>
                                                {topicDropdownValues.map((topic: string, index: number) => {
                                                    return <MenuItem value={he.decode(topic)} key={index}>{he.decode(topic)}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box position='relative'>
                                            <TextField id="filled-basic" label="SEARCH" variant="filled" className='search-input'
                                                fullWidth={true} onChange={(event) => { setSearch(event.target.value) }} name={search} value={search} onKeyDown={handleSearch} />
                                            <Box component="span" position="absolute" top="3px" right="12px"
                                                className="search-icon" ><SearchIcon onClick={handelSearchIcon} style={{ cursor: 'pointer' }} />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            {page === 1 &&
                                <PinnedBlog
                                    data={pinnedBlog}
                                    topicSearched={topic}
                                    typeSearched={selected}
                                    textSearched={search}
                                    currentPage={page}
                                />}
                            <Grid container spacing={4}>
                                {commonBlog.length > 0 ? <CommonBlogs data={commonBlog}
                                    topicSearched={topic}
                                    typeSearched={selected}
                                    textSearched={search}
                                    currentPage={page}
                                /> :
                                    <Grid item xs={12} >
                                        <NoResultFound />
                                    </Grid>
                                }
                            </Grid>
                            {/* className={commonBlog && commonBlog.length < 9 ? "desabled":''} */}
                            <Box display='flex' justifyContent='center' mt={10} mb={7} className='pagination'>
                                <Stack spacing={2}>
                                    <Pagination count={pages} page={page} onChange={handleChange} color="primary" />
                                </Stack>
                            </Box>
                        </Container>
                    </Box>
                    <Box className='subscribe'>
                        <Container maxWidth={false} sx={{ maxWidth: '1332px', position: 'relative', zIndex: '9' }} >
                            <Typography fontSize={{ xs: '25px', md: '36px' }} mb={5} color='#fff' fontWeight='700' textAlign='center'>
                                Sign up for upcoming news
                            </Typography>
                            <Box display='flex' justifyContent='center' mb={6}>
                                <FormControl variant="standard" sx={{ mr: '24px', width: '477px', maxWidth: '100%' }}>
                                    <BootstrapInput placeholder='Email*' name='email' onChange={handleEmailChange} value={mailingData.email} />
                                    {error.email.length > 0 ? <Typography color='red' variant="body2" >{error.email}</Typography> : ""}
                                </FormControl>
                                {/* className={disabled?'btn-grey':'btn-blue'} */}
                                <Button variant='contained'
                                    className={disabled ? 'btn-grey' : 'btn-white'}
                                    // className='btn-white btn-subscribe'
                                    sx={{ minWidth: '100px' }}
                                    onClick={handleSubmit} 
                                    // disabled={disabled}
                                    >SUBMIT</Button>
                            </Box>
                            {verified !== "" ?
                                <Typography fontSize='15px' mb={5} className='mail-msg' color='#fff'
                                > Your request has been submitted!</Typography>
                                : ''
                            }
                            <Typography fontSize='20px' mb={5} color='#fff' textAlign='center'>
                                Stay on top of the latest happenings in Biomerics by signing up.
                            </Typography>
                        </Container>
                    </Box>
                    <CommonCreativeSolution />
                    {/* <Footer /> */}
                </React.Fragment >
            }
        </>
    );
}
export default Blog;
