import React, { FC } from 'react';
import Carousel from 'react-material-ui-carousel';
import {  Button, Box, Typography, Container } from '@mui/material';
//import { formatHeroSectionData, formatNewsCardSectionData } from '../Config';
import contactbg from '../../../assets/images/contact-bg.jpg';
import {Helmet} from 'react-helmet';
interface HeadingSection {
    data:{   
        field_description: string,
        nid?: string
        title?: string
        field_image_url_1?:string
        field_seo_title?:string 
        field_seo_description?:string 
        field_seo_image?:string 
        field_seo_keywords?:string
        }[]
    };
const BannerSection:FC<HeadingSection> = ({data}) => {
    
return (
    < >
        {data?.map((item: any, i: number) => {
            return <Item key={i} {...item} />
        })}
    </>
);
}

const Item = (props: any) => {
const { title, field_description, field_button_text, fieldImage,field_image_url_1,field_seo_title, field_seo_description,field_seo_image, field_seo_keywords } = props
 //console.log(title, "title");
    return (
        <React.Fragment>
            <Box className="hero-section contact-us" display="flex" justifyContent="space-between" flexDirection="column" pt={{xs:'25px', lg:'64px', xl:'91px'}}>
                     <img src={field_image_url_1} alt="" />
                     {field_seo_title != undefined ?
               <Helmet>
                        <meta charSet="utf-8" />
                         <title> {!field_seo_title[0] ? field_seo_title.split("&amp;")[0] : field_seo_title.split("amp;")[0]}</title>
                         <meta name="description" content={field_seo_description}></meta>
                         <meta name="keywords" content={field_seo_keywords}></meta>
                         <meta property='keywords' content={field_seo_keywords}></meta>
                         <meta property="og:title" content={!field_seo_title[0] ? field_seo_title.split("&amp; ")[0] : field_seo_title.split("amp; ")[0]} />
                         <meta property="og:description" content={field_seo_description}/>
                        <meta property="og:image" content={field_seo_image}/> 
                 </Helmet> 
                 :""}
                         <Container maxWidth={false} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', maxWidth: '1736px' }}>
                            <Box position="relative" zIndex="9">
                                <Typography variant="h1" fontSize="73px" fontWeight="400">{title}</Typography>
                                {/* <Typography variant="h2" fontWeight="600" mb={3}>RFQ</Typography> */}
                            </Box>
                        </Container>
                    </Box>
        </React.Fragment>
        );
};


export default BannerSection;
