

import React from 'react'
import { Container, Box, Typography, Grid, Card, CardContent, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import img1 from '../../assets/images/img1.jpg'
import arrow from '../../assets/images/arrow.svg'
import Link from '@mui/material/Link';
//import DesignImg from '../../assets/images/DesignServiceImage.jpg'
import FDImg from '../../assets/images/FDImage.jpg';
import MaterialImg from "../../assets/images/MaterialImg.jpg";
import ECImage from "../../assets/images/EcImage.png";
//Get Images by const variable
import verticalIntegrationImg from '../../../assets/images/vertical-integration.png';
const AboutPrivacyPolicy = (props: any) => {
const {data} = props;
    //console.log("VerticalIntegration::::",data);
     return (
        <Box className='blog' pt="77px">
            <Container maxWidth={false} sx={{ maxWidth: '1332px' }}>
                <Box className='privacy-policy'>
                    {/* {Data()} */}
                <Box className='privacy-policy-section'>
                    <Typography variant="h1" >{data[0].title }</Typography>
                    <Typography dangerouslySetInnerHTML={{ __html: data[0].body }}></Typography> 
                </Box>
                </Box>
            </Container>
        </Box>
    )


}
export default AboutPrivacyPolicy;

