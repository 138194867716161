import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useLocation } from 'react-router-dom';
import { Grid, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import Link from '@mui/material/Link';
import Footer from './Footer';
import CommonCreativeSolution from '../components/CommonCreativeSolution';
import Header from '../components/HeaderSections/Header';
import BannerSection from '../components/sections/Leadership/BannerSection';
import CardDataSection from '../components/sections/Leadership/CardDataSection'
//Api url
import { LEADERSHIP_PAGE_CONTENT_URL } from '../common/APi/apiurl';
import { LeadershipHeroSection,LeadershipCardSection } from '../common/utils/utils';
import { LeadershipPageResponse } from '../Service/Leadership';
import ExploreAboutBiomerics from '../components/sections/ServiceDetailsLevel2/ExploreAboutBiomerics'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TopSubMenuAboutUs from './sections/ServiceDetailsLevel2/TopSubMenuAboutUs';


const Leadership = () => {
    let pageURL;
    const pathName = useLocation();
    const currentPage = pathName.pathname;
    const location = useLocation().pathname;
    const [loading, setLoading] = useState<boolean>(true)
    const [pageContent, setContent] = useState<null | any>(null);
    const [HeadingSectionData, setHeadingSection] = useState<null | any>(null);
    const [LeadershipCardData, setLeadershipCardData] = useState<null | any>(null);
    
    const fetchResponse = async () => {
        //console.log("currentPage")
        if (currentPage === "/Leadership") {
            const response = await LeadershipPageResponse(LEADERSHIP_PAGE_CONTENT_URL);
            console.log("response in leaderhsip...",response)
            setContent(response)
            setLoading(false);
        }
    };

    const formatData = () => {
        let newsData;
        let titlesection: any;
        let HeadingSection;
        let LeadershipCardData;

        if (pageContent) {
            HeadingSection = LeadershipHeroSection(pageContent);
            LeadershipCardData =LeadershipCardSection(pageContent);
            setHeadingSection(HeadingSection);
            setLeadershipCardData(LeadershipCardData);
        
        }

    }

    useEffect(() => {
        window.scrollTo(0,0)
        fetchResponse();
    }, [location])

    useEffect(() => {
        formatData();

    }, [pageContent])
    return (
        <>
         <TopSubMenuAboutUs currentPage={location}/>
         {loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
                    <React.Fragment>
                
                        <BannerSection data={HeadingSectionData} />
                        <CardDataSection />
                        <ExploreAboutBiomerics/>
                        <CommonCreativeSolution />
                        {/* <Footer /> */}
                    </React.Fragment >
                }
          
        </>
    );
}
export default Leadership;

