import React, {FC,useState,useEffect} from 'react';
import Carousel from 'react-material-ui-carousel';
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, 
ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import AboutUsImg from '../../../assets/images/About-us-banner.png';
import { BlogHeaderPageResponse } from '../Service/BlogHeader';
import { BLOG_HEADER } from '../common/APi/apiurl';
import { BlogHeroSection,BlogCarsSection,GetToKnoewSection } from '../common/utils/utils';
import BannerSection from '../components/sections/NewsAndSearch/BannerSection';
import GetToKnowUs from '../components/sections/NewsAndSearch/GetToKnowUs';
interface HeroSection {
    data: {
        field_description: string,
        nid?: string
        title?: string
        field_blog_bannerimage:string
    }[]
};
const BannerHeader= () => {
  
    const [loading, setLoading] = useState<boolean>(true)
    const [pageContent, setContent] = useState<null | any>(null);
    const [HeadingSectionData, setHeadingSection] = useState<null | any>(null);
    const [GetToKnoewSectionData, setGetToKnoewSection] = useState<null | any>(null);
    const fetchResponse = async () => {
        
             const response1 = await BlogHeaderPageResponse(BLOG_HEADER);
             setContent(response1)
             setLoading(false);
           // console.log(response1, "cccc")
          
    };
    const formatData = () => {
        let newsData;
        let titlesection: any;

        let HeadingSection;
        let GetToKnoewSectionData;
        let newsContent;
        if (pageContent) {
            HeadingSection = BlogHeroSection(pageContent);
            GetToKnoewSectionData = GetToKnoewSection(pageContent);
            setHeadingSection(HeadingSection);
            setGetToKnoewSection(GetToKnoewSectionData);
        }
    };
    useEffect(() => {
        fetchResponse();

    }, []);
    useEffect(() => {
        formatData();
        //console.log("row2",pageContent)
    }, [pageContent])
    return (
        <>
            {loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
                <React.Fragment>
                    <BannerSection data={HeadingSectionData} />
                </React.Fragment >
            }
        </>
    );
        }
    export default BannerHeader;
