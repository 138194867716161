import React, {FC,useEffect} from 'react';
import Carousel from 'react-material-ui-carousel';
import { Container, Box, Typography, Grid, Card, CardContent, Button,List, CardMedia, 
ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import { Link as RouterLink} from 'react-router-dom';
import he from 'he'
import { Helmet } from 'react-helmet';
const BlogsPagination=(props: any) => {
    const { data } = props;

   // var detail_url='/BlogDetails';
 if(data){
    return data.map((commonData: any, index: any) => { 
        return (
            <React.Fragment>
                  
                  {commonData.field_seo_title != undefined ?
               <Helmet>
                        <meta charSet="utf-8" />
                         <title> {!commonData.field_seo_title[0] ? commonData.field_seo_title.split("&amp;")[0] : commonData.field_seo_title.split("amp;")[0]}</title>
                         <meta name="description" content={commonData.field_seo_description}></meta>
                         <meta name="keywords" content={commonData.field_seo_keywords}></meta>
                         <meta property='keywords' content={commonData.field_seo_keywords}></meta>
                         <meta property="og:title" content={!commonData.field_seo_title[0] ? commonData.field_seo_title.split("&amp; ")[0] : commonData.field_seo_title.split("amp; ")[0]} />
                         <meta property="og:description" content={commonData.field_seo_description}/>
                        <meta property="og:image" content={commonData.field_seo_image}/> 
                 </Helmet> 
                 :""}
                            <Grid item xs={12} md={6} lg={4}>
                                <Card className='blog-box' sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)' }}>
                                    <Box display='flex' alignItems='start' justifyContent='end'>
                                        <CardMedia
                                            component="img"
                                            height="288"
                                            image={commonData.field_blog_bannerimage}
                                            alt=""
                                        />
                                    </Box>
                                    <CardContent sx={{ p: '24px' }}>
                                        <Typography gutterBottom variant="h5" fontSize='28px' mb={3}>
                                        {he.decode(commonData.title)}
                                        </Typography>
                                        <RouterLink   to={`/BlogDetails${commonData.field_blog_address_url}`} state={{searchResultData:data,  currentIndex:index ,
                                        currentPage:props.currentPage,
                                             isPinnedBlog:false}}>
                                             
                                        <Button sx={{ padding: '0 25px', fontSize: '18px', fontWeight: '500 !important' }} style={{width: '170px',
                                                        height: '46px'}} className='btn-blue' 
                                                        href={'/BlogDetails'+`${commonData.field_blog_address_url}`}>
                                                            READ MORE
                                                    </Button>
                                                    </RouterLink>
                                    </CardContent>
                                </Card>
                            </Grid>
                      
            </React.Fragment>
        );
        })
    }else{
        return (
            <>no data</>
        )
    }
  
};


export default BlogsPagination;