import React, { useEffect, FC, useState } from 'react'
import logo from '../assets/images/logo.png';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
//import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
//import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import { List, ListItem, ListItemAvatar, ListItemText, TextField } from '@mui/material';
import Config from '../Information/Config.json'
import { fetchFooterMenuFromServer } from '../Service/Header Menu';
import he from 'he';
import { formatRequiredDatafromApi } from '../common/utils/utils';
import { postRfqFormData } from '../Service/Rfq';
import useAnalyticsEventTracker from '../components/useAnalyticsEventTracker';
interface IProps {
  footerId: string,
  contentData: {
    title: string
    field_description: string
  }
}

interface userContactInformation {
  userName: string,
  email: string;



}

const initial_value: userContactInformation = {
  userName: '',
  email: '',

}

interface ErrorMessage {
  userName: string;
  email: string;
}

const Footer = () => {
  const [verified, setVerified] = useState<string>("");
  const [mailingData, setMailingData] = useState<userContactInformation>(initial_value);
  const [error, setError] = useState<ErrorMessage>({
    userName: '',
    email: '',

  });
  const gaEventTracker = useAnalyticsEventTracker(window.location.pathname);
  const [disabled, setDisabled] = useState<boolean>(true)
  // const handleClick = (event: any) => {
  //   let certURL = window.location.href
  //   let queryString = certURL.split('/');
  //   let textCertifications = queryString[queryString.length - 1];
  //   let clickedElementId = event.currentTarget.id

  //   if (clickedElementId === 'footerLinkId') {
  //     console.log(textCertifications)
  //     if (textCertifications == 'Certifications') {
  //       document.body.classList.add('certifications');
  //     }
  //     console.log(clickedElementId)
  //   }
  // };
  // function handelSubmit(value: any) {
  //   console.log(value,"kkkkkkk")
  //   setVerified(value);
  // }

  const handleSubmit = async () => {
    const body = {
      firstname: mailingData.userName,
      email: mailingData.email,
      lead_source: 'Newsletter'
    }
    const response = await postRfqFormData(JSON.stringify(body));

    if (JSON.parse(response).data.statusCode === 200) {
      setVerified("here are msg");
      setMailingData(initial_value)
    } else {
      alert("something went wrong!")
    }

  }

  const [footerData, setFooterData] = useState<null | []>(null)
  const [loading, setLoading] = useState(true)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setMailingData({ ...mailingData, [name]: value })
    if (name === 'email') {
      if (!value) {

        setError({ ...error, [name]: "Please enter your email address." });
        setDisabled(true)

      } else {
        setError({ ...error, [name]: "" });
        setDisabled(true)
      }

      if (typeof value !== "undefined") {

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(value)) {
          // isValid = false;
          // errors["email"] = "Please enter valid email address.";
          setError({ ...error, [name]: "Please enter your valid email address." })
          setDisabled(true)
        } else {
          setError({ ...error, [name]: "" })
          setDisabled(false)
        }
      }
    }
    //setRfqFormData({...rfqFormData,[name]:value}) 
  }

  useEffect(() => {
    const fFd = fetchFooterMenuFromServer();
    fFd.then((res: any) => {
      const requiredData = formatRequiredDatafromApi(res[0].body);

      setFooterData(requiredData)
      setLoading(false)
    }).catch((e) => {
      console.log(e);

    })

  }, [])


  const renderFooterMenu = () => {

    return footerData?.map((fd: any, index: any) => {
      const pageLink = he.decode(fd.to);

      return <Grid item xs={12} md={4} lg={2}>
        <Box className='quick-links'>
          <Link underline='none' component={RouterLink} to={pageLink} href="#" onClick={() => gaEventTracker('check footer-1')}>
            <Typography className='links-heading'>{he.decode(fd.title)}
            </Typography>
          </Link>
       

          {fd.sub_title.map((fdSub: any, index: any) => {
           
            return <List sx={{ p: 0 }}>

              <ListItem sx={{ p: 0 }}>
                <ListItemText className='footerlink' >
                  <NavLink id="footerLinkId"
                    href='#'
                    onClick={() => gaEventTracker('check footer-1')}
                    //onClick={handleClick}
                    to={he.decode(fdSub.to?.replace(/\s+/g, ''))}
                    state={{ showPagination: "true", currentIndex: 0, searchResultData: [], isPinnedBlog: "true" }}
                  >{he.decode(fdSub.title)}
                  </NavLink>

                  {fdSub.sub_title && fdSub.sub_title.length && fdSub.sub_title.length > 0 ?
                    fdSub.sub_title.map((sub: any, index: any) => {
                      return <List sx={{ py: 0 }} className="footer-submenu">

                        <ListItem sx={{ p: 0 }}>
                          <ListItemText className='footerlink' >
                            <NavLink id="footerLinkId"
                              href='#'
                              onClick={() => gaEventTracker('check footer-1')}
                              //onClick={handleClick}
                              to={he.decode(sub.to?.replace(/\s+/g, ''))}
                              state={{ showPagination: "true", currentIndex: 0, searchResultData: [], isPinnedBlog: "true" }}
                            >{he.decode(sub.title)}
                            </NavLink>
                          </ListItemText>
                        </ListItem>
                      </List>
                    })
                    : null}

                </ListItemText>
              </ListItem>
            </List>
          })
          }
        </Box>
      </Grid>
    })
  }
  
  return (

    <>
      {/* <AppBar position="fixed" color="inherit" sx={{ boxShadow: 'none',zIndex: (theme) => theme.zIndex.drawer + 1 }}> */}
      <Box className='footer' sx={{ backgroundColor: '#EEEBEB', pt: { xs: '80px', md: '160px' } }}>
        <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>

          <Grid  container spacing={{ xs: 2, lg: 4, xl: 8 }} mb={{ xs: '50px', md: '96px' }}>
            <Grid item xs={12} lg={6}>
              <Typography variant="h5" fontWeight="600" mb="20px" color="#4A4948">
                CONTACT
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6} style={{ marginTop: "4px" }}>
                  <Typography fontWeight="500" mb="10px" color="#4A4948">
                    Biomerics Corporate Headquarters
                  </Typography>
                  <Typography fontWeight="500" mb="10px" color="#4A4948">
                    <Link href="tel:1 888.874.7787" underline="none" color="#4A4948">
                      +1 888-874-7787
                    </Link>
                  </Typography>
                  <Typography fontWeight="500" mb="10px" color="#4A4948">
                    <Link href="tel:1 801-355-2705" underline="none" color="#4A4948">
                      +1 801-355-2705
                    </Link>
                  </Typography>
                  <Typography fontWeight="500" mb="10px" color="#4A4948">
                    <Link href='mailto:info@biomerics.com' target="_blank">info@biomerics.com</Link>
                  </Typography>
                  <Box className='social-icons'>
                    <Link href="https://www.linkedin.com/company/biomerics/" target="_blank" mr={1}><i className="fa-brands fa-linkedin-in"></i></Link>
                    <Link href="https://www.facebook.com/biomerics" target="_blank" mr={1}><i className="fa-brands fa-facebook-f"></i></Link>
      
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box>
                    <List sx={{ py: 0 }}>
                      <ListItem sx={{ p: 0 }}>
                        <ListItemText><Link href="/Rfq">Request for Quote</Link></ListItemText>
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box className='newsletter' bgcolor='#ffffff' p={4}>
                <Typography variant="h5" fontWeight="600" mb="20px" color="#29A7DF">
                  Join Our Mailing List
                </Typography>
                <div className='form-field'>
                  <Grid container spacing={{ xs: '15px', lg: '15px', xl: '48px' }}>
                    <Grid item xs={12} lg={5}>
                      <TextField id="standard-basic" label="Name" fullWidth={true} variant="standard"
                        name='userName'
                        value={mailingData.userName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <TextField id="standard-basic"
                        type="email"
                        name="email"
                        label="Email"
                        fullWidth={true}
                        variant="standard"
                        value={mailingData.email}
                        onChange={handleChange}
                        required />
                      {error.email.length > 0 ? <Typography color='red' variant="body2" >{error.email}</Typography> : ""}
                    </Grid>
                    <Grid item xs={12} lg={2} >
                      <Button variant="contained" className={disabled ? 'btn-grey' : 'btn-blue'} onClick={handleSubmit} disabled={disabled} >SUBMIT</Button>
                    </Grid>

                    {/* <p className='mail-msg'>Your Request has been submitted!</p> */}
                  </Grid>
                </div>
                {verified !== "" ?
                  <p className='mail-msg'> Your request has been submitted!</p>
                  : ''
                }
              </Box>
            </Grid>
          </Grid>
          <Grid className='footer-links-wrapper' container spacing={3} pb={{ xs: '40px', md: '120px' }}>
            {loading ? <></> : renderFooterMenu()}
          </Grid>
          {/**BE IXF: The following <div> block needs to be placed in the location where the link block will be displayed-->
                  <!--BE IXF: For your website the location is after copyright */}
          <div className="be-ix-link-block">{/**<!--Link Block Target Div--> */}</div>
          {/**<!--BE IXF: end -->*/}
          <Box className='copyright' py={{ xs: '20px', md: '40px' }}>
            <Typography fontWeight="500" mb="8px" color="#4A4948">
              <Link className="text-link" target="_blank" to="/Privacy-Policy" component={RouterLink}>
                Privacy Policy
              </Link>
            </Typography>
            <Typography fontSize='14px' color="#4A4948">
              © 2023 Biomerics. All trademarks are the property of their respective owners.
            </Typography>
          </Box>
        </Container>
      </Box>

      {/* </AppBar> */}
    </>

  )
}

export default Footer
